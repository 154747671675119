<template>
  <div :id="identifier">
    <div class="time-div" id="timeline">
      <div class="progress"></div>
    </div>
    <div v-for="(step, index) in timelineStep" :key="step.id">
      <div
        class="step"
        :class="{
          endOfTimeline: index + 1 == timelineStep.length,
          startOfTimeline: index == 0
        }"
      >
        <div class="">
          <div class="step-icon-wrapper" v-if="step.icon.data && step.icon_not_reached.data">
            <img
              class="icon-not-reached"
              v-if="
                step.icon_not_reached.data &&
                  step.icon_not_reached.data.attributes.ext.toLowerCase().trim() != '.json'
              "
              :src="url + step.icon_not_reached.data.attributes.url"
              :alt="step.icon_not_reached.data.attributes.alternativeText"
            />
            <lord-icon
              class="icon-not-reached"
              v-if="
                step.icon_not_reached.data &&
                  step.icon_not_reached.data.attributes.ext.toLowerCase().trim() == '.json'
              "
              target="div"
              :src="url + step.icon_not_reached.data.attributes.url"
            ></lord-icon>
            <img
              class="icon"
              v-if="step.icon.data && step.icon.data.attributes.ext.toLowerCase().trim() != '.json'"
              :src="url + step.icon.data.attributes.url"
              :alt="step.ico.data.attributes.alternativeText"
            />
            <lord-icon
              class="icon"
              v-if="step.icon.data && step.icon.data.attributes.ext.toLowerCase().trim() == '.json'"
              trigger="hover"
              target="div"
              :src="url + step.icon.data.attributes.url"
            ></lord-icon>
          </div>
          <div v-else class="number-icon-wrapper">
            <svg
              class="icon-circle"
              width="100%"
              height="100%"
              viewBox="0 0 3000 3000"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              xml:space="preserve"
              xmlns:serif="http://www.serif.com/"
              style="fill-rule: evenodd; clip-rule: evenodd"
            >
              <g transform="matrix(27.6491,0,0,27.6491,1500,1500)">
                <g transform="matrix(1,0,0,1,-40,-40)">
                  <clipPath id="_clip1">
                    <rect x="0" y="0" width="80" height="80" />
                  </clipPath>
                  <g clip-path="url(#_clip1)">
                    <circle
                      id="circle"
                      cx="40"
                      cy="40"
                      r="37.5"
                      style="
                          fill: none;
                          stroke: url(#_Linear3);
                          stroke-width: 5px;
                        "
                    />
                  </g>
                </g>
                <g></g>
              </g>
              <defs>
                <linearGradient
                  id="_Linear3"
                  x1="0"
                  y1="0"
                  x2="1"
                  y2="0"
                  gradientUnits="userSpaceOnUse"
                  gradientTransform="matrix(80,0,0,80,0,41.5999)"
                >
                  <stop offset="0" style="stop-color: rgb(11, 49, 94); stop-opacity: 1" />
                  <stop offset="1" style="stop-color: rgb(36, 106, 164); stop-opacity: 1" />
                </linearGradient>
              </defs>
            </svg>
            <p v-if="startFromZero">{{ index }}</p>
            <p v-else>{{ index + 1 }}</p>
          </div>
          <div
            data-aos-anchor-placement="center-bottom"
            data-aos="fade-up"
            data-aos-duration="600"
            data-aos-offset="200"
          >
            <vue-markdown class="sb-headline-3-blue inhalt text-left">
              ### {{ step.title }}
            </vue-markdown>
            <div v-if="step.content">
              <vue-markdown
                class="markdown sb-headline-3-blue inhalt text-left"
                :source="step.content"
              ></vue-markdown>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueMarkdown from "vue-markdown";
import $ from "jquery";
import { getStrapiUrl } from "../../helper";
export default {
  name: "Timeline",
  data() {
    return {
      url: getStrapiUrl(),
      pixelPaddingTopVonEndofTimeline: 0,
      timelineHeight: 0,
      identifier: this.id
    };
  },
  props: {
    timelineStep: {
      type: Array
    },
    startFromZero: {
      type: Boolean
    },
    id: {
      type: String
    }
  },
  components: {
    VueMarkdown
  },
  mounted() {
    this.timelinelaengeSetzen();
    setTimeout(() => {
      this.timelinelaengeSetzen();
    }, 1000);
    $(document).on("touchmove", this.progress);
    $(document).on("scroll", this.progress);
    window.addEventListener("resize", () => {
      this.timelinelaengeSetzen();
      this.progress();
    });
    $(window).resize(this.timelinelaengeSetzen);
    $(window).resize(this.progress);

    $(window).scroll(() => {
      if (typeof this.identifier !== "undefined") {
        var fixed_height = $(`#${this.identifier} .progress`).height();
        var fixed_position = $(`#${this.identifier} .progress`).offset().top;

        $(`#${this.identifier} .number-icon-wrapper`).each(function() {
          var $this = $(this);
          if ($this.offset().top <= fixed_height + fixed_position) {
            $this
              .children("p")
              .css("background", "linear-gradient(180deg, #0b315e 0%, #246aa4 100%)");
            $this.find("#circle").css("stroke", "url(#_Linear3)");
            $this.children("p").css("backgroundClip", "border-box");
            $this.children("p").css("-webkit-background-clip", "text");
          } else if ($this.offset().top >= fixed_height + fixed_position) {
            $this.children("p").css("background", "#e7ebef");
            $this.find("#circle").css("stroke", "#e7ebef");
            $this.children("p").css("backgroundClip", "unset");
            $this.children("p").css("-webkit-background-clip", "text");
          }
        });

        $(`#${this.identifier} .icon-not-reached`).each(function() {
          var $this = $(this);
          if ($this.offset().top <= fixed_height + fixed_position) {
            $this.css("display", "none");
            $this.siblings().css("display", "block");
          }
        });

        $(`#${this.identifier} .icon`).each(function() {
          var $this = $(this);
          if ($this.offset().top >= fixed_height + fixed_position) {
            $this.css("display", "none");
            $this.siblings().css("display", "block");
          }
        });
      }
    });
  },
  methods: {
    timelinelaengeSetzen() {
      // Timeline länge berechnen:
      this.pixelPaddingTopVonEndofTimeline = $(`#${this.identifier} .endOfTimeline`)
        .css("padding-top")
        .match(/^[0-9]*/g);
      // prettier-ignore
      this.timelineHeight = $(`#${this.identifier} .endOfTimeline`).offset().top + parseInt(this.pixelPaddingTopVonEndofTimeline) - $(`#${this.identifier} .time-div`).offset().top;
      // CSS setzen:
      $(`#${this.identifier} .time-div`).css({
        height: this.timelineHeight + "px"
      });
    },
    progress() {
      var bgColor = "linear-gradient(180deg, #0b315e 0%, #246aa4 100%)";
      var windowScrollTop = $(window).scrollTop();
      var untererBildschirmrand = window.innerHeight + windowScrollTop; // oder: clientHeight = Darstellbare Webfläche

      // prettier-ignore
      var gezeichneterStrichAbstandZuUntererBildschrim = window.innerHeight / 2.5;

      // prettier-ignore
      var anzahlPixelInFarbe = untererBildschirmrand - $(`#${this.identifier} #timeline`).offset().top - gezeichneterStrichAbstandZuUntererBildschrim; // 0 ist normalwert, ab null wird gezeichnet

      // Das der Strich nicht länger wird als er darf:
      if (anzahlPixelInFarbe > $(`#${this.identifier} #timeline`).height()) {
        anzahlPixelInFarbe = $(`#${this.identifier} #timeline`).height();
      }
      // Balken Farbig zeichnen:
      $(`#${this.identifier} .progress`)
        .height(anzahlPixelInFarbe + "px")
        .css({
          backgroundImage: bgColor
        });
    }
  }
};
</script>

<style scoped>
.time-div {
  width: 0.3rem;
  position: absolute;
  z-index: -999;
  margin-left: 5rem;
  height: 233rem;
  transition: all 0.5s ease-out;
  background-color: var(--strawbinary-light-blue);
}

.time-div .progress {
  background-color: inherit;
  width: 0.5rem;
  position: relative;
  left: -0.1rem;
}

.step {
  padding: 5rem 0;
  position: relative;
}

.endOfTimeline {
  padding-top: 3.1rem;
}

.startOfTimeline {
  padding-top: 3rem;
}

.icon {
  display: none;
}

.icon,
.icon-not-reached {
  position: absolute;
  background-color: white;
  border-radius: 0;
  left: 0;
  height: 10rem;
  width: 10rem;
  top: -3rem;
  z-index: 900;
}

.step-icon-wrapper {
  position: absolute;
  height: 100%;
  width: 100%;
}

.icon-circle {
  position: absolute;
  background-color: white;
  border-radius: 0;
  left: 0.1rem;
  height: 10rem;
  width: 10rem;
  top: 2rem;
  z-index: 900;
}

.startOfTimeline .number-icon-wrapper .icon-circle {
  top: 0;
}
.startOfTimeline .number-icon-wrapper p {
  top: 1.5rem;
}

.number-icon-wrapper p {
  display: inline-block;
  position: absolute;
  top: 3.3rem;
  left: 3.5rem;
  z-index: 900;
  background: linear-gradient(180deg, #0b315e 0%, #246aa4 100%);
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "Rubik", sans-serif;
  margin: 0;
}

.inhalt,
.step h1,
.step h2,
.step h3 {
  padding: 0 35rem 0 15rem;
}

@media screen and (max-width: 106.25em) {
  .time-div {
    margin-left: 4.4rem;
  }

  .inhalt,
  .step h1,
  .step h2,
  .step h3 {
    padding: 0 15rem 0 15rem;
  }

  .icon,
  .icon-not-reached {
    left: 0;
    height: 9rem;
    width: 9rem;
  }

  .icon-circle {
    left: 0;
    height: 9rem;
    width: 9rem;
  }

  .number-icon-wrapper p {
    top: 3.5rem;
    left: 3.3rem;
  }

  .step {
    padding-top: 4.3rem;
  }

  .startOfTimeline {
    padding-top: 3rem;
  }

  .startOfTimeline .number-icon-wrapper p {
    left: 3.5rem;
  }
}

@media screen and (max-width: 78.125em) {
  .time-div {
    margin-left: 4.4rem;
  }

  .icon,
  .icon-not-reached {
    left: 0;
  }

  .icon-circle {
    left: 0;
  }

  .startOfTimeline .number-icon-wrapper p {
    left: 3.2rem;
  }
  .number-icon-wrapper p {
    left: 3.3rem;
  }
  .inhalt,
  .step h1,
  .step h2,
  .step h3 {
    padding-left: 15rem;
    padding-right: 0;
  }
}

@media screen and (max-width: 56.25em) {
  .time-div {
    margin-left: 4rem;
  }

  .icon,
  .icon-not-reached {
    left: 0rem;
  }

  .icon-circle {
    left: -0.3rem;
  }

  .number-icon-wrapper p {
    left: 3rem;
  }

  .inhalt,
  .step h1,
  .step h2,
  .step h3 {
    padding-left: 13rem;
  }

  .step {
    padding: 5rem 0;
    padding-top: 3.5rem;
  }

  .startOfTimeline .number-icon-wrapper p {
    left: 2.9rem;
  }
}

@media screen and (max-width: 43.75em) {
  .icon,
  .icon-not-reached {
    left: 0.6rem;
    height: 7rem;
    width: 7rem;
  }

  .icon-circle {
    left: 0.2rem;
    height: 8rem;
    width: 8rem;
  }

  .number-icon-wrapper p {
    top: 3rem;
  }
  .startOfTimeline .number-icon-wrapper p {
    top: 1.2rem;
  }
}

@media screen and (max-width: 37.5em) {
  .icon-circle {
    height: 8rem;
    width: 8rem;
    left: -0.6rem;
  }

  .icon,
  .icon-not-reached {
    left: 0rem;
    height: 6rem;
    width: 6rem;
    top: -1.25rem;
  }

  .number-icon-wrapper p {
    left: 1.9rem;
    top: 2.3rem;
  }

  .time-div {
    margin-left: 3.1rem;
  }

  .inhalt,
  .step h1,
  .step h2,
  .step h3 {
    padding-left: 8rem;
  }

  .step {
    padding-top: 3.5rem;
  }

  .startOfTimeline .number-icon-wrapper p {
    top: 0.3rem;
    left: 1.8rem;
  }
}

@media screen and (max-width: 31.25em) {
  .icon-circle {
    left: -0.8rem;
  }

  .time-div {
    margin-left: 2.9rem;
  }

  .number-icon-wrapper p {
    left: 1.7rem;
  }

  .icon,
  .icon-not-reached {
    left: 0rem;
  }

  .startOfTimeline .number-icon-wrapper p {
    left: 1.6rem;
  }
}

@media screen and (max-width: 26.25em) {
  .icon-circle {
    width: 7rem;
    height: 7rem;
    left: -0.8rem;
  }

  .time-div {
    margin-left: 2.5rem;
  }

  .number-icon-wrapper p {
    top: 2.9rem;
  }

  .icon,
  .icon-not-reached {
    left: -0.2rem;
  }

  .inhalt,
  .step h1,
  .step h2,
  .step h3 {
    padding: 0 2.5rem 0 8rem;
  }

  .step {
    padding-top: 3rem;
  }

  .startOfTimeline {
    padding-top: 1rem;
  }

  .startOfTimeline .number-icon-wrapper p {
    top: 0.8rem;
    left: 1.6rem;
  }
}
</style>
