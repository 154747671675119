<template>
  <div id="app">
    <Nav />
    <CookieNotification class="cookieNotification" />
    <router-view :key="$route.fullPath"></router-view>
  </div>
</template>

<script>
import { bootstrap } from "vue-gtag";
import CookieNotification from "./components/element/CookieNotification.vue";
import Nav from "./components/Nav.vue";

export default {
  name: "StrawbinaryHomepage",
  components: {
    Nav,
    CookieNotification
  },
  async mounted() {
    this.setVh();
    window.addEventListener("resize", () => this.setVh());
    if (JSON.parse(this.$cookies.get("cookieValues"))[1].isset) {
      await bootstrap();
    }
  },
  methods: {
    setVh() {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    }
  }
};
</script>

<style scoped>
#app .cookieNotification {
  right: 9rem;
  bottom: 2rem;
}

@media screen and (max-width: 68.75em) {
  #app .cookieNotification {
    right: 2rem;
  }
}

@media screen and (max-width: 33.125em) {
  #app .cookieNotification {
    right: 0;
    left: 0;
    bottom: 2rem;
    height: max-content;
    width: 90vw;
    margin: auto;
  }
}

@media screen and (max-width: 29.375em) {
  #app .cookieNotification {
    height: max-content;
    bottom: 1rem;
    width: 95vw;
  }
}

@media screen and (max-height: 40em) {
  #app .cookieNotification {
    width: 95vw;
    position: absolute;
    top: 2rem;
    left: 0;
    right: 0;
    margin: auto;
  }
}
</style>
