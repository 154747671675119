<template>
  <article :id="pos">
    <div id="Error" class="ct-container">
      <div class="logo-wrapper">
        <img src="/assets/errorsection/Strawbinary_Logo_Black_White.svg" alt="Logo" />
      </div>
      <div class="dots-wrapper">
        <img src="/assets/errorsection/dots_vertical.svg" />
      </div>
      <div class="error-container">
        <div class="error-text">
          <div class="t1">
            <p>...Oops</p>
          </div>
          <div class="t2">
            <h3>
              Irgendetwas ist schief gelaufen...
            </h3>
          </div>
          <div class="t3">
            <p>
              Es ist ein Mysterium, wie du hier her gekommen bist. Aber um wieder auf den richtigen
              Weg zu kommen, klicke einfach auf den Button hier unten.
            </p>
          </div>
          <div class="btn-wrapper">
            <Button
              :button="{
                label: 'Zurück zur Homepage',
                url: '/',
                style: 'primary',
                page: { data: null },
                icon: {
                  data: {
                    attributes: { url: '/assets/errorsection/arrow_right_error.json', ext: '.json' }
                  }
                }
              }"
              :url="''"
            />
          </div>
        </div>
        <div class="error-wrapper"><img src="/assets/errorsection/404_Error.svg" /></div>
      </div>
    </div>
  </article>
</template>

<script>
import Button from "./element/Button.vue";

export default {
  name: "CTError",
  props: {
    body: {
      type: Object
    },
    pos: {
      type: Number
    }
  },
  components: {
    Button
  }
};
</script>

<style lang="css" scoped>
#Error {
  width: 100vw;
  height: 100vh;
  position: relative;
}

.logo-wrapper {
  position: absolute;
  top: 0;
  left: 0;
}

.logo-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

.dots-wrapper {
  position: absolute;
  top: 30%;
  left: 0;
}

.dots-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

.error-container {
  width: 100%;
  height: 100%;
  display: flex;
}

.error-text {
  margin-top: 20%;
  width: 40%;
  padding-bottom: 3vw;
}

.t1 {
  width: 100%;
  font-size: 3rem;
  margin-bottom: 1rem;
}

.t2 {
  width: 100%;
  font-size: 5rem !important;
  margin-bottom: 2rem;
}

.t3 {
  width: 100%;
  font-size: 2rem;
  margin-bottom: 2rem;
}

.btn-wrapper button {
  padding: 0;
}

.error-wrapper {
  width: 60%;
}

.error-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

@media screen and (max-width: 93.75em) {
  .logo-wrapper {
    width: 40rem;
    height: auto;
  }

  .error-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .error-container .error-wrapper {
    order: 1;
    flex-grow: 1;
  }

  .error-container .error-text {
    order: 2;
    flex-grow: 1;
  }

  .error-text {
    width: 80%;
    margin: auto;
  }

  .error-wrapper {
    max-width: 55%;
    width: 55%;
    min-width: 55%;
    height: auto;
    margin: auto;
  }
}

@media screen and (max-width: 68.75em) {
  .logo-wrapper {
    display: none;
  }

  .error-wrapper {
    max-width: 80%;
    width: 80%;
    min-width: 80%;
    padding-left: 1.5rem;
  }
}

@media screen and (max-width: 48em) {
  .dots-wrapper {
    width: 8%;
    top: 60%;
  }

  .error-text {
    width: 100%;
    margin: auto;
    height: 10rem;
    padding-left: 1.5rem;
  }

  .t1 {
    font-size: 2rem;
  }

  .t2 {
    font-size: 3rem;
  }

  .t3 {
    font-size: 1.5rem;
  }

  .error-wrapper {
    min-width: 100%;
    width: 100%;
    max-width: 100%;
    padding-left: 1.5rem;
  }
}

@media screen and (max-width: 24.375em) {
  .dots-wrapper {
    display: none;
  }
}
</style>
