<template lang="html">
  <section :id="pos" class="ct-container">
    <div id="KundenHighlight">
      <div id="KundenHighlight-desktop" v-if="body.kunde.length <= 3">
        <div class="text-center">
          <h2 class="sb-headline-blue">Unsere Kunden</h2>
        </div>
        <div class="row kunden-row">
          <div
            class="justify-center justify-content-between col"
            v-for="kunde in body.kunde"
            :key="kunde.id"
          >
            <Card
              :card="kunde"
              data-aos-anchor-placement="center-bottom"
              data-aos="fade-up"
              data-aos-duration="600"
            />
          </div>
        </div>
      </div>
      <div id="KundenHighlight-desktop" class="position-relative" v-else>
        <div class="text-center">
          <h2 class="sb-headline-blue">Unsere Kunden</h2>
        </div>
        <carousel
          :perPageCustom="[
            [0, 1],
            [1400, 2],
            [1950, 3]
          ]"
          :paginationEnabled="false"
          :scrollPerPage="true"
          :navigateTo="pageDesktop"
          :autoplay="false"
          :autoplayTimeout="0"
          ref="carousel"
        >
          <slide v-for="kunde in body.kunde" :key="kunde.id">
            <div class="justify-center">
              <Card
                :card="kunde"
                data-aos-anchor-placement="center-bottom"
                data-aos="fade-up"
                data-aos-duration="600"
              />
            </div>
          </slide>
        </carousel>
        <div v-if="sideButtons" class="text-center slide-control-wrapper">
          <button
            class="clear-button arrow arrowLEFT btnhoverLEFT"
            @click="
              pageDesktop === 0 ? (pageDesktop = $refs.carousel.pageCount - 1) : pageDesktop--
            "
          ></button>
          <button
            class="clear-button arrow arrowRIGHT btnhoverRIGHT"
            @click="
              pageDesktop === $refs.carousel.pageCount - 1 ? (pageDesktop = 0) : pageDesktop++
            "
          ></button>
        </div>
      </div>
      <div id="KundenHighlight-mobile">
        <div class="text-center">
          <h2 class="sb-headline-blue">Unsere Kunden</h2>
        </div>
        <carousel
          @page-change="PageChangeDragSlide"
          ref="kundenHighlightSliderMobile"
          :perPageCustom="[
            [0, 1],
            [1024, 2],
            [1200, 3]
          ]"
          :paginationEnabled="false"
          :scrollPerPage="true"
          :autoplay="false"
        >
          <slide v-for="kunde in body.kunde" :key="kunde.id">
            <div class="justify-center kunde-wrapper">
              <Card
                :card="kunde"
                data-aos-anchor-placement="center-bottom"
                data-aos="fade-up"
                data-aos-duration="600"
              />
            </div>
          </slide>
        </carousel>
        <div class="text-center pagination-dots">
          <button
            type="button"
            name="button"
            v-for="i in this.sliderCount"
            :key="i.id"
            class="fa-circle slider-custom-pagination"
            :class="{ fas: i == activePage, far: i !== activePage }"
            @click="PaginationCarousel(i)"
          ></button>
        </div>
      </div>
      <div class="btn-wrapper">
        <Button :button="body.link" />
      </div>
    </div>
  </section>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";
import Button from "./element/Button.vue";
import { getStrapiUrl } from "../helper";
import Card from "./element/Card.vue";

export default {
  name: "KundenHighlight",
  data() {
    return {
      url: getStrapiUrl(),
      sliderCount: 0,
      activePage: 1,
      pageDesktop: 0
    };
  },
  props: {
    body: {
      type: Object
    },
    pos: {
      type: Number
    }
  },
  components: {
    Button,
    Carousel,
    Slide,
    Card
  },
  computed: {
    sideButtons: function() {
      var width = window.innerWidth;

      if (this.body.kunde.length == 3 && width > 1900) {
        return false;
      } else if (this.body.kunde.length > 3 && width > 1900) {
        return true;
      } else if (this.body.kunde.length > 2 && width > 786) {
        return true;
      } else if (this.body.kunde.length == 2 && width > 786) {
        return false;
      } else {
        return false;
      }
    }
  },
  methods: {
    PaginationCarousel(page) {
      if (typeof this.$refs.kundenHighlightSliderMobile !== "undefined") {
        const carousel = this.$refs.kundenHighlightSliderMobile;
        this.activePage = page;
        carousel.goToPage(page - 1);
      }
    },
    PageChangeDragSlide(page) {
      this.activePage = page + 1;
    }
  },
  mounted() {
    if (this.$refs.kundenHighlightSliderMobile !== "undefined") {
      this.sliderCount = this.$refs.kundenHighlightSliderMobile.pageCount;
    }
  }
};
</script>

<style lang="css" scoped>
.card-form-type {
  align-self: center;
}

#KundenHighlight-mobile {
  display: none;
}

.image {
  width: 40rem;
}

.kunden-row {
  margin-bottom: 5rem;
  padding: 0 10vw;
}

.kunde {
  margin: 5rem;
}

.btn-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

.slide-control-wrapper {
  display: flex;
  justify-content: space-between;
  position: absolute;
  width: 100%;
  top: 60%;
  transform: translateY(-60%);
  left: 0;
}

.arrow {
  background-size: contain;
  background-position: center;
  width: 6rem;
  height: 6rem;
  cursor: pointer;
}

.arrowRIGHT {
  background: url(/assets/arrow-circle-dark-right.svg) no-repeat;
  position: absolute;
  right: -10rem;
  padding: 0 0;
}

.arrowLEFT {
  background: url(/assets/arrow-circle-dark-left.svg) no-repeat;
  position: absolute;
  left: -10rem;
  padding: 0 0;
}

@media screen and (max-width: 109.375em) {
  .kunden-row {
    padding: 0;
  }
}

@media screen and (max-width: 75em) {
  .arrowRIGHT {
    top: -2rem;
    right: 0;
  }

  .arrowLEFT {
    top: -2rem;
    left: 0;
  }
}

@media screen and (max-width: 48em) {
  #KundenHighlight-mobile {
    display: block;
  }

  #KundenHighlight-desktop {
    display: none;
  }

  .card-form-type {
    width: 95%;
  }

  .btn-wrapper {
    margin-top: 5rem;
  }
}

@media screen and (max-width: 31.25em) {
  .image {
    width: 30rem;
  }

  .kunde {
    margin: 2.5rem;
  }
}

@media screen and (max-width: 21.875em) {
  .image {
    width: 25rem;
  }
}
</style>
