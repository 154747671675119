<template lang="html">
  <section
    :id="pos"
    :class="{
      'bg-light-blue': body.background === 'lightBlue',
      'bg-white': body.background === 'white'
    }"
    class="ct-container"
  >
    <vue-markdown
      :class="{
        tabelleNormal: 'normal' == body.tabellen_design,
        tabelleBorderLeft: 'border_left' == body.tabellen_design
      }"
      class="text markdown sb-headline-blue sb-headline-3-blue"
      :source="body.text"
    ></vue-markdown>
  </section>
</template>

<script>
import VueMarkdown from "vue-markdown";
export default {
  name: "Text",
  props: {
    body: {
      type: Object
    },
    pos: {
      type: Number
    }
  },
  components: {
    VueMarkdown
  }
};
</script>

<style lang="css" scoped>
</style>
