import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Error from "../components/Error.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/page/:slug",
    name: "Page",
    components: require("../views/Page.vue")
  },
  {
    path: "/contact",
    name: "Contact",
    components: require("../components/ContentTypeKontaktformular.vue")
  },
  {
    path: "/:pathMatch(.*)*",
    name: "Error",
    component: Error
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  linkExactActiveClass: "active"
});

export default router;
