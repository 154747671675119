<template lang="html">
  <section :id="pos" class="ct-container">
    <div id="partner">
      <div class="text-center">
        <h2 class="sb-headline-blue">Partner</h2>
      </div>
      <div id="partner-desktop">
        <Carousel
          :perPageCustom="[
            [0, 1],
            [1290, 2],
            [1800, 3]
          ]"
          :paginationEnabled="false"
          :navigateTo="pageDesktop"
          ref="carousel"
        >
          <Slide v-for="partner in body.partner" :key="partner.id">
            <div
              class="row partner-row"
              data-aos-id="partnerAOS"
              :data-aos-anchor-placement="!sideButtonsBool ? 'center-bottom' : ''"
              :data-aos="!sideButtonsBool ? 'fade-up' : ''"
              :data-aos-duration="!sideButtonsBool ? '600' : ''"
            >
              <div class="justify-center justify-content-between col">
                <div
                  v-if="partner.url != null && partner.url != ''"
                  class="partner border-top-sb shadow-sb"
                >
                  <a :href="partner.url" target="_blank">
                    <img
                      class="logo"
                      :src="url + partner.logo.data.attributes.url"
                      :alt="partner.logo.data.attributes.alternativeText"
                    />
                  </a>
                </div>
                <div v-else class="partner border-top-sb shadow-sb no-cursor">
                  <a>
                    <img
                      class="logo"
                      :src="url + partner.logo.data.attributes.url"
                      :alt="partner.logo.data.attributes.alternativeText"
                    />
                  </a>
                </div>
              </div>
            </div>
          </Slide>
        </Carousel>
      </div>
      <div v-if="sideButtonsBool" class="sideButtons">
        <button
          @click="pageDesktop === 0 ? (pageDesktop = $refs.carousel.pageCount - 1) : pageDesktop--"
          id="arrowLEFT"
          class="clear-button arrowImg arrow btnhoverLEFT"
        ></button>
        <button
          @click="pageDesktop === $refs.carousel.pageCount - 1 ? (pageDesktop = 0) : pageDesktop++"
          id="arrowRIGHT"
          class="clear-button arrowImg arrow btnhoverRIGHT"
        ></button>
      </div>
      <div id="partner-mobile">
        <carousel
          @page-change="PageChangeDragSlide"
          ref="partnerSliderMobile"
          class="row"
          :perPage="1"
          :paginationEnabled="false"
          :scrollPerPage="true"
          :autoplay="false"
        >
          <slide v-for="partner in body.partner" :key="partner.id" class="row">
            <div
              class="justify-center col"
              data-aos-anchor-placement="center-bottom"
              data-aos="fade-up"
              data-aos-duration="600"
            >
              <div
                v-if="partner.url != null && partner.url != ''"
                class="partner border-top-sb shadow-sb"
              >
                <a :href="partner.url" target="_blank">
                  <div class="logo">
                    <img
                      :src="url + partner.logo.data.attributes.url"
                      :alt="partner.logo.data.attributes.alternativeText"
                    />
                  </div>
                </a>
              </div>
              <div v-else class="partner border-top-sb shadow-sb no-cursor">
                <a>
                  <img
                    class="logo"
                    :src="url + partner.logo.data.attributes.url"
                    :alt="partner.logo.data.attributes.alternativeText"
                  />
                </a>
              </div>
            </div>
          </slide>
        </carousel>
        <div class="text-center pagination-dots">
          <button
            type="button"
            name="button"
            v-for="i in this.sliderCount"
            :key="i.id"
            class="fa-circle slider-custom-pagination"
            :class="{ fas: i == activePage, far: i != activePage }"
            @click="PaginationCarousel(i)"
          ></button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";
import { getStrapiUrl } from "../helper";

export default {
  name: "Partner",
  data() {
    return {
      url: getStrapiUrl(),
      sliderCount: 0,
      activePage: 1,
      index: 1,
      page: 0,
      pageDesktop: 0,
      sideButtonsBool: false
    };
  },
  props: {
    body: {
      type: Object
    },
    pos: {
      type: Number
    }
  },
  components: {
    Carousel,
    Slide
  },
  methods: {
    PaginationCarousel(page) {
      if (typeof this.$refs.partnerSliderMobile !== "undefined") {
        const carousel = this.$refs.partnerSliderMobile;
        this.activePage = page;
        carousel.goToPage(page - 1);
      }
    },
    PageChangeDragSlide(page) {
      this.activePage = page + 1;
    },
    sideButtons: function() {
      var width = window.innerWidth;

      if (this.body.partner.length == 3 && width > 1800) {
        this.sideButtonsBool = false;
      } else if (this.body.partner.length > 3 && width > 1800) {
        this.sideButtonsBool = true;
      } else if (this.body.partner.length > 2 && width > 786 && width < 1800) {
        this.sideButtonsBool = true;
      } else if (this.body.partner.length == 2 && width > 786 && width < 1800) {
        this.sideButtonsBool = false;
      } else {
        this.sideButtonsBool = false;
      }
    },
    swipeOneElement: function() {
      if (this.sideButtonsBool) {
        this.pageDesktop === this.$refs.carousel.pageCount - 1
          ? (this.pageDesktop = 0)
          : this.pageDesktop++;
      }
    }
  },
  created() {
    window.addEventListener("resize", this.sideButtons);
  },
  destroyed() {
    window.removeEventListener("resize", this.sideButtons);
  },
  mounted() {
    this.sideButtons();
    if (this.$refs.partnerSliderMobile !== "undefined") {
      this.sliderCount = this.$refs.partnerSliderMobile.pageCount;
    }

    document.addEventListener("aos:in:partnerAOS", () => {
      this.swipeOneElement();
    });
  }
};
</script>

<style lang="css" scoped>
#partner {
  margin: 0;
}

#partner-desktop {
  margin-top: 0;
}

#partner-mobile {
  display: none;
}

.row,
.col {
  margin: 0;
}

.partner-row {
  margin-bottom: 5rem;
}

.col a {
  width: 100%;
  height: 100%;
  position: relative;
}

.logo {
  width: 95%;
  max-width: 95%;
  height: auto;
  max-height: 95%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  object-fit: cover;
}

.partner {
  display: flex;
  vertical-align: middle;
  justify-content: center;
  min-width: 40rem;
  max-width: 40rem;
  min-height: 21rem;
  max-height: 21rem;
}

.sideButtons {
  position: relative;
  bottom: 18rem;
}

.arrowImg {
  background-color: var(--tattoo-darker-gray);
  background-size: contain;
  background-position: center;
}

.arrow {
  width: 6rem;
  height: 6rem;
  border: none;
  outline: none;
  position: absolute;
}

#arrowLEFT {
  background: url(/assets/arrow-circle-dark-left.svg) no-repeat;
  left: -10rem;
}

#arrowRIGHT {
  background: url(/assets/arrow-circle-dark-right.svg) no-repeat;
  right: -10rem;
}

.no-cursor {
  cursor: default;
}

@media screen and (max-width: 109.375em) {
  .partner-row {
    padding: 0;
  }
}

@media screen and (max-width: 100em) {
  .partner-row {
    display: flex;
    justify-content: center;
  }

  .partner-row .col {
    display: flex;
    flex-grow: inherit;
  }
}

@media screen and (max-width: 75em) {
  #arrowLEFT {
    left: 0;
  }

  #arrowRIGHT {
    right: 0;
  }
}

@media screen and (max-width: 48em) {
  .partner {
    min-width: 95%;
    max-width: 95%;
    min-height: 25rem;
    max-height: 25rem;
    margin-bottom: 4rem;
  }

  .col a {
    width: 100%;
    height: 100%;
    position: relative;
  }

  .logo {
    width: 100%;
    max-width: 100%;
    height: auto;
    max-height: 100%;
    position: unset;
    top: unset;
    left: unset;
    transform: unset;
  }

  .logo img {
    object-fit: cover;
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 95%;
    max-width: 95%;
    height: auto;
    max-height: 95%;
  }

  .btnMobile {
    display: none;
  }

  #partner-mobile {
    display: block;
  }

  #partner-desktop {
    display: none;
  }

  .sideButtons {
    display: none;
  }
}

@media screen and (max-width: 37.75em) {
  .partner .logo {
    max-width: 100%;
    max-height: 100%;
  }
}
@media screen and (max-width: 22.5em) {
  .partner {
    margin: 2rem;
    min-height: 15rem;
    max-height: 15rem;
  }
}
</style>
