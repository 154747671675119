<template lang="html">
  <section :id="pos" class="ct-container">
    <div id="boxes">
      <div class="wrapper">
        <vue-markdown class="text-center head sb-headline-blue">
          ## {{ body.headline }}
        </vue-markdown>
        <div v-if="body.slider" id="boxes-desktop">
          <div class="row boxes">
            <div class="col" v-for="body in body.box" :key="body.id">
              <div
                class="wrapper-boxes"
                data-aos-anchor-placement="center-bottom"
                data-aos="fade-up"
                data-aos-duration="600"
              >
                <div class="bg-boxes shadow-sb border-top-sb">
                  <div class="inner-boxes">
                    <div
                      class="text-center"
                      v-if="
                        body.image.data &&
                          body.image.data.attributes.ext.toLowerCase().trim() == '.json'
                      "
                    >
                      <lord-icon
                        trigger="hover"
                        target="div"
                        :src="url + body.image.data.attributes.url"
                      ></lord-icon>
                    </div>
                    <div v-else-if="body.image.data">
                      <img
                        class="media"
                        :src="url + body.image.data.attributes.url"
                        :alt="body.image.data.attributes.alternativeText"
                      />
                    </div>
                    <vue-markdown
                      class="markdown sb-headline-3-blue inhalt"
                      :source="body.text"
                      v-bind:class="{ 'box-headline-padding': body.image.data == null }"
                    ></vue-markdown>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="!body.slider" id="boxes-no-slider">
          <div class="row boxes">
            <div class="col" v-for="body in body.box" :key="body.id">
              <div
                class="wrapper-boxes"
                data-aos-anchor-placement="center-bottom"
                data-aos="fade-up"
                data-aos-duration="600"
              >
                <div class="bg-boxes shadow-sb border-top-sb">
                  <div class="inner-boxes">
                    <div
                      class="text-center"
                      v-if="
                        body.image.data &&
                          body.image.data.attributes.ext.toLowerCase().trim() == '.json'
                      "
                    >
                      <lord-icon
                        trigger="hover"
                        target="div"
                        :src="url + body.image.data.attributes.url"
                      ></lord-icon>
                    </div>
                    <div v-else-if="body.image.data">
                      <img
                        class="media"
                        :src="url + body.image.data.attributes.url"
                        :alt="body.image.data.attributes.alternativeText"
                      />
                    </div>
                    <vue-markdown
                      class="markdown sb-headline-3-blue inhalt"
                      :source="body.text"
                      v-bind:class="{ 'box-headline-padding': body.image.data == null }"
                    ></vue-markdown>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="body.slider" id="boxes-mobile">
          <carousel
            :perPageCustom="[
              [0, 1],
              [1340, 2]
            ]"
            @page-change="PageChangeDragSlide"
            ref="boxSliderMobile"
            class="row"
            :paginationEnabled="false"
            :scrollPerPage="true"
            :autoplay="false"
            :navigateTo="pageDesktop"
          >
            <slide v-for="body in body.box" :key="body.id" class="row">
              <div class="col">
                <div
                  class="wrapper-boxes"
                  data-aos-anchor-placement="center-bottom"
                  data-aos="fade-up"
                  data-aos-duration="600"
                >
                  <div class="bg-boxes shadow-sb border-top-sb">
                    <div class="inner-boxes">
                      <div
                        class="text-center"
                        v-if="
                          body.image.data &&
                            body.image.data.attributes.ext.toLowerCase().trim() == '.json'
                        "
                      >
                        <lord-icon
                          trigger="hover"
                          target="div"
                          :src="url + body.image.data.attributes.url"
                        ></lord-icon>
                      </div>
                      <div v-else-if="body.image.data">
                        <img
                          class="media"
                          :src="url + body.image.data.attributes.url"
                          :alt="body.image.data.attributes.alternativeText"
                        />
                      </div>
                      <vue-markdown
                        class="markdown sb-headline-3-blue inhalt"
                        :source="body.text"
                        v-bind:class="{ 'box-headline-padding': body.image.data == null }"
                      ></vue-markdown>
                    </div>
                  </div>
                </div>
              </div>
            </slide>
          </carousel>
          <div class="sideButtons">
            <button
              @click="
                pageDesktop === 0
                  ? (pageDesktop = $refs.boxSliderMobile.pageCount - 1)
                  : pageDesktop--
              "
              id="arrowLEFT"
              class="clear-button arrowImg arrow btnhoverLEFT"
            ></button>
            <button
              @click="
                pageDesktop === $refs.boxSliderMobile.pageCount - 1
                  ? (pageDesktop = 0)
                  : pageDesktop++
              "
              id="arrowRIGHT"
              class="clear-button arrowImg arrow btnhoverRIGHT"
            ></button>
          </div>
          <div class="text-center pagination-dots">
            <button
              type="button"
              name="button"
              v-for="i in this.sliderCount"
              :key="i.id"
              class="fa-circle slider-custom-pagination"
              :class="{ fas: i == activePage, far: i != activePage }"
              @click="PaginationCarousel(i)"
            ></button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";
import VueMarkdown from "vue-markdown";
import { getStrapiUrl } from "../helper";
export default {
  name: "ContentTypeBoxes",
  data() {
    return {
      url: getStrapiUrl(),
      sliderCount: 0,
      activePage: 1,
      index: 1,
      page: 0,
      pageDesktop: 0
    };
  },
  props: {
    body: {
      type: Object
    },
    pos: {
      type: Number
    }
  },
  components: {
    VueMarkdown,
    Carousel,
    Slide
  },
  methods: {
    SlideCarouselMobile(value) {
      if (typeof this.$refs.boxSliderMobile !== "undefined") {
        const carousel = this.$refs.boxSliderMobile;
        const currentPage = carousel.currentPage;
        const pageCount = carousel.pageCount;
        if (value == "prev") {
          currentPage != 0 ? carousel.goToPage(currentPage - 1) : carousel.goToPage(pageCount - 1);
          this.activePage = this.activePage - 1;
        } else {
          currentPage < pageCount - 1 ? carousel.goToPage(currentPage + 1) : carousel.goToPage(0);
          this.activePage = this.activePage + 1;
        }
      }
    },
    PaginationCarousel(page) {
      if (typeof this.$refs.boxSliderMobile !== "undefined") {
        const carousel = this.$refs.boxSliderMobile;
        this.activePage = page;
        carousel.goToPage(page - 1);
      }
    },
    PageChangeDragSlide(page) {
      this.activePage = page + 1;
    }
  },
  mounted() {
    if (this.$refs.boxSliderMobile !== "undefined") {
      this.sliderCount = this.$refs.boxSliderMobile.pageCount;
    }
  }
};
</script>

<style lang="css" scoped>
#app .row {
  margin-left: 0;
  margin-right: 0;
}

lord-icon {
  width: 20rem;
  height: 20rem;
}

#boxes-mobile {
  display: none;
}

.boxes .col {
  display: flex;
  justify-content: center;
}

.wrapper-boxes {
  height: 100%;
  padding: 5rem;
}

.bg-boxes {
  max-width: 40rem;
  min-width: 40rem;
  min-height: 100%;
  height: 100%;
  background-color: var(--strawbinary-light-blue);
}

.inner-boxes {
  padding: 0 5rem;
  display: flex;
  flex-direction: column;
  text-align: justify;
  height: 100%;
  padding-bottom: 2rem;
}

.sideButtons {
  position: relative;
  bottom: 25rem;
}

.arrowImg {
  background-color: var(--tattoo-darker-gray);
  background-size: contain;
  background-position: center;
}

.arrow {
  width: 6rem;
  height: 6rem;
  border: none;
  display: inline-block;
  position: absolute;
}

#arrowLEFT {
  background: url(/assets/arrow-circle-dark-left.svg) no-repeat;
  left: -10rem;
}

#arrowRIGHT {
  background: url(/assets/arrow-circle-dark-right.svg) no-repeat;
  right: -10rem;
}

@media screen and (max-width: 112.5em) {
  lord-icon {
    width: 17rem;
    height: 17rem;
  }

  .wrapper-boxes {
    padding: 2rem;
  }
}

@media screen and (max-width: 106.25em) {
  .bg-boxes {
    margin: 2rem 2rem;
  }
}

@media screen and (max-width: 88.0625em) {
  .wrapper-boxes {
    padding: 2.5rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .bg-boxes {
    min-width: 40rem;
  }

  #boxes-mobile {
    display: block;
  }

  #boxes-desktop {
    display: none;
  }

  .pagination-dots {
    display: none;
  }

  .inner-boxes {
    padding: 0 3rem;
  }
}

@media screen and (max-width: 75em) {
  .boxes {
    width: 100%;
  }

  #arrowRIGHT {
    top: -2rem;
    right: 0;
  }

  #arrowLEFT {
    top: -2rem;
    left: 0;
  }
}

@media screen and (max-width: 50em) {
  lord-icon {
    width: 15rem;
    height: 15rem;
  }
}

@media screen and (max-width: 49.125rem) {
  .sideButtons {
    display: none;
  }

  .pagination-dots {
    display: block;
  }
}

@media screen and (max-width: 48.75em) {
  #boxes {
    margin-top: 4rem;
    margin-bottom: 5rem;
  }
}

@media screen and (max-width: 37.5em) {
  .inner-boxes {
    padding: 0 2rem 2rem;
  }
}

@media screen and (max-width: 33.125em) {
  .bg-boxes {
    min-width: 80vw;
    margin: 0;
  }
  .wrapper-boxes {
    padding: 2.5rem 0.5rem;
  }
}

@media screen and (max-width: 21.875em) {
  .inner-boxes {
    padding: 0 1rem;
  }
}
</style>
