<template lang="html">
  <ApolloQuery :query="require('../graphql/Footer.gql')" :variables="{ locale }">
    <template v-slot="{ result: { error, data } }">
      <footer v-if="data">
        <div class="top">
          <div class="row">
            <div class="col">
              <div class="contactdata">
                <p class="medium" v-if="data.footer.data.attributes.contactdata.name">
                  {{ data.footer.data.attributes.contactdata.name }}
                </p>
                <p v-if="data.footer.data.attributes.contactdata.strasse_hsnr">
                  {{ data.footer.data.attributes.contactdata.strasse_hsnr }}
                </p>
                <p
                  v-if="
                    data.footer.data.attributes.contactdata.plz &&
                      data.footer.data.attributes.contactdata.ort
                  "
                >
                  {{ data.footer.data.attributes.contactdata.plz }}
                  {{ data.footer.data.attributes.contactdata.ort }}
                </p>
                <p v-if="data.footer.data.attributes.contactdata.tel">
                  Telefon: {{ data.footer.data.attributes.contactdata.tel }}
                </p>
                <p v-if="data.footer.data.attributes.contactdata.email">
                  E-Mail: {{ data.footer.data.attributes.contactdata.email }}
                </p>
              </div>
              <div class="socialmedia">
                <div
                  class="plattform"
                  v-for="socialmedia in data.footer.data.attributes.socialmedia"
                  :key="socialmedia.id"
                >
                  <a target="_blank" :href="socialmedia.link">
                    <img
                      v-if="
                        socialmedia.icon.data.attributes.ext.toLowerCase().trim() == '.svg' ||
                          socialmedia.icon.data.attributes.ext.toLowerCase().trim() == '.png' ||
                          socialmedia.icon.data.attributes.ext.toLowerCase().trim() == '.jpeg' ||
                          socialmedia.icon.data.attributes.ext.toLowerCase().trim() == '.jpg'
                      "
                      :src="url + socialmedia.icon.data.attributes.url"
                      :alt="socialmedia.icon.data.attributes.alternativeText"
                    />
                    <lord-icon
                      v-if="socialmedia.icon.data.attributes.ext.toLowerCase().trim() == '.json'"
                      trigger="hover"
                      target="a"
                      :src="url + socialmedia.icon.data.attributes.url"
                    ></lord-icon>
                  </a>
                </div>
              </div>
            </div>
            <div class="col top-text">
              <vue-markdown
                class="link-white"
                :source="data.footer.data.attributes.text"
              ></vue-markdown>
            </div>
          </div>
        </div>
        <div class="bottom">
          <div class="row">
            <div class="col">
              <div class="logo-wrapper">
                <img
                  v-if="data.footer.data.attributes.logo"
                  :src="url + data.footer.data.attributes.logo.data.attributes.url"
                  :alt="data.footer.data.attributes.logo.alternativeText"
                />&nbsp;
                <span class="copyright fs-copyright" v-if="data.footer.data.attributes.copyright"
                  >&copy; {{ data.footer.data.attributes.copyright }}</span
                >
              </div>
            </div>
            <div class="col bottom-links">
              <div class="legal-links">
                <div v-for="link in data.footer.data.attributes.rechtlichelinks" :key="link.id">
                  <Button :button="link" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </template>
  </ApolloQuery>
</template>

<script>
import Button from "./element/Button.vue";
import VueMarkdown from "vue-markdown";
import { getStrapiUrl } from "../helper";
export default {
  name: "Footer",
  data() {
    return {
      url: getStrapiUrl(),
      locale: "de"
    };
  },
  components: {
    VueMarkdown,
    Button
  }
};
</script>

<style lang="css" scoped>
footer {
  color: white;
  font-family: "Rubik", sans-serif;
}

lord-icon {
  height: 4rem;
  width: 4rem;
}

.copyright {
  color: white;
  font-family: "Rubik", sans-serif;
  font-weight: 300;
}

.top {
  padding: 7.5rem 10vw;
  background-color: var(--strawbinary-grey);
}

.bottom {
  background-color: var(--strawbinary-dark-grey);
  padding: 3rem 10vw;
}

.logo-wrapper,
.plattform {
  display: inline-block;
}

.legal-links {
  display: inline-flex;
}

.legal-links div {
  padding-left: 5rem;
}

.legal-links a {
  margin: 0 1rem;
}

.top p {
  margin: 2rem 0;
}

.plattform img {
  width: 3.5rem;
}

.plattform:not(:first-child):not(:last-child) {
  margin: 1rem 4rem;
}

.logo-wrapper img {
  width: 20rem;
}

.bottom-links,
.top-text {
  display: flex;
  justify-content: flex-end;
}

@media screen and (max-width: 93.75em) {
  .top {
    padding: 7.5rem 5vw;
  }

  .bottom {
    padding: 3rem 5vw;
  }
}

@media screen and (max-width: 87.5em) {
  .legal-links div {
    padding-left: 2rem;
  }
}

@media screen and (max-width: 68.75em) {
  .legal-links {
    width: 100vw;
    justify-content: space-evenly;
  }

  .legal-links div {
    padding: unset;
  }

  .logo-wrapper {
    width: 100%;
    text-align: center;
    margin-bottom: 2.5rem;
  }
}

@media screen and (max-width: 40.625em) {
  .link-white {
    width: 100vw;
    margin-top: 2.5rem;
  }

  .top {
    padding: 3rem 5vw;
  }
}

@media screen and (max-width: 25em) {
  .plattform:not(:first-child):not(:last-child) {
    margin: 1rem 2.5rem;
  }
}
</style>
