<template lang="html">
  <section :id="pos">
    <div class="wrapper ct-container" id="callToAction">
      <vue-markdown
        class="markdown-medium sb-headline-3-blue sb-headline-blue"
        :source="body.inhalt"
      ></vue-markdown>
      <div class="text-center btn-wrapper">
        <Button :button="body.ctaLink" />
      </div>
    </div>
  </section>
</template>

<script>
import Button from "./element/Button.vue";
import VueMarkdown from "vue-markdown";
import { getStrapiUrl } from "../helper";
export default {
  name: "CallToAction",
  data() {
    return {
      url: getStrapiUrl()
    };
  },
  props: {
    body: {
      type: Object
    },
    pos: {
      type: Number
    }
  },
  components: {
    VueMarkdown,
    Button
  }
};
</script>

<style lang="css" scoped>
.wrapper {
  background-color: var(--strawbinary-light-blue);
}

.btn-wrapper {
  margin-top: 5rem;
}
</style>
