import Vue from "vue";
import App from "./App.vue";
import VueApollo from "vue-apollo";
import apolloClient from "./vue-apollo";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import router from "./router";
import VueMeta from "vue-meta";
import { loadAnimation } from "lottie-web";
import { defineLordIconElement } from "lord-icon-element";
import Vuelidate from "vuelidate";
import VueCookies from "vue-cookies";
import VueGtag from "vue-gtag";
import AOS from 'aos'
import 'aos/dist/aos.css'

AOS.init();

Vue.use(VueGtag, {
  config: { id: "UA-153714387-1" },
  bootstrap: false
});

Vue.use(Vuelidate);

defineLordIconElement(loadAnimation);
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
});

Vue.config.productionTip = false;
Vue.use(VueApollo);

const apolloProvider = new VueApollo({
  defaultClient: apolloClient
});

Vue.use(VueCookies);

new Vue({
  apolloProvider,
  router,
  render: h => h(App)
}).$mount("#app");
