<template>
  <section :id="pos" class="ct-container">
    <div class="ct-timeline">
      <vue-markdown class="text-center sb-headline-blue"> ## {{ body.headline }} </vue-markdown>
      <Timeline
        :timelineStep="body.timelineStep"
        :startFromZero="body.startFromZero"
        :id="pos.toString()"
      />
    </div>
  </section>
</template>

<script>
import VueMarkdown from "vue-markdown";
import Timeline from "./element/Timeline.vue";
import { getStrapiUrl } from "../helper";
export default {
  name: "ContentTypeTimeline",
  data() {
    return {
      url: getStrapiUrl()
    };
  },
  props: {
    body: {
      type: Object
    },
    pos: {
      type: Number
    }
  },
  components: {
    VueMarkdown,
    Timeline
  }
};
</script>

<style lang="css" scoped>
.ct-timeline {
  margin: 7rem 0;
}
</style>
