<template lang="html">
  <section :id="pos">
    <div id="landingpackages">
      <div class="bg-landingpackages">
        <img src="/assets/LandingPackagesBackground.svg" alt="Background" />
      </div>
      <div class="logo-landingpackages" @click="home">
        <img
          :src="url + body.logo.data.attributes.url"
          :alt="body.logo.data.attributes.alternativeText"
        />
      </div>
      <div class="ct-landingpackages-container">
        <div class="gaphic-landingpackages">
          <img
            :src="url + body.graphic.data.attributes.url"
            :alt="body.graphic.data.attributes.alternativeText"
          />
        </div>
        <div class="main-headline-outer">
          <h1 class="main-headline">
            <p class="fs-landing-small">{{ body.preheadline }}</p>
            <span id="typewriterHeadline" class="fs-landing-big">{{ body.headline }}</span>
          </h1>
        </div>
        <div class="text-container">
          <vue-markdown
            class="markdown-medium info-box-text"
            :source="body.paragraph"
          ></vue-markdown>
        </div>
        <div class="btn-wrapper">
          <Button v-for="button in body.button" :button="button" :key="button.id" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import VueMarkdown from "vue-markdown";
import { getStrapiUrl } from "../helper";
import Button from "./element/Button.vue";

export default {
  name: "LandingPackages",
  data() {
    return {
      url: getStrapiUrl()
    };
  },
  props: {
    body: {
      type: Object
    },
    pos: {
      type: Number
    }
  },
  components: {
    VueMarkdown,
    Button
  },
  methods: {
    home() {
      this.$router.push("/");
    }
  }
};
</script>
<style lang="css" scoped>
#landingpackages {
  width: 100vw;
  height: max-content;
  position: relative;
}

.bg-landingpackages {
  position: absolute;
  height: 90vh;
  top: 0;
  right: 0;
  z-index: -2;
}

.bg-landingpackages img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: top right;
}

.logo-landingpackages {
  width: 30rem;
  height: 9rem;
  background-color: var(--strawbinary-dark-grey);
  padding: 2rem 3rem;
}

.logo-landingpackages:hover {
  cursor: pointer;
}

.logo-landingpackages img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

.gaphic-landingpackages {
  max-width: 45%;
  min-width: 30%;
  position: absolute;
  top: 18%;
  right: 10%;
}

.gaphic-landingpackages img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

.main-headline-outer {
  margin-top: 8%;
  margin-bottom: 3rem;
  width: 50%;
}

.main-headline #typewriterHeadline {
  font-family: "Rubik", sans-serif;
  font-weight: 500;
}

.main-headline p {
  font-weight: 300;
  font-family: "Rubik", sans-serif;
  margin-bottom: 2rem;
}

.main-headline {
  border-bottom: 0.8rem solid var(--strawbinary-dark-grey);
  padding-bottom: 2rem;
}

.text-container {
  width: 40%;
  margin-bottom: 4rem;
}

.btn-wrapper {
  margin-left: -0.6rem;
}

@media screen and (max-width: 112.5em) {
  .bg-landingpackages {
    height: auto;
    width: 50vw;
  }
}

@media screen and (max-width: 68.75em) {
  #landingpackages {
    height: unset;
  }

  .logo-landingpackages {
    display: none;
  }

  .bg-landingpackages {
    height: 100vh;
    width: unset;
  }

  .gaphic-landingpackages {
    max-width: 50%;
    min-width: 45%;
    position: unset;
    margin: 0 auto;
    padding-top: 6rem;
  }

  .main-headline-outer {
    width: 80%;
  }

  .text-container {
    width: 80%;
    margin-bottom: 2rem;
  }
}

@media screen and (max-width: 48em) {
  .gaphic-landingpackages {
    max-width: 60%;
    min-width: 55%;
  }

  .main-headline p {
    margin-bottom: 1rem;
  }

  .main-headline {
    padding-bottom: 1rem;
    border-bottom: 0.5rem solid var(--strawbinary-dark-grey);
  }

  .text-container {
    margin-bottom: 2rem;
  }
}

@media screen and (max-width: 33.75em) {
  .gaphic-landingpackages {
    max-width: 70%;
    min-width: 60%;
  }

  .main-headline-outer {
    width: 100%;
    margin-bottom: 2rem;
  }

  .main-headline p {
    margin-bottom: 0.5rem;
  }
  .text-container {
    width: 90%;
  }
}
</style>
