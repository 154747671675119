var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{staticClass:"button",attrs:{"type":"button"}},[(
      _vm.button.page.data &&
        _vm.button.page.data.attributes !== null &&
        (_vm.button.url == '' || _vm.button.url == null)
    )?_c('router-link',{class:{
      'btn-primary-sb': _vm.button.style == 'primary',
      'btn-secondary-sb': _vm.button.style == 'secondary',
      'btn-link-sb': _vm.button.style == 'link',
      'btn-text-sb': _vm.button.style == 'text',
      'btn-dark-primary-sb': _vm.button.style == 'dark_primary',
      'btn-blue-icon-sb': _vm.button.style == 'blue_icon',
      'btn-nav-link': _vm.button.style == 'nav_link',
      'active': _vm.button.url === '/' && _vm.$route.path === '/'
    },attrs:{"to":{ path: '/page/' + _vm.button.page.data.attributes.slug }}},[_c('label',[_vm._v(" "+_vm._s(_vm.button.label)+" ")]),(
        _vm.button.icon &&
          _vm.button.icon.data &&
          _vm.button.icon.data.attributes.ext.toLowerCase().trim() !== '.json'
      )?_c('img',{staticClass:"icon",attrs:{"src":_vm.url + _vm.button.icon.data.attributes.url,"alt":_vm.button.icon.data.attributes.alternativeText}}):_vm._e(),(
        _vm.button.icon &&
          _vm.button.icon.data &&
          _vm.button.icon.data.attributes.ext.toLowerCase().trim() == '.json'
      )?_c('lord-icon',{attrs:{"target":"a","trigger":"hover","src":_vm.url + _vm.button.icon.data.attributes.url}}):_vm._e()],1):_vm._e(),(_vm.button.url !== null && _vm.button.url !== '')?_c('a',{class:{
      'btn-primary-sb': _vm.button.style == 'primary',
      'btn-secondary-sb': _vm.button.style == 'secondary',
      'btn-link-sb': _vm.button.style == 'link',
      'btn-text-sb': _vm.button.style == 'text',
      'btn-dark-primary-sb': _vm.button.style == 'dark_primary',
      'btn-blue-icon-sb': _vm.button.style == 'blue_icon',
      'btn-nav-link': _vm.button.style == 'nav_link',
      'active': _vm.button.url === '/' && _vm.$route.path === '/'
    },attrs:{"href":_vm.button.url}},[_c('label',[_vm._v(" "+_vm._s(_vm.button.label)+" ")]),(
        _vm.button.icon &&
          _vm.button.icon.data &&
          _vm.button.icon.data.attributes.ext.toLowerCase().trim() !== '.json'
      )?_c('img',{staticClass:"icon",attrs:{"src":_vm.url + _vm.button.icon.data.attributes.url,"alt":_vm.button.icon.data.attributes.alternativeText}}):_vm._e(),(
        _vm.button.icon &&
          _vm.button.icon.data &&
          _vm.button.icon.data.attributes.ext.toLowerCase().trim() == '.json'
      )?_c('lord-icon',{attrs:{"target":"a","trigger":"hover","src":_vm.url + _vm.button.icon.data.attributes.url}}):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }