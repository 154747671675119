<template>
  <main v-if="pages.data">
    <div v-if="$apollo.loading"><Loading /></div>
    <div v-for="(body, index) in pages.data[0].attributes.body" :key="body.id">
      <div v-if="body.__typename === 'ComponentContentTypeCtLandingsection'">
        <ContentTypeLandingsection1 :body="body" :pos="index" />
      </div>
      <div v-if="body.__typename === 'ComponentContentTypeCtExtBox'">
        <ContentTypeExtendedBoxes :body="body" :pos="index" />
      </div>
      <div v-if="body.__typename === 'ComponentContentTypeCtPartner'">
        <ContentTypePartner :body="body" :pos="index" />
      </div>
      <div v-if="body.__typename === 'ComponentContentTypeCtCallToAction'">
        <ContentTypeCallToAction :body="body" :pos="index" />
      </div>
      <div v-if="body.__typename === 'ComponentContentTypeCtKundenHighlight'">
        <ContentTypeKundenHighlight :body="body" :pos="index" />
      </div>
      <div v-if="body.__typename === 'ComponentContentTypeCtBoxes'">
        <ContentTypeBoxes :body="body" :pos="index" />
      </div>
      <div v-if="body.__typename === 'ComponentContentTypeCtText'">
        <ContentTypeText :body="body" :pos="index" />
      </div>
      <div v-if="body.__typename === 'ComponentContentTypeCtAboutUs'">
        <ContentTypeAboutUs :body="body" :pos="index" />
      </div>
      <div v-if="body.__typename === 'ComponentContentTypeCtDropdown'">
        <ContentTypeDropdown :body="body" :pos="index" />
      </div>
      <div v-if="body.__typename === 'ComponentContentTypeCtTimeline'">
        <ContentTypeTimeline :body="body" :pos="index" />
      </div>
      <div v-if="body.__typename === 'ComponentContentTypeCtImportantPoints'">
        <ContentTypeImportantPoints :body="body" :pos="index" />
      </div>
      <div v-if="body.__typename === 'ComponentContentTypeComponentCollection'">
        <ContentTypeComponentCollection :body="body" :pos="index" />
      </div>
      <div v-if="body.__typename === 'ComponentContentTypeCtHorizontalTimeline'">
        <ContentTypeHorizontalTimeline :body="body" :pos="index" />
      </div>
      <div v-if="body.__typename === 'ComponentContentTypeCtLandingpackages'">
        <ContentTypeLandingPackages :body="body" :pos="index" />
      </div>
      <div v-if="body.__typename === 'ComponentContentTypeCtLandingdetail'">
        <ContentTypeLandingDetail :body="body" :pos="index" />
      </div>
      <div v-if="body.__typename === 'ComponentContentTypeCtInbetween'">
        <ContentTypeInBetween :body="body" :pos="index" />
      </div>
    </div>
    <Footer />
  </main>
</template>

<script>
import Footer from "../components/Footer.vue";
import ContentTypeLandingsection1 from "../components/ContentTypeLandingsection1.vue";
import ContentTypeExtendedBoxes from "../components/ContentTypeExtendedBoxes.vue";
import ContentTypePartner from "../components/ContentTypePartner.vue";
import ContentTypeCallToAction from "../components/ContentTypeCallToAction.vue";
import ContentTypeKundenHighlight from "../components/ContentTypeKundenHighlight.vue";
import ContentTypeBoxes from "../components/ContentTypeBoxes.vue";
import ContentTypeText from "../components/ContentTypeText.vue";
import ContentTypeAboutUs from "../components/ContentTypeAboutUs.vue";
import ContentTypeDropdown from "../components/ContentTypeDropdown.vue";
import ContentTypeTimeline from "../components/ContentTypeTimeline.vue";
import ContentTypeImportantPoints from "../components/ContentTypeImportantPoints.vue";
import ContentTypeComponentCollection from "../components/ContentTypeComponentCollection.vue";
import ContentTypeHorizontalTimeline from "../components/ContentTypeHorizontalTimeline.vue";
import Loading from "../components/Loading.vue";
import ContentTypeLandingPackages from "../components/ContentTypeLandingPackages.vue";
import ContentTypeLandingDetail from "../components/ContentTypeLandingDetail.vue";
import ContentTypeInBetween from "../components/ContentTypeInBetween.vue";

export default {
  name: "Page",
  data() {
    return {
      slug: this.$route.params.slug,
      locale: "de",
      pages: []
    };
  },
  computed: {
    indexing() {
      if (this.pages.data[0].attributes.Seo.preventIndexing) {
        return "noindex";
      }
      return "index";
    }
  },
  metaInfo() {
    if (this.pages.data) {
      return {
        title: `${this.pages.data[0].attributes.titel} - Strawbinary`,
        meta: [
          {
            vmid: "description",
            name: "description",
            content: this.pages.data[0].attributes.Seo.metaDescription
          },
          {
            vmid: "keywords",
            name: "keywords",
            content: this.pages.data[0].attributes.Seo.keywords
          },
          {
            vmid: "robots",
            name: "robots",
            content: this.indexing
          }
        ]
      };
    } else {
      return "Strawbinary";
    }
  },
  components: {
    Footer,
    ContentTypeLandingsection1,
    ContentTypeExtendedBoxes,
    ContentTypePartner,
    ContentTypeCallToAction,
    ContentTypeKundenHighlight,
    ContentTypeBoxes,
    ContentTypeText,
    ContentTypeAboutUs,
    ContentTypeDropdown,
    ContentTypeTimeline,
    ContentTypeImportantPoints,
    ContentTypeComponentCollection,
    ContentTypeHorizontalTimeline,
    Loading,
    ContentTypeLandingPackages,
    ContentTypeLandingDetail,
    ContentTypeInBetween
  },
  apollo: {
    pages: {
      query: require("../graphql/Page.gql"),
      variables() {
        return {
          locale: { eq: this.locale },
          slug: { eq: this.slug }
        };
      }
    }
  }
};
</script>

<style lang="css" scoped></style>
