<template lang="html">
  <section :id="pos">
    <div id="landingdetail">
      <div class="bg-landingdetail">
        <div class="logo-landingdetail" @click="home">
          <img
            :src="url + body.logo.data.attributes.url"
            :alt="body.logo.data.attributes.alternativeText"
          />
        </div>
        <div class="ct-landingdetail-container">
          <div class="main-headline-outer">
            <h1 class="main-headline">
              <p class="fs-landing-small">{{ body.preheadline }}</p>
              <span id="typewriterHeadline" class="fs-landing-big">{{ body.headline }}</span>
            </h1>
          </div>
          <div class="text-container">
            <vue-markdown
              class="markdown-medium info-box-text"
              :source="body.paragraph"
            ></vue-markdown>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import VueMarkdown from "vue-markdown";
import { getStrapiUrl } from "../helper";
export default {
  name: "LandingDetail",
  data() {
    return {
      url: getStrapiUrl()
    };
  },
  props: {
    body: {
      type: Object
    },
    pos: {
      type: Number
    }
  },
  components: {
    VueMarkdown
  },
  methods: {
    home() {
      this.$router.push("/");
    }
  }
};
</script>

<style lang="css" scoped>
#landingdetail {
  width: 100vw;
}

.bg-landingdetail {
  background: url(/assets/LandingDetailBackground.svg) no-repeat;
  background-size: cover;
  background-position: top;
}

.logo-landingdetail {
  width: 30rem;
  height: 9rem;
  background-color: var(--strawbinary-dark-grey);
  padding: 2rem 3rem;
}

.logo-landingdetail:hover {
  cursor: pointer;
}

.logo-landingdetail img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

.main-headline-outer {
  margin-top: 10%;
  margin-bottom: 3rem;
  width: 50%;
}

.main-headline #typewriterHeadline {
  font-family: "Rubik", sans-serif;
  font-weight: 500;
}

.main-headline p {
  font-weight: 300;
  font-family: "Rubik", sans-serif;
  margin-bottom: 2rem;
}

.main-headline {
  border-bottom: 0.8rem solid var(--strawbinary-dark-grey);
  padding-bottom: 2rem;
}

.text-container {
  width: 80%;
}

@media screen and (max-width: 68.75em) {
  .logo-landingdetail {
    display: none;
  }

  .bg-landingdetail {
    margin-top: 6rem;
  }
}

@media screen and (max-width: 48em) {
  .main-headline-outer {
    margin-bottom: 2rem;
  }

  .main-headline p {
    margin-bottom: 1rem;
  }

  .main-headline {
    border-bottom: 0.4rem solid var(--strawbinary-dark-grey);
    padding-bottom: 1rem;
  }
}

@media screen and (max-width: 40.625em) {
  .main-headline-outer {
    width: 80%;
  }

  .text-container {
    width: 90%;
  }
}
</style>
