<template lang="html">
  <section :id="pos">
    <div id="leistungen">
      <div class="wrapper">
        <vue-markdown class="text-center head sb-headline-white">
          ## {{ body.headline }}
        </vue-markdown>
        <div class="row leistungen">
          <div class="col single-col" v-for="(body, index) in body.extboxContainer" :key="body.id">
            <div
              class="wrapper-leistung"
              data-aos-anchor-placement="center-bottom"
              data-aos="fade-down"
              data-aos-duration="800"
              :data-aos-delay="300 * index"
            >
              <div class="bg-leistung border-top-sb">
                <div class="inner-leistung">
                  <vue-markdown
                    class="text-center sb-headline-3-blue headline-wrapper d-flex align-items-center"
                  >
                    ### {{ body.title }}
                  </vue-markdown>
                  <div v-if="body.graphic.data.attributes.ext.toLowerCase().trim() == '.json'">
                    <lord-icon
                      trigger="hover"
                      :src="url + body.graphic.data.attributes.url"
                    ></lord-icon>
                  </div>
                  <div v-else>
                    <img
                      class="media"
                      :src="url + body.graphic.data.attributes.url"
                      :alt="body.graphic.data.attributes.alternativeText"
                    />
                  </div>
                  <vue-markdown class="inhalt markdown" :source="body.text"></vue-markdown>
                  <Button :button="body.link" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="body.Button" class="text-center button-wrapper">
          <Button :button="body.Button" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Button from "./element/Button.vue";
import VueMarkdown from "vue-markdown";
import { getStrapiUrl } from "../helper";
export default {
  name: "Leistung",
  data() {
    return {
      url: getStrapiUrl()
    };
  },
  props: {
    body: {
      type: Object
    },
    pos: {
      type: Number
    }
  },
  components: {
    VueMarkdown,
    Button
  }
};
</script>

<style lang="css" scoped>
lord-icon {
  width: 30rem;
  height: 30rem;
}

.row,
.col {
  margin: 0;
}

.wrapper-leistung {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.bg-leistung {
  background-color: white;
  max-width: 40rem;
  min-width: 40rem;
  max-height: max-content;
  min-height: 30vw;
  margin: 2rem 5rem;
}

.inner-leistung {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 3rem 4rem;
  min-height: 80rem;
  max-height: max-content;
}

.media {
  width: 25rem;
  height: auto;
}

.wrapper {
  background-color: var(--strawbinary-dark-grey);
  margin: 15vw 0;
  position: relative;
}

.wrapper::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  background: var(--strawbinary-dark-grey);
  z-index: 0;
  clip-path: polygon(100% 100%, 100% 0%, -5% 100%);
  top: calc(-10vw + 0.3rem);
  height: 10vw;
}

.wrapper::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  background: var(--strawbinary-dark-grey);
  z-index: 0;
  clip-path: polygon(105% 0, 0 0, 0 100%);
  top: calc(100% - 0.2rem);
  height: 10vw;
}

.leistungen {
  padding: 0 30rem;
}

.inhalt {
  width: 100%;
  font-family: "Barlow Semi Condensed", sans-serif;
}

.media,
lord-icon,
.inhalt {
  padding: 2rem 0;
}

.button-wrapper .button {
  display: inline-flex;
}

.headline-wrapper {
  height: 10rem;
}

@media screen and (max-width: 999em) {
  .bg-leistung {
    margin: 2rem 0;
  }
}

@media screen and (max-width: 134.375em) {
  .leistungen {
    padding: 0 15rem;
  }
}

@media screen and (max-width: 129.375em) {
  .leistungen {
    padding: 0 10rem;
  }
}

@media screen and (max-width: 108.75em) {
  .leistungen {
    padding: 0 3rem;
  }
}

@media screen and (max-width: 103.125em) {
  .wrapper {
    margin: 20vw 0;
  }
}

@media screen and (max-width: 87.5em) {
  .single-col {
    max-width: 100%;
    min-width: 100%;
  }

  .bg-leistung {
    max-width: 50rem;
    min-width: 50rem;
  }

  .headline-wrapper {
    height: 100%;
  }
}

@media screen and (max-width: 62.5em) {
  .wrapper {
    padding: 5vw 0;
  }
}

@media screen and (max-width: 37.5em) {
  .bg-leistung {
    max-width: auto;
    min-width: auto;
    width: 100%;
  }
}

@media screen and (max-width: 34.375em) {
  .media {
    width: 20rem;
    height: 20rem;
    padding: 1rem 0;
  }

  .inhalt {
    padding: 1.5rem 0;
  }

  .inner-leistung {
    max-height: 100%;
    min-height: 100%;
  }
}

@media screen and (max-width: 31.25em) {
  .bg-leistung {
    min-width: unset;
    width: 90%;
    max-width: unset;
  }

  .wrapper {
    padding: 8vw 0;
  }
}

@media screen and (max-width: 19.375em) {
  .media {
    width: 20rem;
    height: auto;
  }
}
</style>
