<template lang="html">
  <ApolloQuery :query="require('../graphql/Nav.gql')" :variables="{ locale }">
    <template v-slot="{ result: { error, data } }">
      <nav id="nav" v-if="data">
        <div id="nav-desktop" :class="{ navOpen: navOpen }">
          <div id="menuToggle">
            <input @click="navTrigger" type="checkbox" />
            <span></span>
            <span></span>
            <span></span>
          </div>
          <div class="logo-wrapper-outer">
            <div class="logo-wrapper-inner">
              <img class="logo" src="/assets/Strawbinary_Logo_Weiss_flat_filled.svg" />
            </div>
          </div>
          <div class=""></div>
        </div>
        <div id="nav-mobile">
          <div style="position:relative;">
            <transition name="slide-fade">
              <img
                v-show="!navOpen"
                class="logo"
                @click="home"
                src="/assets/Strawbinary_Logo_Weiss_flat.svg"
              />
            </transition>
          </div>
          <div id="menuToggle">
            <input @click="navTrigger" type="checkbox" />
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <div id="sidenav" class="sidenav">
          <div class="sidenav-bg">
            <div class="sidenav-bg-left">
              <div class="sidenav-bg-right">
                <div class="sidenav-bg-dots">
                  <div class="row">
                    <div class="col">
                      <div class="link-container">
                        <Button
                          v-on:click.native="navTrigger"
                          :button="{
                            label: 'Home',
                            url: '/',
                            style: 'nav_link'
                          }"
                        />
                        <Button
                          v-on:click.native="navTrigger"
                          v-for="link in data.mainNav.data.attributes.link"
                          :button="link"
                          :key="link.id"
                        />
                      </div>
                    </div>
                    <div class="col logo-call-to-action-wrapper">
                      <div class="logo-call-to-action">
                        <img
                          class="nav-logo"
                          src="/assets/Strawbinary_Logo_Weiss.svg"
                          alt="Strawbinary Logo"
                        />
                        <div class="text-center call-to-action">
                          <vue-markdown
                            class="markdown-rubik white"
                            :source="data.mainNav.data.attributes.call_to_action_text"
                          ></vue-markdown>
                          <br />
                          <Button
                            v-on:click.native="navTrigger"
                            :button="data.mainNav.data.attributes.call_to_action_link"
                            :key="data.mainNav.data.attributes.call_to_action_link.id"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </template>
  </ApolloQuery>
</template>

<script>
import Button from "./element/Button.vue";
import VueMarkdown from "vue-markdown";
import $ from "jquery";
import { getStrapiUrl } from "../helper";
export default {
  name: "Nav",
  data() {
    return {
      url: getStrapiUrl(),
      locale: "de",
      navOpen: false
    };
  },
  components: {
    Button,
    VueMarkdown
  },
  methods: {
    navTrigger: function() {
      if (this.navOpen) {
        this.navOpen = false;
        $("#menuToggle input").prop("checked", false);
        document.getElementById("sidenav").style.left = "100%";
        $("html").css("overflow-y", "auto");
      } else {
        document.getElementById("sidenav").style.left = "0";
        $("#menuToggle input").prop("checked", true);
        this.navOpen = true;
        $("html").css("overflow-y", "hidden");
      }
    },
    home: function() {
      this.$router.push("/");
    }
  }
};
</script>

<style lang="css" scoped>

.slide-fade-leave-active,
.slide-fade-enter-active {
  transition: all 0.8s ease;
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY(-100%) !important;
  opacity: 0;
}

.navOpen#nav-desktop {
  background: transparent;
}

.navOpen .logo-wrapper-outer {
  display: none;
}

#nav-mobile {
  display: none;
  position: fixed;
  background: var(--strawbinary-dark-grey);
  z-index: 1998;
  top: 0;
  height: 7rem;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
}

#nav-desktop {
  background: var(--strawbinary-dark-grey);
  position: fixed;
  z-index: 9999;
  right: 0;
  top: 0;
  flex-direction: column;
  justify-content: center;
  display: flex;
  height: 100%;
  width: 8rem;
  justify-content: space-between;
}

.sidenav-bg-dots {
  height: 100%;
}

.sidenav-bg-right {
  background-image: url("/assets/nav-bg-right.svg");
  background-repeat: no-repeat;
  background-position: 150%;
  background-size: contain;
  height: 100%;
}

.sidenav-bg-left {
  background-image: url("/assets/nav-bg-left.svg");
  background-repeat: no-repeat;
  background-position: -10%;
  background-size: 60%;
  height: 100%;
}

#menuToggle {
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  z-index: 1998;
  -webkit-user-select: none;
  user-select: none;
  margin-right: 1rem;
  width: 100%;
  align-items: center;
  margin-top: 3rem;
}

#menuToggle input {
  display: block;
  width: 4rem;
  height: 4rem;
  position: absolute;
  cursor: pointer;
  opacity: 0;
  z-index: 1999;
  -webkit-touch-callout: none;
  top: -0.5rem;
  left: 1.8rem;
}

#menuToggle span {
  display: flex;
  width: 3.3rem;
  height: 0.2rem;
  margin-bottom: 1rem;
  position: relative;
  background: white;
  border-radius: 0.3rem;
  z-index: 1998;
  transform-origin: 0.35rem -0.05rem;
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
    background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
}

#menuToggle span:first-child {
  transform-origin: 0% 0%;
}

#menuToggle span:nth-last-child(2) {
  transform-origin: 0% 100%;
}

#menuToggle input:checked ~ span {
  opacity: 1;
  transform: rotate(-45deg) translate(0, 0px);
  background: white;
}

#menuToggle input:checked ~ span:nth-last-child(3) {
  opacity: 1;
  transform: rotate(45deg);
  transform-origin: -0.25rem 0.6rem;
}

#menuToggle input:checked ~ span:nth-last-child(2) {
  transform: rotate(0deg) scale(0.2, 0.2);
  opacity: 0;
}

.logo-wrapper-outer {
  display: table;
}

.logo-wrapper-inner {
  padding: 50% 0;
  height: 0;
}

.logo {
  height: 2.5rem;
  display: block;
  transform-origin: top left;
  transform: rotate(90deg) translate(0, -210%);
  margin-top: -50%;
}

#sidenav {
  width: 100%;
  left: 100%;
  text-align: left;
  z-index: 999;
}

.sidenav {
  height: 100vh;
  width: 0;
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  overflow-x: hidden;
  transition: 0.5s;
}

.sidenav-bg {
  background-color: var(--strawbinary-dark-grey);
  height: 100vh;
  overflow-y: auto;
}

.row {
  margin: 0;
}

.row,
.col {
  height: 100%;
}

.link-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-left: 8vw;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  height: 100%;
}

.link-container button {
  margin: 1.5rem 0;
}

.nav-logo {
  height: 30rem;
}

.logo-call-to-action {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

@media screen and (max-width: 125em) {
  #nav-desktop {
    width: 7rem;
  }
}

@media screen and (orientation: landscape) {
  .sidenav-bg-right,
  .sidenav-bg-left {
    height: inherit;
  }
}

@media screen and (max-width: 109.375em) {
  .sidenav-bg-right {
    background-position: 30vw;
    background-size: cover;
    padding-right: 5rem;
  }
  .nav-logo {
    height: 25rem;
  }
}

@media screen and (max-width: 106.25em) {
  #nav-desktop {
    width: 6rem;
  }
  .logo {
    height: 2rem;
    transform: rotate(90deg) translate(0, -198%);
  }
}

@media screen and (max-width: 87.5em) {
  .nav-logo {
    height: 20rem;
  }

  .link-container {
    padding-left: 5vw;
  }

  .link-container button {
    margin: 0.5rem 0;
  }
}

@media screen and (max-width: 78.125em) {
  .sidenav-bg-right {
    background-image: url("/assets/nav-bg-bottom.svg");
    background-position: 0 30rem;
  }

  .logo-call-to-action-wrapper {
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 8rem;
    width: 100%;
  }

  .sidenav-bg-dots {
    background-image: url("/assets/dots_dark.svg");
    background-size: 50%;
    background-repeat: no-repeat;
    background-position: 150% 85%;
  }

  .nav-logo {
    display: none;
  }

  .sidenav-bg-left {
    background-position: -10rem 6rem;
    background-size: 60rem;
  }

  .row,
  .col,
  .link-container {
    height: auto;
  }

  .link-container {
    padding-top: 7rem;
  }
}

@media screen and (max-width: 62.5em) {

  .link-container {
    min-width: 35rem;
  }

  .logo-call-to-action {
    width: 65rem;
    min-width: 20rem;
  }
}

@media screen and (max-width: 68.75em) {
  #nav-desktop {
    display: none;
  }
  #nav-mobile {
    display: flex;
  }

  #sidenav {
    padding-top: 7rem;
  }

  .sidenav-bg {
    height: calc(100vh - 7rem);
  }

  .sidenav-bg-right {
    padding-right: 0;
  }

  .logo {
    transform: none;
    margin: 0;
    padding: 2rem;
    transform-origin: unset;
    height: 100%;
  }

  #menuToggle {
    align-items: flex-end;
    margin-top: 1rem;
    margin-right: 3rem;
    width: auto;
  }

  #menuToggle input {
    top: 0.5rem;
    left: unset;
    right: -0.3rem;
  }
  .link-container {
    padding-top: 3rem;
  }
}

@media screen and (max-width: 25em) {
  .logo-call-to-action {
    width: 100%;
  }
}

@media screen and (max-width: 21.875em) {
  .link-container {
    min-width: 23rem;
  }
}
</style>
