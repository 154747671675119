<template>
  <article :id="pos">
    <div id="Loading">
      <div class="background">
        <div class="img-wrapper">
          <img src="/assets/Strawbinary_Logo_Duotone.svg" alt="Strawbinary Logo" />
        </div>
        <div class="img-background">
          <img src="/assets/wave-background.svg" alt="Background" />
        </div>
        <lord-icon trigger="loop" src="/assets/loading-icon.json"></lord-icon>
      </div>
    </div>
  </article>
</template>

<script>
export default {
  name: "CTLoadingComponent",
  props: {
    body: {
      type: Object
    },
    pos: {
      type: Number
    }
  },
  mounted() {
    document.documentElement.style.overflow = "hidden";
  },
  destroyed() {
    document.documentElement.style.overflow = "auto";
  }
};
</script>

<style lang="css" scoped>
.background {
  background: linear-gradient(to bottom, #e7ebef 80vh, #fff 20vh);
  z-index: 0;
  width: 100vw;
  height: 100vh;
}

.img-wrapper {
  width: 25%;
  height: auto;
  z-index: 999;
  padding-top: 20rem;
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -70%);
}

.img-background img {
  width: 100vw;
  z-index: 998;
  position: absolute;
  bottom: 18vh;
}

lord-icon {
  width: 8%;
  height: auto;
  z-index: 999;
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -30%);
}

@media screen and (max-width: 100em) {
  .img-wrapper {
    width: 40%;
  }

  lord-icon {
    width: 10%;
  }
}

@media screen and (max-width: 68.75em) {
  .img-wrapper {
    width: 50%;
  }

  lord-icon {
    width: 15%;
  }
}

@media screen and (max-width: 37.5em) {
  .background {
    background: linear-gradient(to bottom, #e7ebef 70vh, #fff 30vh);
    z-index: 0;
    width: 100vw;
    height: 100vh;
  }

  .img-wrapper {
    width: 80%;
  }

  .img-background img {
    bottom: 28vh;
  }

  lord-icon {
    width: 30%;
    top: 75%;
  }
}
</style>
