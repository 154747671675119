<template lang="html">
  <main>
    <div class="dropdown-wrapper shadow-sb">
      <button v-on:click="_dropdown" class="border-top-sb btn-style">
        <vue-markdown class="dropdown-head sb-headline-3-blue fs-dropdown">
          ### {{ dropdown.headline }}
        </vue-markdown>
        <lord-icon
          class="dropdown-icon"
          target="button"
          src="/assets/34-arrow-up-outline-edited.json"
          :class="[isOpen ? 'rotateUP' : 'rotateDOWN']"
        ></lord-icon>
      </button>
      <transition name="slide">
        <div class="dropdown-style column" v-if="isOpen" v-bind:class="{ close: !isOpen }">
          <vue-markdown
            name="Dropdown"
            class="markdown"
            :source="dropdown.dropdowntext"
          ></vue-markdown>
        </div>
      </transition>
    </div>
  </main>
</template>

<script>
import VueMarkdown from "vue-markdown";
export default {
  name: "Dropdown",
  props: {
    dropdown: {
      type: Object
    },
    isActive: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  components: {
    VueMarkdown
  },
  data() {
    return {
      isOpen: false
    };
  },
  created() {
    this.$watch("isActive", () => {
      if (!this.isActive && this.isOpen) {
        this.isOpen = !this.isOpen;
      }
    });
  },
  methods: {
    _dropdown: function() {
      if (!this.isOpen) {
        this.isOpen = true;
      } else {
        this.isOpen = false;
      }
    }
  }
};
</script>

<style lang="css" scoped>
.dropdown-wrapper {
  margin-bottom: 5rem;
  position: relative;
  width: 100%;
}

.btn-style {
  padding: 2rem 4rem;
  background-color: var(--strawbinary-light-blue);
  width: 100%;
  display: flex;
  align-items: center;
}

.dropdown-head {
  width: 90%;
  padding-right: 2rem;
  text-align: left;
}

.dropdown-icon {
  height: 100%;
  width: 5rem;
}

.dropdown-style {
  background-color: var(--strawbinary-light-blue);
}

ul {
  cursor: pointer;
}

.slide-enter-active {
  transition-duration: 0.3s;
  transition-timing-function: ease-in;
}

.slide-leave-active {
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to,
.slide-leave {
  max-height: 100rem;
  overflow: hidden;
}

.slide-enter,
.slide-leave-to {
  overflow: hidden;
  max-height: 0;
}

.dropdown-style > * {
  padding: 4rem;
}

.close {
  display: none;
}

lord-icon {
  height: 5rem;
  width: 5rem;
  position: absolute;
  right: 3rem;
}

.rotateUP {
  animation: rotationUP 0.3s linear;
  animation-fill-mode: forwards;
}

.rotateDOWN {
  animation: rotationDOWN 0.3s linear;
  animation-fill-mode: forwards;
}

@keyframes rotationUP {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-180deg);
  }
}

@keyframes rotationDOWN {
  from {
    transform: rotate(-180deg);
  }
  to {
    transform: rotate(0deg);
  }
}

@media screen and (max-width: 48em) {
  .dropdown-icon {
    height: 3.5rem;
    width: 3.5rem;
  }
}

@media screen and (max-width: 31.25em) {
  .btn-style {
    padding: 1rem 1rem;
  }

  .dropdown-icon {
    position: relative;
    right: 1rem;
  }

  .dropdown-style > * {
    padding: 2rem;
  }
}
</style>
