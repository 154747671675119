var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('article',{attrs:{"id":_vm.pos}},[_c('div',{staticClass:"ct-container",attrs:{"id":"Error"}},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"error-container"},[_c('div',{staticClass:"error-text"},[_vm._m(2),_vm._m(3),_vm._m(4),_c('div',{staticClass:"btn-wrapper"},[_c('Button',{attrs:{"button":{
              label: 'Zurück zur Homepage',
              url: '/',
              style: 'primary',
              page: { data: null },
              icon: {
                data: {
                  attributes: { url: '/assets/errorsection/arrow_right_error.json', ext: '.json' }
                }
              }
            },"url":''}})],1)]),_vm._m(5)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"logo-wrapper"},[_c('img',{attrs:{"src":"/assets/errorsection/Strawbinary_Logo_Black_White.svg","alt":"Logo"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dots-wrapper"},[_c('img',{attrs:{"src":"/assets/errorsection/dots_vertical.svg"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"t1"},[_c('p',[_vm._v("...Oops")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"t2"},[_c('h3',[_vm._v(" Irgendetwas ist schief gelaufen... ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"t3"},[_c('p',[_vm._v(" Es ist ein Mysterium, wie du hier her gekommen bist. Aber um wieder auf den richtigen Weg zu kommen, klicke einfach auf den Button hier unten. ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"error-wrapper"},[_c('img',{attrs:{"src":"/assets/errorsection/404_Error.svg"}})])}]

export { render, staticRenderFns }