<template lang="html">
  <section :id="pos">
    <div id="CTInBetween" class="ct-container-y" v-if="body.show">
      <div class="triangle">
        <div class="outer-image-wrapper">
          <div id="img-1" class="inner-image-wrapper">
            <img src="/assets/inbetween-graphic.svg" alt="STRW Lines" />
          </div>
          <div id="img-2" class="inner-image-wrapper">
            <img src="/assets/inbetween-graphic.svg" alt="STRW Lines" />
          </div>
          <div id="img-3" class="inner-image-wrapper">
            <img src="/assets/inbetween-graphic.svg" alt="STRW Lines" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "InBetween",
  props: {
    body: {
      type: Object
    },
    pos: {
      type: Number
    }
  },
  created() {
    window.addEventListener(
      "scroll",
      () => {
        document.body.style.setProperty(
          "--scroll",
          window.pageYOffset / (document.body.offsetHeight - window.innerHeight)
        );
      },
      false
    );
  }
};
</script>

<style lang="css" scoped>
.triangle {
  background-repeat: no-repeat;
  height: 80vh;
  background-size: cover;
  position: relative;
  background-color: var(--strawbinary-grey);
}

.triangle::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 0;
  background-color: white;
  clip-path: polygon(100% 0, 0 0, 0 75%);
  top: -0.1rem;
  height: 10vw;
}

.triangle::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 0;
  background-color: white;
  clip-path: polygon(100% 25%, 100% 100%, 0 100%);
  bottom: 0;
  height: 10vw;
  bottom: -0.1rem;
}

.outer-image-wrapper {
  height: 100%;
  z-index: 1;
  position: relative;
}

.inner-image-wrapper {
  position: absolute;
  height: 20%;
  width: 100%;
  z-index: 20;
  rotate: -4.8deg;
  z-index: 2;
  display: flex;
}

.inner-image-wrapper img {
  width: auto;
  height: 85%;
  object-fit: fill;
  object-position: center;
}

#img-1 {
  top: 20%;
}

#img-2 {
  top: 40%;
}

#img-3 {
  top: 60%;
}

#img-1,
#img-3 {
  animation: leftright 1s linear infinite;
  animation-play-state: paused;
  animation-delay: calc(var(--scroll) * -6s);
}

#img-2 {
  animation: rightleft 1s linear infinite;
  animation-play-state: paused;
  animation-delay: calc(var(--scroll) * -6s);
}

@keyframes leftright {
  0% {
    transform: translateX(-100vw);
  }
  100% {
    transform: translateX(0vw);
  }
}

@keyframes rightleft {
  0% {
    transform: translateX(0vw);
  }
  100% {
    transform: translateX(-100vw);
  }
}

@media screen and (max-width: 75em) {
  .triangle {
    height: 60vh;
  }
}

@media screen and (max-width: 62em) {
  .triangle {
    height: 50vh;
  }
}

@media screen and (max-width: 36em) {
  .triangle {
    height: 40vh;
  }

  #img-1 {
    top: 15%;
  }

  #img-2 {
    top: 40%;
  }

  #img-3 {
    top: 65%;
  }
}

@media screen and (max-width: 20em) {
  .triangle {
    height: 30vh;
  }
}
</style>
