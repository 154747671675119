<template>
  <transition name="slide-fade" :class="{ 'no-Fade': windowClose == true }">
    <section id="cookieNotification" v-if="!windowClose">
      <div id="head">
        <div class="icon-wrapper">
          <img src="/assets/Cookie.svg" />
        </div>
        <div class="pb-3">
          <p class="header">Cookie-Präferenzen</p>
          <p class="text">
            Für eine bessere Benutzererfahrung und zu statistischen Zwecken erheben wir Cookies.
          </p>
        </div>
      </div>
      <div id="extended" class="pb-3" v-if="moreOptions">
        <div>
          <div class="mt-0 head-wrapper">
            <p class="header">Essenziel</p>
            <div class="d-flex align-items-end">
              <div class="form-check form-switch">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="flexSwitchCheckChecked"
                  v-model="cookieValues[0].isset"
                  disabled
                />
              </div>
              <a class="arrow-wrapper" @click="showEssencial = !showEssencial">
                <span class="arrow-text">mehr</span>
                <span class="arrow"></span>
              </a>
            </div>
          </div>
          <transition name="fade">
            <div class="text-wrapper" v-if="showEssencial">
              <p class="text">Cookies die zur Benutzung der Website notwendig sind.</p>
              <a class="text-more-info" href="/page/datenschutzerklaerung#cookie">Mehr erfahren</a>
            </div>
          </transition>
        </div>
        <div>
          <div class="head-wrapper">
            <p class="header">Analyse</p>
            <div class="d-flex align-items-end">
              <div class="form-check form-switch">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="flexSwitchCheckChecked1"
                  v-model="cookieValues[1].isset"
                />
              </div>
              <a class="arrow-wrapper" @click="showAnalyse = !showAnalyse">
                <span class="arrow-text">mehr</span>
                <span class="arrow"></span>
              </a>
            </div>
          </div>
          <transition name="fade">
            <div class="text-wrapper" v-if="showAnalyse">
              <p class="text">
                Um unsere Webseite immer besser zu machen, sammeln wir ein paar Informationen.
              </p>
              <a class="text-more-info" href="/page/datenschutzerklaerung#google-analytics"
                >Mehr erfahren</a
              >
            </div>
          </transition>
        </div>
        <!-- <div>
          <div class="head-wrapper">
            <p class="header">Advertising</p>
            <div class="d-flex align-items-end">
              <div class="form-check form-switch">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="flexSwitchCheckChecked2"
                  v-model="cookieValues[2].isset"
                />
              </div>
              <a class="arrow-wrapper" @click="showAdvertising = !showAdvertising">
                <span class="arrow-text">mehr</span>
                <span class="arrow"></span>
              </a>
            </div>
          </div>
          <transition name="fade">
            <div class="text-wrapper" v-if="showAdvertising">
              <p class="text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Massa.</p>
              <a class="text-more-info" href="#">Mehr erfahren</a>
            </div>
          </transition>
        </div> -->
      </div>
      <!-- </div> -->
      <div id="buttons">
        <button class="clear-button btn-primary-sb" @click="saveAll" v-if="!moreOptions">
          <label>Alle Cookies akzeptieren</label>
        </button>
        <button class="clear-button btn-primary-sb" @click="saveSelected" v-else>
          <label>Ausgewählte Cookies zulassen</label>
        </button>
        <button class="mt-4 clear-button btn-secondary-sb" @click="saveEssential">
          <label>Nur Essenzielle Cookies zulassen</label>
        </button>
        <button @click="moreOption" class="mt-2 clear-button btn-clear-white-sb">
          <label v-if="moreOptions">Zurück</label>
          <label v-else>Einstellungen</label>
        </button>
      </div>
    </section>
  </transition>
</template>

<script>
import { bootstrap } from "vue-gtag";
export default {
  name: "CookieNotification",
  data() {
    return {
      moreOptions: false,
      windowClose: false,
      showEssencial: false,
      showAnalyse: false,
      showAdvertising: false,
      cookieValues: [
        { id: 0, name: "Essenziel", isset: true },
        { id: 1, name: "Analyse", isset: false },
        { id: 2, name: "Advertising", isset: false }
      ]
    };
  },
  created() {
    if (JSON.parse(this.$cookies.get("cookieValues"))) {
      this.windowClose = true;
    }
  },
  methods: {
    saveAll: async function() {
      this.cookieValues[1].isset = true;
      this.cookieValues[2].isset = true;
      this.$cookies.set("cookieValues", JSON.stringify(this.cookieValues));

      await bootstrap();

      this.windowClose = true;
    },
    saveEssential: function() {
      this.cookieValues[1].isset = false;
      this.cookieValues[2].isset = false;
      this.$cookies.set("cookieValues", JSON.stringify(this.cookieValues));

      this.windowClose = true;
    },
    saveSelected: async function() {
      this.$cookies.set("cookieValues", JSON.stringify(this.cookieValues));

      if (this.cookieValues[1].isset) {
        await bootstrap();
      }

      this.windowClose = true;
    },
    moreOption: function() {
      this.moreOptions = !this.moreOptions;
      if (!this.moreOptions) {
        this.showEssencial = false;
        this.showAnalyse = false;
        this.showAdvertising = false;
      }
    }
  }
};
</script>

<style lang="css" scoped>
#cookieNotification {
  position: fixed;
  z-index: 1999;
  width: 46rem;
  height: max-content;
  background-color: var(--strawbinary-grey);
  padding: 2rem;
  padding-bottom: 1rem;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
}

#cookieNotification * {
  color: white;
}

#head,
#buttons {
  flex-shrink: 0;
}

#extended {
  overflow-y: auto;
  max-height: 35vh;
}

#cookieNotification img {
  width: 30%;
  height: 10rem;
  margin-bottom: 2rem;
}

#cookieNotification .header {
  font-family: Rubik;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0em;
  text-align: left;
}

#cookieNotification .text,
#cookieNotification .arrow-text {
  font-family: Rubik;
  font-style: normal;
  font-weight: 400;
  line-height: 2.1rem;
  letter-spacing: 0em;
  text-align: left;
  color: #bac8d3;
}

#cookieNotification #extended .text-more-info {
  font-family: Rubik;
  font-style: normal;
  font-weight: 400;
  line-height: 1.7rem;
  letter-spacing: 0em;
  text-align: left;
  color: white;
}
#cookieNotification #extended .text-more-info:hover {
  color: var(--strawbinary-medium-blue);
}

#cookieNotification #extended .text {
  width: 95%;
  margin-bottom: 0.6rem;
}

#cookieNotification #extended .head-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  border-bottom: 2px solid var(--strawbinary-medium-blue);
  line-height: 0;
  padding-bottom: 0.5rem;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

#cookieNotification #extended .text-wrapper {
  display: flex;
  align-items: flex-end;
  flex-flow: wrap;
  justify-content: flex-start;
  padding-bottom: 1rem;
}

#cookieNotification .arrow-text {
  font-family: Rubik;
  font-style: normal;
  font-weight: 400;
  text-align: left;
  color: #bac8d3;
}

#cookieNotification .arrow-wrapper {
  display: flex;
  flex-flow: row;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  margin-left: 1rem;
  margin-right: 0.1rem;
}

#cookieNotification .arrow-text {
  margin-right: 1rem;
}

#cookieNotification .arrow {
  display: inline-block;
  height: 1rem;
  width: 1rem;
  border-top: 2px solid #bac8d3;
  border-left: 2px solid #bac8d3;
  transform: translate(-1.5px, -1.5px) rotate(-135deg);
}

.icon-wrapper {
  flex-direction: row;
  display: flex;
  justify-content: flex-start;
}

/* Switches */
.form-check-input:checked {
  background-color: var(--strawbinary-medium-blue);
  border-color: var(--strawbinary-medium-blue);
}

.form-check-input:disabled {
  background-color: #82b6e3;
  border-color: #82b6e3;
  opacity: 0.65;
}

/* Animation */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.slide-fade-enter-active {
  transition: all 0.3s;
}

.slide-fade-leave-active {
  transition: all 0.8s;
}

.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY(100px);
  opacity: 0;
}

.no-Fade {
  display: none !important;
}

/* Buttons */
#cookieNotification #buttons * {
  width: 100%;
}

#buttons {
  padding-top: 2.5rem;
}

@media screen and (max-width: 90em) {
  #cookieNotification {
    justify-content: space-evenly;
  }
}

@media screen and (max-height: 50em) {
  #extended {
    max-height: 25vh;
  }
}

@media screen and (max-height: 40em) {
  #extended {
    max-height: 35vh;
  }
}

@media screen and (max-width: 33.125em) {
  #cookieNotification {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    justify-content: space-between;
  }

  #buttons {
    padding-top: 1.5rem;
  }

  #cookieNotification img {
    width: unset;
  }

  #extended {
    overflow-y: auto;
    max-height: 30vh;
  }
}

@media screen and (max-width: 25em) {
  #cookieNotification {
    padding: 1rem;
    padding-bottom: 0;
  }
}
</style>
