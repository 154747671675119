<template lang="html">
  <section :id="pos">
    <div id="landingsection1">
      <div class="background-left-wave">
        <div class="background-left-dots">
          <div class="background-right">
            <div class="ls-inner">
              <div class="row">
                <div class="col ls-content-col">
                  <div class="top-left-graphic">
                    <img
                      :src="url + body.top_graphic.data.attributes.url"
                      :alt="body.top_graphic.data.attributes.alternativeText"
                    />
                  </div>
                  <div class="landingsection-content">
                    <div class="main-headline-outer">
                      <h1 class="main-headline">
                        <p class="fs-landing-small">Wir erstellen</p>
                        <span id="typewriterHeadline" class="fs-landing-big"></span>
                      </h1>
                    </div>
                    <div class="ls-mobile">
                      <div class="websites-container">
                        <transition name="slide-fade-graphic" mode="out-in">
                          <img
                            v-if="image == 'Webseiten'"
                            id="Desktop"
                            style="transition-delay: .3s"
                            src="/assets/landingsection/Website/Desktop.svg"
                            alt="Desktop"
                          />
                        </transition>
                        <transition name="slide-fade-graphic" mode="out-in">
                          <img
                            v-if="image == 'Webseiten'"
                            style="transition-delay: .6s"
                            id="Tablet"
                            src="/assets/landingsection/Website/Tablet.svg"
                            alt="Tablet"
                          />
                        </transition>
                        <transition name="slide-fade-graphic" mode="out-in">
                          <img
                            v-if="image == 'Webseiten'"
                            style="transition-delay: .9s"
                            id="FrauHandy"
                            src="/assets/landingsection/Website/Handy_mit_Frau.svg"
                            alt="Frau mit Handy"
                          />
                        </transition>
                      </div>
                      <div class="software-container">
                        <transition name="slide-fade-graphic" mode="out-in">
                          <img
                            v-if="image == 'Software'"
                            id="Software"
                            style="transition-delay: .3s"
                            src="/assets/landingsection/Software/Software.svg"
                            alt="Software"
                          />
                        </transition>
                        <transition name="slide-fade-graphic" mode="out-in">
                          <img
                            v-if="image == 'Software'"
                            id="ZweiMaenner"
                            style="transition-delay: .6s"
                            src="/assets/landingsection/Software/Zwei_Maenner.svg"
                            alt="Zwei Männer"
                          />
                        </transition>
                      </div>
                      <div class="graphicdesign-container">
                        <transition name="slide-fade-graphic" mode="out-in">
                          <img
                            v-if="image == 'Designs'"
                            id="Artwork"
                            style="transition-delay: .3s"
                            src="/assets/landingsection/Grafikdesign/Artwork.svg"
                            alt="Artwrok"
                          />
                        </transition>
                        <transition name="slide-fade-graphic" mode="out-in">
                          <img
                            v-if="image == 'Designs'"
                            id="Formen"
                            style="transition-delay: .6s"
                            src="/assets/landingsection/Grafikdesign/Formen.svg"
                            alt="Formen"
                          />
                        </transition>
                        <transition name="slide-fade-graphic" mode="out-in">
                          <img
                            v-if="image == 'Designs'"
                            id="People"
                            style="transition-delay: .9s"
                            src="/assets/landingsection/Grafikdesign/People.svg"
                            alt="People"
                          />
                        </transition>
                      </div>
                    </div>
                    <div class="info-box">
                      <div class="btn-wrapper">
                        <Button v-for="link in this.content.link" :button="link" :key="link.id" />
                      </div>
                      <div class="next-prev-btn-wrapper">
                        <button
                          @click="lsPrev"
                          id="lsPrev"
                          class="btnhoverLEFT"
                          type="button"
                          name="button"
                        ></button>
                        <button
                          @click="lsNext"
                          id="lsNext"
                          class="btnhoverRIGHT"
                          type="button"
                          name="button"
                        ></button>
                      </div>
                      <transition name="slide-fade" mode="out-in">
                        <div :key="this.content.id">
                          <vue-markdown
                            class="markdown-medium info-box-text"
                            :source="this.content.inhalt"
                          ></vue-markdown>
                        </div>
                      </transition>
                    </div>
                  </div>
                </div>
                <div class="col ls-desktop">
                  <div class="websites-container">
                    <transition name="slide-fade-graphic" mode="out-in">
                      <img
                        v-if="image == 'Webseiten'"
                        id="Desktop"
                        style="transition-delay: .3s"
                        src="/assets/landingsection/Website/Desktop.svg"
                        alt="Desktop"
                      />
                    </transition>
                    <transition name="slide-fade-graphic" mode="out-in">
                      <img
                        v-if="image == 'Webseiten'"
                        style="transition-delay: .6s"
                        id="Tablet"
                        src="/assets/landingsection/Website/Tablet.svg"
                        alt="Tablet"
                      />
                    </transition>
                    <transition name="slide-fade-graphic" mode="out-in">
                      <img
                        v-if="image == 'Webseiten'"
                        style="transition-delay: .9s"
                        id="FrauHandy"
                        src="/assets/landingsection/Website/Handy_mit_Frau.svg"
                        alt="Frau mit Handy"
                      />
                    </transition>
                  </div>
                  <div class="software-container">
                    <transition name="slide-fade-graphic" mode="out-in">
                      <img
                        v-if="image == 'Software'"
                        id="Software"
                        style="transition-delay: .3s"
                        src="/assets/landingsection/Software/Software.svg"
                        alt="Software"
                      />
                    </transition>
                    <transition name="slide-fade-graphic" mode="out-in">
                      <img
                        v-if="image == 'Software'"
                        id="ZweiMaenner"
                        style="transition-delay: .6s"
                        src="/assets/landingsection/Software/Zwei_Maenner.svg"
                        alt="Zwei Männer"
                      />
                    </transition>
                  </div>
                  <div class="graphicdesign-container">
                    <transition name="slide-fade-graphic" mode="out-in">
                      <img
                        v-if="image == 'Designs'"
                        id="Artwork"
                        style="transition-delay: .3s"
                        src="/assets/landingsection/Grafikdesign/Artwork.svg"
                        alt="Artwrok"
                      />
                    </transition>
                    <transition name="slide-fade-graphic" mode="out-in">
                      <img
                        v-if="image == 'Designs'"
                        id="Formen"
                        style="transition-delay: .6s"
                        src="/assets/landingsection/Grafikdesign/Formen.svg"
                        alt="Formen"
                      />
                    </transition>
                    <transition name="slide-fade-graphic" mode="out-in">
                      <img
                        v-if="image == 'Designs'"
                        id="People"
                        style="transition-delay: .9s"
                        src="/assets/landingsection/Grafikdesign/People.svg"
                        alt="People"
                      />
                    </transition>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="landing-mobile">
      <div class="bg-left-wave">
        <div class="background-right">
          <div class="websites-container">
            <transition name="slide-fade-graphic" mode="out-in">
              <img
                v-if="image == 'Webseiten'"
                id="Desktop"
                style="transition-delay: .3s"
                src="/assets/landingsection/Website/Desktop.svg"
                alt="Desktop"
              />
            </transition>
            <transition name="slide-fade-graphic" mode="out-in">
              <img
                v-if="image == 'Webseiten'"
                style="transition-delay: .6s"
                id="Tablet"
                src="/assets/landingsection/Website/Tablet.svg"
                alt="Tablet"
              />
            </transition>
            <transition name="slide-fade-graphic" mode="out-in">
              <img
                v-if="image == 'Webseiten'"
                style="transition-delay: .9s"
                id="FrauHandy"
                src="/assets/landingsection/Website/Handy_mit_Frau.svg"
                alt="Frau mit Handy"
              />
            </transition>
          </div>
          <div class="software-container">
            <transition name="slide-fade-graphic" mode="out-in">
              <img
                v-if="image == 'Software'"
                id="Software"
                style="transition-delay: .3s"
                src="/assets/landingsection/Software/Software.svg"
                alt="Software"
              />
            </transition>
            <transition name="slide-fade-graphic" mode="out-in">
              <img
                v-if="image == 'Software'"
                id="ZweiMaenner"
                style="transition-delay: .6s"
                src="/assets/landingsection/Software/Zwei_Maenner.svg"
                alt="Zwei Männer"
              />
            </transition>
          </div>
          <div class="graphicdesign-container">
            <transition name="slide-fade-graphic" mode="out-in">
              <img
                v-if="image == 'Designs'"
                id="Artwork"
                style="transition-delay: .3s"
                src="/assets/landingsection/Grafikdesign/Artwork.svg"
                alt="Artwrok"
              />
            </transition>
            <transition name="slide-fade-graphic" mode="out-in">
              <img
                v-if="image == 'Designs'"
                id="Formen"
                style="transition-delay: .6s"
                src="/assets/landingsection/Grafikdesign/Formen.svg"
                alt="Formen"
              />
            </transition>
            <transition name="slide-fade-graphic" mode="out-in">
              <img
                v-if="image == 'Designs'"
                id="People"
                style="transition-delay: .9s"
                src="/assets/landingsection/Grafikdesign/People.svg"
                alt="People"
              />
            </transition>
          </div>
          <div class="mobile-outer">
            <div class="info-box">
              <div class="next-prev-btn-wrapper">
                <button
                  @click="lsPrev"
                  id="lsPrev"
                  class="btnhoverLEFT"
                  type="button"
                  name="button"
                ></button>
                <button
                  @click="lsNext"
                  id="lsNext"
                  class="btnhoverRIGHT"
                  type="button"
                  name="button"
                ></button>
              </div>
              <div class="main-headline-outer">
                <h1 class="main-headline">
                  <p class="fs-landing-small">Wir erstellen</p>
                  <span id="typewriterHeadlineMobile" class="fs-landing-big"></span>
                </h1>
              </div>
              <transition name="slide-fade" mode="out-in">
                <div :key="this.content.id">
                  <vue-markdown
                    class="markdown-medium info-box-text lh-info-box"
                    :source="this.content.inhalt"
                  ></vue-markdown>
                </div>
              </transition>
            </div>
            <div class="btn-wrapper">
              <Button v-for="link in this.content.link" :button="link" :key="link.id" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Button from "./element/Button.vue";
import VueMarkdown from "vue-markdown";
import { getStrapiUrl } from "../helper";

export default {
  name: "Landingsection",
  data() {
    return {
      url: getStrapiUrl(),
      typeCounterHeadline: 0,
      typeCounterInfo: 0,
      infoText: "",
      contentIndex: 0,
      weCreateLength: 0,
      image: "Webseiten",
      timeout: null
    };
  },
  props: {
    body: {
      type: Object
    },
    pos: {
      type: Number
    }
  },
  mounted() {
    this.typeWriterHeadline();
  },
  methods: {
    typeWriterHeadline() {
      if (this.weCreateLength != this.content.we_create.length) {
        clearTimeout(this.timeout);
        document.getElementById("typewriterHeadline").innerHTML = "";
        document.getElementById("typewriterHeadlineMobile").innerHTML = "";
        this.weCreateLength = this.content.we_create.length;
        this.typeCounterHeadline = 0;
      }
      if (this.typeCounterHeadline < this.content.we_create.length) {
        document.getElementById("typewriterHeadline").innerHTML += this.content.we_create.charAt(
          this.typeCounterHeadline
        );
        document.getElementById(
          "typewriterHeadlineMobile"
        ).innerHTML += this.content.we_create.charAt(this.typeCounterHeadline);
        this.typeCounterHeadline++;
      }
      this.timeout = setTimeout(this.typeWriterHeadline, 70);
    },
    lsPrev() {
      if (this.contentIndex > 0) {
        this.contentIndex--;
      } else {
        this.contentIndex = this.body.landingsection_content.length - 1;
        this.weCreateLength = 0;
      }
      this.image = this.content.we_create;
    },
    lsNext() {
      if (this.contentIndex < this.body.landingsection_content.length - 1) {
        this.contentIndex++;
      } else {
        this.contentIndex = 0;
        this.weCreateLength = 0;
      }
      this.typeWriterHeadline();
      this.image = this.content.we_create;
    }
  },
  computed: {
    content() {
      if (this.contentIndex <= this.body.landingsection_content.length) {
        return this.body.landingsection_content[this.contentIndex];
      }
      return this.body.landingsection_content[0];
    }
  },
  components: {
    VueMarkdown,
    Button
  }
};
</script>

<style lang="css" scoped>
.slide-fade-enter-active,
.slide-fade-graphic-enter-active {
  transition: all 0.3s ease;
  opacity: 1;
}

.slide-fade-leave-active,
.slide-fade-graphic-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
  transition-delay: 0.3s !important;
  opacity: 1;
}

.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY(1rem);
  opacity: 0;
}

.slide-fade-graphic-enter,
.slide-fade-graphic-leave-to {
  transform: translateY(5rem);
  opacity: 0;
}

.row,
.col {
  margin: 0;
  padding: 0;
}

.ls-mobile,
#landing-mobile {
  display: none;
}

#landingsection1 {
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
}

.ls-content-col {
  position: relative;
}

.landingsection-content {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.top-left-graphic {
  background-color: var(--strawbinary-dark-grey);
  display: inline-block;
}

.top-left-graphic img {
  height: 9rem;
  padding: 2rem 3rem;
}

.ls-inner {
  height: 100%;
}

.ls-inner .row {
  height: 100%;
}

.info-box {
  color: white;
  background-color: var(--strawbinary-dark-grey);
  display: inline-block;
  position: relative;
  width: 100%;
  min-height: 32.5rem;
  max-height: 32.5rem;
  padding: 12rem 8vw 10rem 8vw;
}

.btn-wrapper {
  position: absolute;
  top: -1.5rem;
  z-index: 998;
}

.btn-wrapper button:not(:first-of-type) {
  margin-left: 3rem;
}

.next-prev-btn-wrapper {
  display: inline-block;
  position: absolute;
  right: 2.5rem;
  top: 2.5rem;
  z-index: 998;
}

.next-prev-btn-wrapper button {
  width: 6rem;
  height: 6rem;
  border: none;
}

.next-prev-btn-wrapper button:first-of-type {
  margin-right: 3rem;
}

.next-prev-btn-wrapper button:focus {
  outline: 0;
}

#lsPrev {
  background: url(/assets/arrow-circle-left.svg) no-repeat;
  background-size: contain;
  background-position: center;
}

#lsNext {
  background: url(/assets/arrow-circle-right.svg) no-repeat;
  background-size: contain;
  background-position: center;
}

#lsNext:active {
  transform: scale(1.03);
}

#lsPrev:active {
  transform: scale(1.03);
}

.main-headline #typewriterHeadline {
  font-family: "Rubik", sans-serif;
  font-weight: 500;
}

.main-headline p {
  font-weight: 300;
  font-family: "Rubik", sans-serif;
  margin-bottom: 2rem;
}

.main-headline {
  border-bottom: 0.8rem solid var(--strawbinary-dark-grey);
  margin: 0 0 10vw 8vw;
  padding-bottom: 3rem;
}

.background-left-wave {
  height: 100%;
  background-image: url("/assets/wellen.svg");
  background-repeat: no-repeat;
  background-size: contain;
}

.background-left-dots {
  height: 100%;
  background-image: url("/assets/dots.svg");
  background-repeat: no-repeat;
  background-position: left;
  background-size: auto;
}

.background-right {
  height: 100%;
  background-image: url("/assets/Strawbinary_Landing_Background.svg");
  background-repeat: no-repeat;
  background-position: 100% -5rem;
  background-size: auto;
}

.websites-container {
  position: relative;
  height: 100%;
}

#Desktop,
#Tablet,
#FrauHandy,
#Software,
#ZweiMaenner,
#Artwork,
#Formen,
#People {
  position: absolute;
}

#Desktop {
  height: 55rem;
  right: 2rem;
  top: 22%;
}

#Tablet {
  height: 50rem;
  z-index: 22;
  right: 13rem;
  top: 50%;
}

#FrauHandy {
  height: 40rem;
  top: 48%;
  right: 55rem;
}

#Software {
  height: 65rem;
  top: 12%;
  right: 14rem;
}

#ZweiMaenner {
  height: 45rem;
  right: 40rem;
  top: 50%;
}

#Artwork {
  height: 70rem;
  top: 20%;
  right: 2rem;
}

#Formen {
  height: 55rem;
  top: 25%;
  right: 12rem;
}

#People {
  height: 40rem;
  top: 60%;
  right: 20rem;
}

@media screen and (max-width: 999em) {
  .main-headline {
    margin: 0 0 4vw 8vw;
  }
}

@media screen and (max-width: 175em) {
  .main-headline {
    margin: 0 0 5vw 8vw;
  }
}

@media screen and (max-width: 125em) {
  .background-right {
    background-position: 100% -3rem;
  }

  .main-headline p {
    margin-bottom: 1rem;
  }

  .main-headline {
    margin: 0 0 5vw 5vw;
    padding-bottom: 2rem;
  }

  .landingsection-content {
    width: 90%;
  }

  .info-box {
    padding: 10rem 5vw 7rem 5vw;
  }

  #lsPrev,
  #lsNext {
    width: 5.5rem;
  }
}

@media screen and (max-width: 112.5em) {
  #Software {
    height: 50rem;
    top: 20%;
    right: 10rem;
  }

  #ZweiMaenner {
    height: 30rem;
    right: 30rem;
    top: 54%;
  }
}

@media screen and (max-width: 105.9375em) {
  #Desktop {
    height: 45rem;
    top: 18%;
    right: 0rem;
  }

  #Tablet {
    height: 36rem;
    top: 44%;
    right: 10rem;
  }

  #FrauHandy {
    height: 30rem;
    right: 44rem;
    top: 40%;
  }
}

@media screen and (max-width: 100em) {
  .background-right {
    background-position: 102% -5rem;
  }
}

@media screen and (max-width: 93.75em) {
  #Artwork {
    height: 55rem;
    top: 20%;
    right: 2rem;
  }

  #Formen {
    height: 40rem;
    top: 25%;
    right: 12rem;
  }

  #People {
    height: 35rem;
    top: 54%;
    right: 16rem;
  }
  .info-box {
    padding: 9rem 3vw 5rem 3vw;
  }

  #lsPrev,
  #lsNext {
    width: 4.5rem;
  }

  .next-prev-btn-wrapper button:first-of-type {
    margin-right: 2rem;
  }

  .next-prev-btn-wrapper {
    right: 2rem;
    top: 2rem;
  }

  .background-right {
    background-size: 55%;
    background-position: 102% 0;
  }
}

@media screen and (max-width: 81.25em) and (max-height: 46.875em) {
  .info-box {
    padding: 7rem 3vw 3rem 3vw;
  }

  .next-prev-btn-wrapper {
    right: 1rem;
    top: 1rem;
  }

  #lsPrev,
  #lsNext {
    width: 4rem;
  }

  .main-headline p {
    margin-bottom: 0.5rem;
  }

  .top-left-graphic img {
    height: 8rem;
  }

  .background-right {
    background-position: 101% -6rem;
  }
}

@media screen and (max-width: 84.375em) {
  #Desktop {
    height: 40rem;
    top: 18%;
    right: -1rem;
  }

  #Tablet {
    height: 32rem;
    top: 42%;
    right: 7rem;
  }

  #FrauHandy {
    height: 26rem;
    right: 40rem;
  }
}

@media screen and (max-width: 75em) {
  #Artwork {
    height: 40rem;
    right: 0rem;
  }

  #Formen {
    height: 30rem;
    top: 25%;
    right: 8rem;
  }

  #People {
    height: 25rem;
    top: 44%;
    right: 10rem;
  }

  .info-box {
    min-height: unset;
    max-height: unset;
    width: 180%;
  }
}

@media screen and (max-width: 75em) and (max-height: 76.25em) {
  #Software {
    top: 8%;
  }

  #ZweiMaenner {
    top: 38%;
  }

  #FrauHandy {
    right: 32rem;
  }
}

@media screen and (max-width: 75em) and (max-height: 60.625em) {
  #Software {
    height: 40rem;
    top: 8%;
  }

  #ZweiMaenner {
    height: 25rem;
    top: 38%;
    right: 20rem;
  }

  #Desktop {
    top: 8%;
  }

  #Tablet {
    top: 34%;
  }

  #FrauHandy {
    top: 32%;
    right: 35rem;
  }

  #Artwork {
    top: 10%;
  }

  #Formen {
    top: 15%;
  }

  #People {
    top: 38%;
  }
}

@media screen and (max-width: 75em) and (max-height: 53.125em) {
  #Software {
    height: 30rem;
    top: 8%;
  }

  #ZweiMaenner {
    height: 20rem;
    top: 34%;
  }

  #Desktop {
    height: 35rem;
  }

  #Tablet {
    height: 25rem;
    top: 36%;
    right: 6rem;
  }

  #FrauHandy {
    height: 20rem;
    top: 35%;
    right: 32rem;
  }

  #Artwork {
    height: 30rem;
    right: 8rem;
  }

  #Formen {
    height: 20rem;
    top: 15%;
    right: 15rem;
  }

  #People {
    height: 20rem;
    top: 33%;
    right: 15rem;
  }
}

@media screen and (max-width: 68.75em) {
  .top-left-graphic {
    display: none;
  }
}

@media screen and (max-width: 62em) {
  .ls-desktop {
    display: none;
  }

  .ls-mobile {
    display: flex;
    flex: 1 0 0%;
  }

  .info-box {
    width: 100vw;
  }

  .landingsection-content {
    bottom: auto;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 7rem;
  }

  .main-headline {
    margin: 35vw 0 0 5vw;
    padding-bottom: 1rem;
    width: 55%;
  }

  .main-headline p {
    margin-bottom: 0.5rem;
  }

  .background-left-wave {
    background-image: url("/assets/wellen_thin.svg");
  }

  .background-right {
    background-image: url("/assets/Strawbinary_Landing_Background_2.svg");
    background-size: 45%;
    background-position: 100% 28%;
  }

  .websites-container {
    position: inherit;
  }
}

@media screen and (max-width: 62em) and (max-height: 90.625em) {
  .main-headline-outer {
    width: 95%;
    height: 100%;
    position: relative;
  }

  .main-headline {
    position: absolute;
    margin: 0 0 0 5vw;
    top: 70%;
    padding-bottom: 1rem;
    padding-right: 2rem;
    width: 55%;
  }

  .background-right {
    background-size: 65%;
    background-position: 100% 8%;
  }

  #Software {
    top: 10%;
    right: 2rem;
  }

  #ZweiMaenner {
    height: 30rem;
    top: 30%;
    right: 10rem;
  }

  #Desktop {
    height: 40rem;
  }

  #Tablet {
    height: 30rem;
    top: 36%;
    right: 6rem;
  }

  #FrauHandy {
    height: 25rem;
    top: 35%;
    right: 32rem;
  }

  #Artwork {
    height: 40rem;
    right: 0;
  }

  #Formen {
    height: 30rem;
    top: 20%;
    right: 6rem;
  }

  #People {
    height: 30rem;
    top: 36%;
    right: 2rem;
  }
}

@media screen and (max-width: 62em) and (max-height: 56.25em) {
  .main-headline {
    top: 50%;
    padding-bottom: 1rem;
    width: 55%;
  }

  #Software {
    height: 30rem;
    top: 15%;
    right: 2rem;
  }

  #ZweiMaenner {
    height: 20rem;
    top: 35%;
    right: 10rem;
  }

  #Desktop {
    height: 30rem;
    top: 20%;
    right: 2rem;
  }

  #Tablet {
    height: 20rem;
    top: 36%;
    right: 6rem;
  }

  #FrauHandy {
    height: 15rem;
    top: 40%;
    right: 32rem;
  }

  #Artwork {
    height: 30rem;
    right: 2rem;
    top: 20%;
  }

  #Formen {
    height: 20rem;
    top: 24%;
    right: 8rem;
  }

  #People {
    height: 20rem;
    top: 40%;
    right: 8rem;
  }
}

@media screen and (max-width: 53.125em) {
  .background-right {
    background-size: 40rem;
    background-position: 101% 6%;
  }

  .main-headline p {
    margin-bottom: 0;
  }

  .main-headline {
    padding-bottom: 0.5rem;
    width: 50%;
    border-bottom: 0.5rem solid var(--strawbinary-dark-grey);
  }
}

@media screen and (max-width: 53.125em) and (max-height: 56.25em) {
  .background-right {
    background-size: 40rem;
    background-position: 101% 15%;
  }
}

/* Mobile-Mobile Trigger */
@media screen and (max-width: 48em) {
  .main-headline {
    width: 100%;
  }

  #typewriterHeadline {
    display: block;
    z-index: 999;
    width: 100%;
    height: 10rem;
  }

  #landingsection1 {
    display: none;
  }

  #landing-mobile {
    display: block;
    width: 100vw;
    height: 100vh;
  }

  .mobile-outer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100vh;
    padding-top: 50vh;
  }

  .bg-left-wave {
    background-image: url("/assets/landingsection/left-wave-mobile.svg");
    background-repeat: no-repeat;
    background-size: contain;
  }

  .next-prev-btn-wrapper button {
    width: 6rem;
    height: 6rem;
    border: none;
    margin-bottom: 2rem;
  }

  #lsPrev {
    background: url(/assets/arrow-circle-dark-left.svg) no-repeat;
    background-size: contain;
    background-position: center;
    width: 6rem;
  }

  #lsNext {
    background: url(/assets/arrow-circle-dark-right.svg) no-repeat;
    background-size: contain;
    background-position: center;
    width: 6rem;
  }

  #lsNext:active {
    transform: scale(1.03);
  }

  #lsPrev:active {
    transform: scale(1.03);
  }

  .next-prev-btn-wrapper {
    position: unset;
    padding: 0;
  }

  .main-headline-outer {
    width: unset;
    height: unset;
    position: unset;
  }

  .main-headline {
    width: 80%;
    color: var(--strawbinary-dark-grey);
    position: unset;
    margin: 0;
  }

  .info-box {
    background-color: transparent;
    position: unset;
    padding: 0;
    padding-left: 4rem;
    width: 80%;
  }

  .info-box-text {
    position: unset;
    margin-top: 2.5rem;
    padding: 0;
    background-color: transparent;
    color: var(--strawbinary-dark-grey);
  }

  .btn-wrapper {
    position: unset;
    padding: 0;
    padding-left: 4rem;
    margin-top: 2rem;
  }

  .btn-wrapper button {
    display: block;
    margin: 0;
    padding: 0;
  }

  .btn-wrapper button:not(:first-of-type) {
    margin-left: 0;
    margin-top: 2rem;
  }
}

@media screen and (max-width: 48em) and (max-height: 59.375em) {
  #landing-mobile {
    margin-bottom: 12rem;
  }
}

@media screen and (max-width: 42em) {
  .main-headline {
    width: 90%;
    padding-bottom: 0.5rem;
  }

  .info-box {
    width: 100%;
  }

  .btn-wrapper {
    margin-top: 1rem;
  }
}

@media screen and (max-width: 37.75em) {
  .info-box {
    width: 100%;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .btn-wrapper {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media screen and (max-width: 37.75em) and (max-height: 73.625em) {
  .background-right {
    background-size: 30rem;
    background-position: 101% 11%;
  }

  #Software {
    height: 28rem;
    top: 10%;
    right: 2rem;
  }

  #ZweiMaenner {
    height: 18rem;
    top: 25%;
    right: 10rem;
  }

  #Desktop {
    height: 30rem;
    top: 12%;
    right: 1rem;
  }

  #Tablet {
    height: 20rem;
    top: 26%;
  }

  #FrauHandy {
    height: 15rem;
    top: 30%;
  }

  #Artwork {
    height: 30rem;
    right: 2rem;
    top: 10%;
  }

  #Formen {
    height: 20rem;
    top: 14%;
    right: 8rem;
  }

  #People {
    height: 20rem;
    top: 26%;
    right: 8rem;
  }
}

@media screen and (max-width: 37.75em) and (max-height: 46.875em) {
  .background-right {
    background-size: 30rem;
  }

  .next-prev-btn-wrapper button {
    width: 4rem;
    height: 4rem;
  }

  #lsPrev {
    width: 4rem;
  }

  #lsNext {
    width: 4rem;
  }

  #Software {
    height: 18rem;
    top: 10%;
    right: 2rem;
  }

  #ZweiMaenner {
    height: 10rem;
    top: 25%;
    right: 10rem;
  }

  #Desktop {
    height: 20rem;
    top: 12%;
    right: 1rem;
  }

  #Tablet {
    height: 13rem;
    right: 4rem;
  }

  #FrauHandy {
    height: 10rem;
    top: 28%;
    right: 20rem;
  }

  #Artwork {
    height: 20rem;
    right: 1rem;
  }

  #Formen {
    height: 13rem;
    right: 5rem;
  }

  #People {
    height: 10rem;
    top: 24%;
    right: 6rem;
  }
}

@media screen and (max-width: 28.125em) {
  .main-headline {
    width: 100%;
  }

  .background-right {
    background-size: 30rem;
    background-position: 100% 11%;
  }

  .next-prev-btn-wrapper button {
    width: 4rem;
    height: 4rem;
  }

  #lsPrev {
    width: 4rem;
  }

  #lsNext {
    width: 4rem;
  }

  #Software {
    height: 23rem;
    top: 14%;
    right: 2rem;
  }

  #ZweiMaenner {
    height: 15rem;
    top: 28%;
    right: 10rem;
  }

  #Desktop {
    height: 23rem;
    top: 14%;
    right: 1rem;
  }

  #Tablet {
    height: 15rem;
    top: 28%;
    right: 4rem;
  }

  #FrauHandy {
    height: 12rem;
    top: 30%;
    right: 20rem;
  }

  #Artwork {
    height: 23rem;
    top: 14%;
    right: 1rem;
  }

  #Formen {
    height: 16rem;
    top: 18%;
    right: 5rem;
  }

  #People {
    height: 13rem;
    top: 28%;
    right: 6rem;
  }
}

@media screen and (max-width: 28.125em) and (max-height: 46.875em) {
  #landing-mobile {
    height: 100vh;
  }

  .mobile-outer {
    height: 100vh;
  }

  .background-right {
    background-size: 30rem;
    background-position: 100% 11%;
  }

  .next-prev-btn-wrapper button {
    width: 4rem;
    height: 4rem;
  }

  #lsPrev {
    width: 4rem;
  }

  #lsNext {
    width: 4rem;
  }

  #Software {
    height: 18rem;
    top: 16%;
    right: 2rem;
  }

  #ZweiMaenner {
    height: 10rem;
    top: 32%;
    right: 10rem;
  }

  #Desktop {
    height: 20rem;
    top: 14%;
    right: 1rem;
  }

  #Tablet {
    height: 13rem;
    top: 30%;
    right: 4rem;
  }

  #FrauHandy {
    height: 10rem;
    top: 31%;
    right: 20rem;
  }

  #Artwork {
    height: 20rem;
    top: 14%;
    right: 1rem;
  }

  #Formen {
    height: 13rem;
    top: 20%;
    right: 5rem;
  }

  #People {
    height: 10rem;
    top: 33%;
    right: 6rem;
  }
}
</style>
