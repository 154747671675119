<template>
  <section :id="pos">
    <div id="CTHoriTimeline" class="ct-horizontal-timeline">
      <div class="head ct-container">
        <vue-markdown class="text-center sb-headline-blue markdown">{{ body.head }}</vue-markdown>
      </div>
      <div class="position-relative" id="timeline-desktop">
        <div
          id="line"
          data-aos-anchor-placement="center-bottom"
          data-aos="fade-right"
          data-aos-duration="600"
          data-aos-delay="400"
        ></div>
        <div class="row horizontal-timeline-wrapper">
          <div v-for="(step, index) in body.horizontalTimelineStep" :key="step.id" class="col">
            <div
              class="step"
              data-aos-anchor-placement="center-bottom"
              data-aos="fade-up"
              data-aos-duration="600"
              :data-aos-delay="300 * index"
            >
              <div class="">
                <div
                  class="step-icon-wrapper-horizontal"
                  v-if="step.icon.data && step.icon_not_reached.data"
                >
                  <img
                    class="icon"
                    v-if="
                      step.icon.data &&
                        step.icon.data.attributes.ext.toLowerCase().trim() != '.json'
                    "
                    :src="url + step.icon.data.attributes.url"
                    :alt="step.icon.data.attributes.alternativeText"
                  />
                  <lord-icon
                    class="icon"
                    v-if="
                      step.icon.data &&
                        step.icon.data.attributes.ext.toLowerCase().trim() == '.json'
                    "
                    trigger="hover"
                    target="div"
                    :src="url + step.icon.data.attributes.url"
                  ></lord-icon>
                </div>
                <div v-else class="number-icon-wrapper-horizontal">
                  <svg
                    class="icon-circle-horizontal"
                    width="100%"
                    height="100%"
                    viewBox="0 0 3000 3000"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    xml:space="preserve"
                    xmlns:serif="http://www.serif.com/"
                    style="fill-rule: evenodd; clip-rule: evenodd"
                  >
                    <g transform="matrix(27.6491,0,0,27.6491,1500,1500)">
                      <g transform="matrix(1,0,0,1,-40,-40)">
                        <clipPath id="_clip1">
                          <rect x="0" y="0" width="80" height="80" />
                        </clipPath>
                        <g clip-path="url(#_clip1)">
                          <circle
                            cx="40"
                            cy="40"
                            r="37.5"
                            style="
                          fill: none;
                          stroke: url(#_Linear2);
                          stroke-width: 5px;
                        "
                          />
                        </g>
                      </g>
                      <g></g>
                    </g>
                    <defs>
                      <linearGradient
                        id="_Linear2"
                        x1="0"
                        y1="0"
                        x2="1"
                        y2="0"
                        gradientUnits="userSpaceOnUse"
                        gradientTransform="matrix(80,0,0,80,0,41.5999)"
                      >
                        <stop offset="0" style="stop-color: rgb(11, 49, 94); stop-opacity: 1" />
                        <stop offset="1" style="stop-color: rgb(36, 106, 164); stop-opacity: 1" />
                      </linearGradient>
                    </defs>
                  </svg>
                  <p v-if="body.startFromZero">{{ index }}</p>
                  <p v-else>{{ index + 1 }}</p>
                </div>
                <h6 class="step-title">{{ step.title }}</h6>
              </div>
              <div v-if="step.content">
                <vue-markdown
                  class="markdown sb-headline-3-blue inhalt"
                  :source="step.content"
                ></vue-markdown>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="timeline-mobile" class="ct-container">
      <Timeline
        :timelineStep="body.horizontalTimelineStep"
        :startFromZero="body.startFromZero"
        :id="pos.toString()"
      />
    </div>
  </section>
</template>

<script>
import VueMarkdown from "vue-markdown";
import Timeline from "./element/Timeline.vue";
import { getStrapiUrl } from "../helper";
export default {
  name: "ContentTypeHorizontalTimeline",
  data() {
    return {
      url: getStrapiUrl()
    };
  },
  props: {
    body: {
      type: Object
    },
    pos: {
      type: Number
    }
  },
  components: {
    VueMarkdown,
    Timeline
  }
};
</script>

<style scoped>
.ct-horizontal-timeline {
  margin: 7rem 0;
  position: relative;
}

#timeline-mobile {
  display: none;
}

#line {
  height: 0.3rem;
  z-index: -999;
  width: 45%;
  background-color: var(--strawbinary-light-blue);
  top: 15rem;
  text-align: center;
  margin: 0 auto;
  position: relative;
}

.step {
  padding: 5rem 2rem;
  position: relative;
  display: flex;
  flex-direction: column;
}

.step-title {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  text-align: center;
}

.icon,
.icon-not-reached {
  position: absolute;
  background-color: white;
  border-radius: 0;
  left: 15rem;
  height: 10rem;
  width: 10rem;
  top: -3rem;
  z-index: 999;
}

.step-icon-wrapper-horizontal {
  position: absolute;
  height: 100%;
  width: 100%;
}

.icon-circle-horizontal {
  background-color: white;
  border-radius: 0;
  height: 10rem;
  width: 10rem;
  z-index: 999;
}

.number-icon-wrapper-horizontal {
  position: relative;
  text-align: center;
}

.number-icon-wrapper-horizontal p {
  display: inline-block;
  position: absolute;
  top: 12%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  z-index: 900;
  background: linear-gradient(180deg, #0b315e 0%, #246aa4 100%);
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "Rubik", sans-serif;
}

.horizontal-timeline-wrapper {
  padding: 5rem 15vw;
}

.head {
  background-color: var(--strawbinary-light-blue);
}

@media screen and (max-width: 125em) {
  .horizontal-timeline-wrapper {
    padding-left: 10vw;
    padding-right: 10vw;
  }

  .number-icon-wrapper-horizontal p {
    top: 13%;
  }
}

@media screen and (max-width: 106.25em) {
  .number-icon-wrapper-horizontal p {
    top: 20%;
  }
}

@media screen and (max-width: 87.5em) {
  .horizontal-timeline-wrapper {
    padding-left: 5vw;
    padding-right: 5vw;
  }
}

@media screen and (max-width: 78.125em) {
  #timeline-desktop {
    display: none;
  }
  #timeline-mobile {
    display: block;
  }

  .number-icon-wrapper-horizontal p {
    top: 12%;
  }
}

@media screen and (max-width: 56.25em) {
  .ct-horizontal-timeline {
    margin: 5vw 0;
  }
}
</style>
