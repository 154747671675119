var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"ct-container",attrs:{"id":_vm.pos}},[_c('div',{attrs:{"id":"KundenHighlight"}},[(_vm.body.kunde.length <= 3)?_c('div',{attrs:{"id":"KundenHighlight-desktop"}},[_vm._m(0),_c('div',{staticClass:"row kunden-row"},_vm._l((_vm.body.kunde),function(kunde){return _c('div',{key:kunde.id,staticClass:"justify-center justify-content-between col"},[_c('Card',{attrs:{"card":kunde,"data-aos-anchor-placement":"center-bottom","data-aos":"fade-up","data-aos-duration":"600"}})],1)}),0)]):_c('div',{staticClass:"position-relative",attrs:{"id":"KundenHighlight-desktop"}},[_vm._m(1),_c('carousel',{ref:"carousel",attrs:{"perPageCustom":[
          [0, 1],
          [1400, 2],
          [1950, 3]
        ],"paginationEnabled":false,"scrollPerPage":true,"navigateTo":_vm.pageDesktop,"autoplay":false,"autoplayTimeout":0}},_vm._l((_vm.body.kunde),function(kunde){return _c('slide',{key:kunde.id},[_c('div',{staticClass:"justify-center"},[_c('Card',{attrs:{"card":kunde,"data-aos-anchor-placement":"center-bottom","data-aos":"fade-up","data-aos-duration":"600"}})],1)])}),1),(_vm.sideButtons)?_c('div',{staticClass:"text-center slide-control-wrapper"},[_c('button',{staticClass:"clear-button arrow arrowLEFT btnhoverLEFT",on:{"click":function($event){_vm.pageDesktop === 0 ? (_vm.pageDesktop = _vm.$refs.carousel.pageCount - 1) : _vm.pageDesktop--}}}),_c('button',{staticClass:"clear-button arrow arrowRIGHT btnhoverRIGHT",on:{"click":function($event){_vm.pageDesktop === _vm.$refs.carousel.pageCount - 1 ? (_vm.pageDesktop = 0) : _vm.pageDesktop++}}})]):_vm._e()],1),_c('div',{attrs:{"id":"KundenHighlight-mobile"}},[_vm._m(2),_c('carousel',{ref:"kundenHighlightSliderMobile",attrs:{"perPageCustom":[
          [0, 1],
          [1024, 2],
          [1200, 3]
        ],"paginationEnabled":false,"scrollPerPage":true,"autoplay":false},on:{"page-change":_vm.PageChangeDragSlide}},_vm._l((_vm.body.kunde),function(kunde){return _c('slide',{key:kunde.id},[_c('div',{staticClass:"justify-center kunde-wrapper"},[_c('Card',{attrs:{"card":kunde,"data-aos-anchor-placement":"center-bottom","data-aos":"fade-up","data-aos-duration":"600"}})],1)])}),1),_c('div',{staticClass:"text-center pagination-dots"},_vm._l((this.sliderCount),function(i){return _c('button',{key:i.id,staticClass:"fa-circle slider-custom-pagination",class:{ fas: i == _vm.activePage, far: i !== _vm.activePage },attrs:{"type":"button","name":"button"},on:{"click":function($event){return _vm.PaginationCarousel(i)}}})}),0)],1),_c('div',{staticClass:"btn-wrapper"},[_c('Button',{attrs:{"button":_vm.body.link}})],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center"},[_c('h2',{staticClass:"sb-headline-blue"},[_vm._v("Unsere Kunden")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center"},[_c('h2',{staticClass:"sb-headline-blue"},[_vm._v("Unsere Kunden")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center"},[_c('h2',{staticClass:"sb-headline-blue"},[_vm._v("Unsere Kunden")])])}]

export { render, staticRenderFns }