<template lang="html">
  <section :id="pos" class="ct-container-y">
    <vue-markdown class="text-center sb-headline-blue"> ## {{ body.headline }} </vue-markdown>
    <div class="text-1-wrapper">
      <vue-markdown class="text-1" v-if="body.text_1" :source="body.text_1"></vue-markdown>
    </div>
    <div
      :style="{ 'background-image': 'url(' + url + body.image.data.attributes.url + ')' }"
      class="about-us-image"
    ></div>
    <div class="text-2-wrapper">
      <vue-markdown class="text-2" v-if="body.text_2" :source="body.text_2"></vue-markdown>
    </div>
  </section>
</template>

<script>
import VueMarkdown from "vue-markdown";
import { getStrapiUrl } from "../helper";
export default {
  name: "ContentTypeAboutUs",
  data() {
    return {
      url: getStrapiUrl()
    };
  },
  props: {
    body: {
      type: Object
    },
    pos: {
      type: Number
    }
  },
  components: {
    VueMarkdown
  }
};
</script>

<style lang="css" scoped>
.about-us-image {
  background-repeat: no-repeat;
  background-position-y: 25%;
  height: 90rem;
  background-size: cover;
  position: relative;
}

.about-us-image::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 0;
  background-color: white;
  clip-path: polygon(100% 0, 0 0, 0 75%);
  top: -.1rem;
  height: 10vw;
}

.about-us-image::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 0;
  background-color: white;
  clip-path: polygon(100% 25%, 100% 100%,0 100%);
  bottom: 0;
  height: 10vw;
  bottom: -.1rem;
}

.text-2-wrapper {
  background-image: url("/assets/lines.svg");
  transform: scaleX(-1);
  background-repeat: no-repeat;
  background-position: 8% 0;
  padding: 0 15vw 5rem 15vw;
  margin-top: 10rem;
}

.text-2 {
  transform: scaleX(-1);
}

.text-1-wrapper {
  background-image: url("/assets/lines.svg");
  background-repeat: no-repeat;
  background-position: 8% 0;
  padding: 10rem 15vw;
  padding-top: 0;
}

.headline {
  padding-bottom: 9rem;
}

@media screen and (max-width: 125em) {
  .about-us-image {
    height: 45vw;
  }
}

@media screen and (max-width: 93.75em) {
  .text-1-wrapper {
    padding: 0 18rem 6vw 18rem;
  }

  .text-2-wrapper {
    padding: 6vw 18rem 0 18rem;
    margin: 0;
  }

  .text-2-wrapper,
  .text-1-wrapper {
    background-image: unset;
  }

  .headline {
    padding-bottom: 3vw;
  }
}

@media screen and (max-width: 75em) {
  .text-2-wrapper {
    padding: 6vw 8vw 0 8vw;
  }
  .text-1-wrapper {
    padding: 6vw 8rem;
  }
}

@media screen and (max-width: 62em) {
  .text-2-wrapper {
    padding: 6vw 6rem 0 6rem;
  }
  .text-1-wrapper {
    padding: 6vw 6rem;
  }
}

@media screen and (max-width: 36em) {
  .text-2-wrapper {
    padding: 6vw 4rem 0 4rem;
  }
  .text-1-wrapper {
    padding: 6vw 4rem;
  }
}

@media screen and (max-width: 24.375em) {
  .text-2-wrapper {
    padding: 6vw 2.2rem 0 2.2rem;
  }
  .text-1-wrapper {
    padding: 6vw 2.2rem;
  }
}
</style>
