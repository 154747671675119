<template>
  <article :id="pos" class="ct-container">
    <vue-markdown class="text-center sb-headline-blue"> ## {{ body.title }} </vue-markdown>
    <div @click="curIndex = index" v-for="(dropdown, index) in body.dropdown" :key="dropdown.id">
      <Dropdown :dropdown="dropdown" :isActive="curIndex === index" />
    </div>
  </article>
</template>

<script>
import Dropdown from "./element/Dropdown.vue";
import VueMarkdown from "vue-markdown";
export default {
  name: "CTDropdown",
  data() {
    return {
      curIndex: null
    };
  },
  props: {
    body: {
      type: Object
    },
    pos: {
      type: Number
    }
  },
  components: {
    Dropdown,
    VueMarkdown

  }
};
</script>

<style lang="css" scoped></style>
