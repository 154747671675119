var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ApolloQuery',{attrs:{"query":require('../graphql/Nav.gql'),"variables":{ locale: _vm.locale }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ref_result = ref.result;
var error = ref_result.error;
var data = ref_result.data;
return [(data)?_c('nav',{attrs:{"id":"nav"}},[_c('div',{class:{ navOpen: _vm.navOpen },attrs:{"id":"nav-desktop"}},[_c('div',{attrs:{"id":"menuToggle"}},[_c('input',{attrs:{"type":"checkbox"},on:{"click":_vm.navTrigger}}),_c('span'),_c('span'),_c('span')]),_c('div',{staticClass:"logo-wrapper-outer"},[_c('div',{staticClass:"logo-wrapper-inner"},[_c('img',{staticClass:"logo",attrs:{"src":"/assets/Strawbinary_Logo_Weiss_flat_filled.svg"}})])]),_c('div',{})]),_c('div',{attrs:{"id":"nav-mobile"}},[_c('div',{staticStyle:{"position":"relative"}},[_c('transition',{attrs:{"name":"slide-fade"}},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(!_vm.navOpen),expression:"!navOpen"}],staticClass:"logo",attrs:{"src":"/assets/Strawbinary_Logo_Weiss_flat.svg"},on:{"click":_vm.home}})])],1),_c('div',{attrs:{"id":"menuToggle"}},[_c('input',{attrs:{"type":"checkbox"},on:{"click":_vm.navTrigger}}),_c('span'),_c('span'),_c('span')])]),_c('div',{staticClass:"sidenav",attrs:{"id":"sidenav"}},[_c('div',{staticClass:"sidenav-bg"},[_c('div',{staticClass:"sidenav-bg-left"},[_c('div',{staticClass:"sidenav-bg-right"},[_c('div',{staticClass:"sidenav-bg-dots"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"link-container"},[_c('Button',{attrs:{"button":{
                          label: 'Home',
                          url: '/',
                          style: 'nav_link'
                        }},nativeOn:{"click":function($event){return _vm.navTrigger($event)}}}),_vm._l((data.mainNav.data.attributes.link),function(link){return _c('Button',{key:link.id,attrs:{"button":link},nativeOn:{"click":function($event){return _vm.navTrigger($event)}}})})],2)]),_c('div',{staticClass:"col logo-call-to-action-wrapper"},[_c('div',{staticClass:"logo-call-to-action"},[_c('img',{staticClass:"nav-logo",attrs:{"src":"/assets/Strawbinary_Logo_Weiss.svg","alt":"Strawbinary Logo"}}),_c('div',{staticClass:"text-center call-to-action"},[_c('vue-markdown',{staticClass:"markdown-rubik white",attrs:{"source":data.mainNav.data.attributes.call_to_action_text}}),_c('br'),_c('Button',{key:data.mainNav.data.attributes.call_to_action_link.id,attrs:{"button":data.mainNav.data.attributes.call_to_action_link},nativeOn:{"click":function($event){return _vm.navTrigger($event)}}})],1)])])])])])])])])]):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }