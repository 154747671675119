<template lang="html">
  <section :id="pos">
    <div id="componentCollection">
      <div class="background-top-wave">
        <div class="background-right-dots">
          <div class="background-left-dotted-circles">
            <div class="comcol-inner">
              <div class="content-col">
                <div class="top-left-graphic" @click="home">
                  <img
                    :src="url + body.topGraphic.data.attributes.url"
                    :alt="body.topGraphic.data.attributes.alternativeText"
                  />
                </div>
                <div class="ct-comcoll-inner">
                  <div class="comcol-content">
                    <h1 class="main-headline">
                      <p class="fs-landing-small">{{ body.smallHeadline }}</p>
                      <span class="fs-landing-big" id="bigHeadline">{{ body.bigHeadline }}</span>
                    </h1>
                    <div class="btn-wrapper">
                      <button
                        :class="{
                          'btn-primary-sb': selectedCategory == type,
                          'btn-dark-primary-sb': selectedCategory != type
                        }"
                        class="clear-button"
                        v-for="type in arrayBtn"
                        :key="type"
                        v-on:click="selectedCategory = type"
                      >
                        <label>
                          <vue-markdown class="sb-p-0">{{ type }}</vue-markdown>
                        </label>
                      </button>
                    </div>
                  </div>
                  <div class="card-position">
                    <div class="row justify-content-between">
                      <div class="col-auto card-wrapper" v-for="card in filterCard" :key="card.id">
                        <Card class="card-element" :card="card" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Card from "./element/Card.vue";
import VueMarkdown from "vue-markdown";
import { getStrapiUrl } from "../helper";
export default {
  name: "ComponentCollection",
  data() {
    return {
      url: getStrapiUrl(),
      arrayBtn: ["Alle"],
      selCategory: ""
    };
  },
  props: {
    body: {
      type: Object
    },
    pos: {
      type: Number
    }
  },
  mounted() {
    if (localStorage.selectedCategory !== "null" && localStorage.selectedCategory !== null) {
      this.selectedCategory = localStorage.selectedCategory;
    } else {
      this.selectedCategory = "Alle";
    }
    if (this.body) {
      for (const cardname of this.body.cardComponent) {
        if (!this.arrayBtn.includes(cardname.category.data.attributes.category)) {
          this.arrayBtn.push(cardname.category.data.attributes.category);
        }
      }
    }
  },
  computed: {
    filterCard: function() {
      if (this.selectedCategory == "Alle") {
        return this.body.cardComponent;
      } else {
        return this.body.cardComponent.filter(
          e => e.category.data.attributes.category == this.selectedCategory
        );
      }
    },
    selectedCategory: {
      get() {
        return this.selCategory;
      },
      set(category) {
        localStorage.selectedCategory = null;
        this.selCategory = category;
      }
    }
  },
  methods: {
    home() {
      this.$router.push("/");
    }
  },
  components: {
    Card,
    VueMarkdown
  }
};
</script>

<style lang="css" scoped>
.row,
.col-auto {
  margin: 0;
  padding: 0;
}

#componentCollection {
  background-color: #ffffff;
}

.content-col {
  position: relative;
}

.comcol-content {
  position: relative;
  top: 30%;
}

.top-left-graphic {
  background-color: var(--strawbinary-dark-grey);
  display: inline-block;
  cursor: pointer;
}

.top-left-graphic img {
  height: 9rem;
  padding: 2rem 3rem;
}

.comcol-inner {
  height: 100%;
}

.comcol-inner .row {
  height: 55%;
}

.ct-comcoll-inner {
  padding: 5vw 8vw;
  padding-top: 10vw;
}

.btn-box {
  display: inline-block;
  position: relative;
  width: max-content;
  padding: 12rem 8vw 10rem 8vw;
}

.btn-wrapper {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  width: 100%;
}

.btn-wrapper button:first-of-type {
  margin-left: 0;
}

.btn-wrapper button {
  width: max-content;
  height: min-content;
  flex-basis: auto;
  margin: 1rem;
  margin-right: 6rem;
  margin-left: 0;
}

.main-headline #bigHeadline {
  font-family: "Rubik", sans-serif;
  font-weight: 500;
}

.main-headline p {
  font-weight: 300;
  font-family: "Rubik", sans-serif;
  margin-bottom: 2rem;
}

.main-headline {
  border-bottom: 0.8rem solid var(--strawbinary-dark-grey);
  margin-bottom: 3vw;
  padding-bottom: 3rem;
  width: 40%;
}

.card-position {
  margin: 4rem 0 5vw 0;
}

.background-top-wave {
  height: 100%;
  background-image: url("/assets/wellenWaagrecht.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position-y: -4rem;
}

.background-right-dots {
  height: 100%;
  background-image: url("/assets/Dots_rechteck_horizontal.svg");
  background-repeat: no-repeat;
  background-position: 79% 1rem;
  background-size: 20%;
}

.background-left-dotted-circles {
  height: 100%;
  background-image: url("/assets/dotted-circles.svg");
  background-repeat: no-repeat;
  background-position: -15% 28rem;
  background-size: 33vw;
}

.card-element {
  margin-right: 4rem;
}

@media screen and (max-width: 125em) {
  .main-headline p {
    margin-bottom: 1rem;
  }

  .main-headline {
    padding-bottom: 2rem;
  }
}

@media screen and (max-width: 93.75em) {
  .btn-box {
    padding: 9rem 3vw 5rem 3vw;
  }
}

@media screen and (max-width: 83.3125em) {
  .card-wrapper {
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (max-width: 81.25em) and (max-height: 46.875em) {
  .btn-box {
    padding: 7rem 3vw 3rem 3vw;
  }

  .main-headline p {
    margin-bottom: 0.5rem;
  }

  .top-left-graphic img {
    height: 8rem;
  }
}

@media screen and (max-width: 68.75em) {
  .top-left-graphic {
    display: none;
  }

  .ct-comcoll-inner {
    padding: 5vw 8vw;
    padding-top: 20vw;
  }

  .background-top-wave {
    background-position-y: 7rem;
  }

  .background-right-dots {
    background-position-x: 65vw;
    background-size: 30vw;
    background-position-y: 8rem;
  }

  .main-headline {
    padding-bottom: 1rem;
    width: 45%;
  }

  .main-headline p {
    margin-bottom: 0.5rem;
  }
}

@media screen and (max-width: 53.125em) {
  .main-headline p {
    margin-bottom: 0;
  }

  .main-headline {
    padding-bottom: 0.5rem;
    border-bottom: 0.5rem solid var(--strawbinary-dark-grey);
  }

  .ct-comcoll-inner {
    padding-top: 24vw;
  }

  .btn-wrapper button {
    margin: 1rem;
    margin-right: 4rem;
    margin-left: 0;
  }

  .card-element {
    margin-left: 0;
    padding-right: 0;
  }
}

@media screen and (max-width: 42.5em) {
  .main-headline {
    width: 50%;
  }

  .background-left-dotted-circles {
    background: none;
  }

  .background-right-dots {
    background-position-x: 74vw;
    background-position-y: 6rem;
    background-size: 55vw;
  }
}

@media screen and (max-width: 33.125em) {
  .main-headline {
    width: 27rem;
  }

  .ct-comcoll-inner {
    padding-top: 30vw;
  }

  /* Buttons auf Handygröße ausgeblendet */
  .btn-wrapper {
    display: none;
  }
}

@media screen and (max-width: 30em) {
  .main-headline {
    width: 20rem;
  }
}
</style>
