<template>
  <div class="select-wrapper">
    <details class="custom-select">
      <summary class="radios" :class="{ 'border-bottom-sb-03': picked !== '' }">
        <input
          type="radio"
          name="item"
          id="default"
          :title="placeholder"
          :key="placeholder"
          checked
        />
        <input
          v-for="item in items"
          :key="item"
          type="radio"
          name="item"
          :id="item"
          :title="item"
          :value="item"
          v-model="picked"
          @input="$emit('update:picked', $event.target.value)"
        />
        <input
          type="radio"
          name="item"
          id="empty"
          title="---"
          value="---"
          v-model="picked"
          @input="$emit('update:picked', $event.target.value)"
        />
      </summary>
      <ul class="list">
        <li
          :class="{ selectedOption: item == selected }"
          @click="selected = item"
          v-for="item in items"
          :key="item"
        >
          <div class="label-wrapper">
            <label :for="item">{{ item }}</label>
          </div>
        </li>
        <li :class="{ selectedOption: item == selected }" @click="selected = item">
          <div class="label-wrapper">
            <label for="empty">---</label>
          </div>
        </li>
      </ul>
    </details>
  </div>
</template>

<script>
export default {
  name: "StrawbinarySelect",
  data() {
    return {
      selected: ""
    };
  },
  props: {
    placeholder: {
      type: String
    },
    items: {
      type: Array
    },
    picked: {
      type: String
    }
  }
};
</script>

<style scoped>
.select-wrapper {
  width: 100%;
  position: relative;
}

details {
  position: relative;
  width: 100%;
  margin-right: 1rem;
}

details[open] {
  z-index: 1;
}

summary {
  padding: 1rem 4rem;
  cursor: pointer;
  background-color: white;
  list-style: none;
  border-bottom: 0.3rem solid #1f1f1f;
  font-size: 1.7rem;
}

summary::-webkit-details-marker {
  display: none;
}

details[open] summary:before {
  content: "";
  display: block;
  width: 100vw;
  height: 100vh;
  background: transparent;
  position: fixed;
  top: 0;
  left: 0;
}

summary:after {
  content: "";
  display: inline-block;
  position: absolute;
  left: 1.5rem;
  top: 1.1rem;
  color: var(--strawbinary-dark-grey);
  pointer-events: none;
  width: 1.5rem;
  height: 1.5rem;
  border-bottom: 0.3rem solid currentColor;
  border-left: 0.3rem solid currentColor;
  border-bottom-left-radius: 2px;
  transform: rotate(135deg) translate(49%, -43%);
  transform-origin: center center;
  transition: transform ease-in-out 100ms;
}

summary:focus {
  outline: none;
}

details[open] summary:after {
  transform: rotate(-45deg) translate(0%, 0%);
  top: 1.2rem;
}

ul {
  width: 100%;
  background: white;
  position: absolute;
  top: calc(100% + 0.5rem);
  left: 0;
  list-style-type: none;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  max-height: 200px;
  overflow-y: auto;
}

.label-wrapper {
  margin: 0;
  padding: 1rem;
  padding-left: 3.5rem;
  border-left: 0.3rem solid white;
  border-bottom: 0.1rem solid var(--strawbinary-light-blue);
  border-top: 0.1rem solid var(--strawbinary-light-blue);
}

.label-wrapper:hover,
.selectedOption .label-wrapper {
  border-left: 0;
}

.label-wrapper:last-child {
  border-bottom: none;
}

li:hover {
  border: none;
  border-left: 0.3rem solid;
  border-image-source: linear-gradient(180deg, #0b315e 0%, #246aa4 100%);
  border-image-slice: 1;
}

.selectedOption {
  border: none;
  border-left: 0.3rem solid;
  border-image-source: linear-gradient(180deg, #0b315e 0%, #246aa4 100%);
  border-image-slice: 1;
}

li:last-child:hover,
li:last-child.selectedOption {
  border-bottom: none;
}

/* FAKE SELECT */

summary.radios {
  counter-reset: radios;
}

input[type="radio"] {
  counter-increment: radios;
  appearance: none;
  display: none;
  pointer-events: none;
}

input[type="radio"]:checked {
  display: inline;
}

input[type="radio"]:after {
  content: attr(title);
  display: inline;
}

ul.list {
  counter-reset: labels;
}

label {
  width: 100%;
  display: block;
  cursor: pointer;
}
</style>
