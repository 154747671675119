<template>
  <div :id="pos">
    <div id="importantPoints" class="ct-container-y">
      <vue-markdown
        class="markdown sb-headline-blue sb-headline-3-blue"
        :source="body.content"
      ></vue-markdown>
      <div class="important-points-wrapper ct-container-x-ip row text-center">
        <div class="important-point col" v-for="(point, index) in body.importantPoint" :key="point.id">
          <vue-markdown
            data-aos-anchor-placement="center-bottom"
            data-aos="fade-down"
            data-aos-duration="800"
            :data-aos-delay="300 * index"
            :source="point.point"
            class="sb-p-0 point"
          ></vue-markdown>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueMarkdown from "vue-markdown";
import { getStrapiUrl } from "../helper";
export default {
  name: "ContentTypeImportantPoints",
  data() {
    return {
      url: getStrapiUrl()
    };
  },
  props: {
    body: {
      type: Object
    },
    pos: {
      type: Number
    }
  },
  components: {
    VueMarkdown
  }
};
</script>

<style scoped>
.important-points-wrapper {
  background-color: var(--strawbinary-light-blue);
  margin-left: 0;
  margin-right: 0;
}

.important-point {
  padding: 10rem 5rem;
}

.point {
  font-family: "Rubik", sans-serif;
  font-weight: bold;
  color: var(--strawbinary-dark-blue);
  margin: 0;
  word-break: break-word;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
}

@media screen and (max-width: 128.75em) {
  .important-point {
    padding: 8rem 2rem;
  }

  .point {
    min-width: 15rem;
  }
}

@media screen and (max-width: 87.5em) {
  .important-point {
    padding: 5rem 2rem;
  }
}

@media screen and (max-width: 62.5em) {
  .important-point {
    padding: 2.5rem 4rem;
  }

  .point {
    min-width: 25rem;
  }
}

@media screen and (max-width: 48em) {
  .point {
    padding: 0 2.5rem;
  }

  .important-point {
    padding: 2.5rem 2rem;
  }
}
</style>
