var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ApolloQuery',{attrs:{"query":require('../graphql/Footer.gql'),"variables":{ locale: _vm.locale }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ref_result = ref.result;
var error = ref_result.error;
var data = ref_result.data;
return [(data)?_c('footer',[_c('div',{staticClass:"top"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"contactdata"},[(data.footer.data.attributes.contactdata.name)?_c('p',{staticClass:"medium"},[_vm._v(" "+_vm._s(data.footer.data.attributes.contactdata.name)+" ")]):_vm._e(),(data.footer.data.attributes.contactdata.strasse_hsnr)?_c('p',[_vm._v(" "+_vm._s(data.footer.data.attributes.contactdata.strasse_hsnr)+" ")]):_vm._e(),(
                  data.footer.data.attributes.contactdata.plz &&
                    data.footer.data.attributes.contactdata.ort
                )?_c('p',[_vm._v(" "+_vm._s(data.footer.data.attributes.contactdata.plz)+" "+_vm._s(data.footer.data.attributes.contactdata.ort)+" ")]):_vm._e(),(data.footer.data.attributes.contactdata.tel)?_c('p',[_vm._v(" Telefon: "+_vm._s(data.footer.data.attributes.contactdata.tel)+" ")]):_vm._e(),(data.footer.data.attributes.contactdata.email)?_c('p',[_vm._v(" E-Mail: "+_vm._s(data.footer.data.attributes.contactdata.email)+" ")]):_vm._e()]),_c('div',{staticClass:"socialmedia"},_vm._l((data.footer.data.attributes.socialmedia),function(socialmedia){return _c('div',{key:socialmedia.id,staticClass:"plattform"},[_c('a',{attrs:{"target":"_blank","href":socialmedia.link}},[(
                      socialmedia.icon.data.attributes.ext.toLowerCase().trim() == '.svg' ||
                        socialmedia.icon.data.attributes.ext.toLowerCase().trim() == '.png' ||
                        socialmedia.icon.data.attributes.ext.toLowerCase().trim() == '.jpeg' ||
                        socialmedia.icon.data.attributes.ext.toLowerCase().trim() == '.jpg'
                    )?_c('img',{attrs:{"src":_vm.url + socialmedia.icon.data.attributes.url,"alt":socialmedia.icon.data.attributes.alternativeText}}):_vm._e(),(socialmedia.icon.data.attributes.ext.toLowerCase().trim() == '.json')?_c('lord-icon',{attrs:{"trigger":"hover","target":"a","src":_vm.url + socialmedia.icon.data.attributes.url}}):_vm._e()],1)])}),0)]),_c('div',{staticClass:"col top-text"},[_c('vue-markdown',{staticClass:"link-white",attrs:{"source":data.footer.data.attributes.text}})],1)])]),_c('div',{staticClass:"bottom"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"logo-wrapper"},[(data.footer.data.attributes.logo)?_c('img',{attrs:{"src":_vm.url + data.footer.data.attributes.logo.data.attributes.url,"alt":data.footer.data.attributes.logo.alternativeText}}):_vm._e(),_vm._v(" "),(data.footer.data.attributes.copyright)?_c('span',{staticClass:"copyright fs-copyright"},[_vm._v("© "+_vm._s(data.footer.data.attributes.copyright))]):_vm._e()])]),_c('div',{staticClass:"col bottom-links"},[_c('div',{staticClass:"legal-links"},_vm._l((data.footer.data.attributes.rechtlichelinks),function(link){return _c('div',{key:link.id},[_c('Button',{attrs:{"button":link}})],1)}),0)])])])]):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }