<template lang="html">
  <div class="card-form-type border-top-sb shadow-sb">
    <router-link
      v-if="card.page.data !== null && (card.url == '' || card.url == null)"
      :to="{ path: '/page/' + card.page.data.attributes.slug }"
    >
      <div class="text-center">
        <img
          class="card-image"
          :src="url + card.graphic.data.attributes.url"
          :alt="card.graphic.data.attributes.alternativeText"
        />
      </div>
      <div class="card-text-align">
        <vue-markdown class="fs-ctkunde-name card-headline sb-p-0">
          {{ card.headline }}</vue-markdown
        >
        <vue-markdown class="text-right sb-p-0 card-category">
          {{ card.category.data.attributes.category }}
        </vue-markdown>
      </div>
    </router-link>
    <!-- Url == 1. Priorität & Page == 2. Priorität  -->
    <a v-else-if="card.url !== null && card.url !== ''" :href="card.url">
      <div class="text-center">
        <img
          class="card-image"
          :src="url + card.graphic.data.attributes.url"
          :alt="card.graphic.data.attributes.alternativeText"
        />
      </div>
      <div class="card-text-align">
        <vue-markdown class="fs-ctkunde-name card-headline sb-p-0">{{
          card.headline
        }}</vue-markdown>
        <vue-markdown class="text-right card-category sb-p-0">
          {{ card.category.data.attributes.category }}
        </vue-markdown>
      </div>
    </a>
    <a v-else>
      <div class="text-center no-curser">
        <img
          class="card-image"
          :src="url + card.graphic.data.attributes.url"
          :alt="card.graphic.data.attributes.alternativeText"
        />
      </div>
      <div class="card-text-align no-curser">
        <vue-markdown class="fs-ctkunde-name card-headline sb-p-0">{{
          card.headline
        }}</vue-markdown>
        <vue-markdown class="text-right card-category sb-p-0">
          {{ card.category.data.attributes.category }}
        </vue-markdown>
      </div>
    </a>
  </div>
</template>

<script>
import VueMarkdown from "vue-markdown";
import { getStrapiUrl } from "../../helper";
export default {
  name: "Card",
  data() {
    return {
      url: getStrapiUrl()
    };
  },
  props: {
    card: {
      type: Object
    }
  },
  components: {
    VueMarkdown
  }
};
</script>

<style lang="css" scoped>
a {
  text-decoration: none;
}

.card-form-type:first-of-type {
  margin-left: 0;
}

.card-form-type:hover {
  cursor: pointer;
}

.card-form-type {
  align-self: flex-start;
  margin: 2rem 0;
  max-width: 49rem;
  min-width: 49rem;
}

.card-text-align {
  display: flex;
  justify-content: space-between;
  flex-flow: row;
  align-items: center;
}

.card-text-align > p {
  margin: 0;
}

.card-image {
  width: 100%;
  object-fit: cover;
  height: 28rem;
}

.card-headline {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  line-height: 3.8rem;
  padding: 1.5rem;
  color: #256aa5;
}

.card-category {
  font-family: Rubik;
  font-style: normal;
  font-weight: 300;
  line-height: 2.4rem;
  padding: 1.5rem;
  min-width: max-content;
  color: #1f1f1f;
}

.no-curser {
  cursor: default !important;
}

@media screen and (max-width: 53.125em) {
  .card-form-type {
    width: 60vw;
    max-width: 100%;
    min-width: unset;
    margin-bottom: 3rem;
  }

  .card-image {
    max-width: 100%;
    min-width: unset;
  }

  .card-headline {
    font-weight: 500;
    line-height: 3rem;
  }

  .card-category {
    font-weight: 300;
    line-height: 2rem;
  }
}

@media screen and (max-width: 33.125em) {
  .card-form-type {
    width: 100vw;
    margin: unset;
    margin-bottom: 3rem;
  }
}
</style>
