<template>
  <div id="contact">
    <img
      id="circle-graphic"
      class="circle-graphic-contact"
      src="/assets/contact/circle.svg"
      alt="Circle"
    />
    <div id="contact-form">
      <img
        id="circle-graphic"
        class="circle-graphic-contact-form"
        src="/assets/contact/circle.svg"
        alt="Circle"
      />
      <button @click="closeContactForm" class="clear-button back-button">
        <div class="round">
          <div id="arrow-wrapper">
            <span class="arrow first next"></span>
            <span class="arrow second next"></span>
          </div>
        </div>
      </button>
      <div v-if="!loading && !mailSuccess && !mailError">
        <h2 class="text-center headline sb-headline-blue">
          <lord-icon trigger="hover" src="/assets/light-bulb.json"></lord-icon>
          Schreibe uns eine Nachricht
        </h2>
        <div class="contact-form-wrapper">
          <div class="contact-form-type">
            <div class="text-center col border-top-sb shadow-sb contact-type-contact-form">
              <lord-icon
                trigger="hover"
                target="div"
                :src="this.contactTypeIcon"
                class="contact-icon-contact-form"
              ></lord-icon>
              <vue-markdown class="contact-type-text" :source="this.contactTypeText"></vue-markdown>
            </div>
          </div>
          <div class="contact-form">
            <div class="name-email-wrapper row">
              <div class="form-group col-sm">
                <div
                  class="wrap-input100 validate-input"
                  :class="{ 'alert-validate': $v.contactform.name.$error }"
                >
                  <input
                    @blur="$v.contactform.name.$touch"
                    id="form-name"
                    class="input100 fs-input"
                    type="text"
                    name="name"
                    v-model="contactform.name"
                    :class="{ 'has-val': contactform.name }"
                  />
                  <span class="focus-input100 fs-placeholder" data-placeholder="Name*"></span>
                </div>
                <p v-if="$v.contactform.name.$error" class="text-error fs-field-error">
                  <i class="fa fa-exclamation"></i> Die Eingabe darf nicht leer sein!
                </p>
              </div>
              <div class="form-group col-sm" :class="{ 'mb-5': $v.contactform.email.$error }">
                <div
                  class="wrap-input100 validate-input"
                  :class="{ 'alert-validate': $v.contactform.email.$error }"
                >
                  <input
                    @blur="$v.contactform.email.$touch"
                    id="form-email"
                    class="input100 fs-input"
                    type="email"
                    name="email"
                    v-model="contactform.email"
                    :class="{ 'has-val': contactform.email }"
                  />
                  <span class="focus-input100 fs-placeholder" data-placeholder="E-Mail*"></span>
                </div>
                <p v-if="$v.contactform.email.$error" class="text-error fs-field-error">
                  <i class="fa fa-exclamation"></i> Die Eingabe darf nicht leer sein und muss dem
                  Format: a@b.c entsprechen!
                </p>
              </div>
            </div>
            <div class="company-phone-wrapper row">
              <div class="form-group col-sm">
                <div class="wrap-input100">
                  <input
                    id="form-company"
                    class="input100 fs-input"
                    type="text"
                    name="firma"
                    v-model="contactform.firma"
                    :class="{ 'has-val': contactform.firma }"
                  />
                  <span class="focus-input100 fs-placeholder" data-placeholder="Firma"></span>
                </div>
              </div>
              <div class="form-group col-sm">
                <div class="wrap-input100">
                  <input
                    id="form-phone"
                    class="input100 fs-input"
                    type="tel"
                    name="tel"
                    v-model="contactform.tel"
                    :class="{ 'has-val': contactform.tel }"
                  />
                  <span class="focus-input100 fs-placeholder" data-placeholder="Telefon"></span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-sm-12">
                <div class="wrap-input100-xs">
                  <input
                    id="contact-website"
                    class="input100 fs-input"
                    type="url"
                    name="website"
                    v-model="contactform.website"
                    :class="{ 'has-val': contactform.website }"
                  />
                  <span class="focus-input100 fs-placeholder" data-placeholder="Website"></span>
                </div>
              </div>
            </div>
            <div class="row" v-if="this.contactType === 'software'">
              <div class="form-group col-sm-12">
                <p v-if="contactform.softwareType" class="select-label">
                  Um was handelt sich dein Projekt?
                </p>
                <div class="wrap-input100-xs-b0">
                  <StrawbinarySelect
                    :picked.sync="contactform.softwareType"
                    :placeholder="'Um was handelt sich dein Projekt?'"
                    :items="[
                      'Webapplikation',
                      'Mobileapplikation',
                      'Desktopapplikation',
                      'Webshop'
                    ]"
                  />
                </div>
              </div>
            </div>
            <div class="row" v-if="this.contactType === 'design'">
              <div class="form-group col-sm-12">
                <p v-if="contactform.designType" class="select-label">Was benötigst du?</p>
                <div class="wrap-input100-xs-b0">
                  <StrawbinarySelect
                    :picked.sync="contactform.designType"
                    :placeholder="'Was benötigst du?'"
                    :items="[
                      'Logo',
                      'Flyer',
                      'Visitenkarte',
                      'Corporate Design',
                      'Ich brauche mehrere Designs',
                      'Sonstiges'
                    ]"
                  />
                </div>
              </div>
            </div>
            <div class="row" v-if="!this.contactType === 'hello'">
              <div class="form-group col-sm-12">
                <div class="wrap-input100-xs">
                  <input
                    id="contact-deadline"
                    class="input100 fs-input"
                    type="text"
                    name="deadline"
                    v-model="contactform.deadline"
                    :class="{ 'has-val': contactform.deadline }"
                  />
                  <span
                    class="focus-input100 fs-placeholder"
                    data-placeholder="Wann soll das Projekt fertig sein?"
                  ></span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-sm-12 message-area">
                <div
                  class="mb-1 wrap-input100-xs validate-input"
                  :class="{ 'alert-validate': $v.contactform.message.$error }"
                >
                  <textarea
                    @blur="$v.contactform.message.$touch"
                    id="form-msg"
                    class="input100 fs-textarea"
                    name="msg"
                    v-model="contactform.message"
                    :class="{ 'has-val': contactform.message }"
                  ></textarea>
                  <span class="focus-input100 fs-placeholder" data-placeholder="Nachricht*"></span>
                </div>
                <p v-if="$v.contactform.message.$error" class="text-error fs-field-error">
                  <i class="fa fa-exclamation"></i> Die Eingabe darf nicht leer sein!
                </p>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-sm-12 datenschutz-control-wrapper fs-data-protection">
                <div>
                  <label class="datenschutz-control validate-input">
                    <input
                      @blur="$v.contactform.datenschutzCheck.$touch"
                      id="contact-dsc"
                      type="checkbox"
                      class="contact-check input100 fs-input"
                      name="datenschutz-check"
                      v-model="contactform.datenschutzCheck"
                    />
                    &nbsp;<a class="hover-bright" target="_blank" href="page/datenschutz"
                      >Datenschutzerklärung</a
                    >
                    gelesen und akzeptiert.*
                  </label>
                  <p
                    v-if="$v.contactform.datenschutzCheck.$error"
                    class="text-error fs-field-error"
                  >
                    <i class="fa fa-exclamation"></i> Sie müssen die Datenschutzerklärung
                    akzeptieren!
                  </p>
                </div>
                <small id="messageHelp" class="form-text text-muted">
                  Die Daten, die Sie uns übermitteln, benötigen wir, um Ihre Anfrage zu bearbeiten.
                  Zur Bearbeitung Ihres Anliegens verwenden wir die Kommunikationswege, die Sie uns
                  in dem Kontaktformular zur Verfügung stellen. Wenn Sie wissen möchten, wie wir mit
                  Ihren personenbezogenen Daten umgehen, können Sie dies in unserer
                  <a class="hover-bright-light" href="page/datenschutz" target="_blank"
                    >Datenschutzerklärung</a
                  >
                  nachlesen.
                </small>
                <small id="messageHelp" class="form-text text-muted"
                  >Die mit * gekennzeichneten Felder sind Pflichtfelder!</small
                >
              </div>
            </div>
            <div class="text-center">
              <div style="display: inline-block" @click="sendMail">
                <Button
                  :button="sendButton"
                  :url="''"
                ></Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <lord-icon
        class="loading-icon"
        v-if="loading"
        trigger="loop"
        src="/assets/loader.json"
      ></lord-icon>
      <div id="sendStatus">
        <div class="success" v-if="mailSuccess">
          <div class="message">
            <p class="contactform-send-success-text">
              <b class="contactform-send-success-headline">You did it -</b> <br />
              Deine Nachricht wurde erfolgreich versendet
            </p>
          </div>
        </div>
        <div class="error" v-if="mailError">
          <div class="message">
            <p class="contactform-send-error-headline">
              <b class="contactform-send-error-headline">Oh no - </b><br />
              Deine Nachricht konnte nicht versendet werden. <br />
              Bitte versuche es später noch einmal.
            </p>
          </div>
        </div>
      </div>
      <div class="home-button-wrapper" v-if="!loading && (mailSuccess || mailError)">
        <Button :button="homeButton"></Button>
      </div>
    </div>
    <div class="bg-dots-left">
      <div class="bg-dots-right">
        <div class="spinner-left">
          <div id="main" class="main">
            <h2 class="text-center headline sb-headline-blue">
              <lord-icon trigger="hover" src="/assets/light-bulb.json"></lord-icon>
              Projekt starten
            </h2>
            <div class="contact-type-outer-wrapper">
              <div class="row contact-type-wrapper">
                <div class="col contact-type" @click="openContactForm('website')">
                  <lord-icon
                    trigger="hover"
                    target="div"
                    src="/assets/contact/computer-display.json"
                    class="contact-icon bg-light-blue border-top-sb shadow-sb"
                  ></lord-icon>
                  <p class="mt-4 fs-make-contact font-weight-medium text-dark-blue hover-pointer">
                    Website
                  </p>
                </div>
                <div class="col contact-type" @click="openContactForm('software')">
                  <lord-icon
                    trigger="hover"
                    target="div"
                    src="/assets/contact/multimedia-code-2.json"
                    class="contact-icon bg-light-blue border-top-sb shadow-sb"
                  ></lord-icon>
                  <p class="mt-4 fs-make-contact font-weight-medium text-dark-blue hover-pointer">
                    Individual&shy;software
                  </p>
                </div>
              </div>
              <div class="row contact-type-wrapper">
                <div class="col contact-type" @click="openContactForm('design')">
                  <lord-icon
                    trigger="hover"
                    target="div"
                    src="/assets/contact/vector-design-pen.json"
                    class="contact-icon bg-light-blue border-top-sb shadow-sb"
                  ></lord-icon>
                  <p class="mt-4 fs-make-contact font-weight-medium text-dark-blue hover-pointer">
                    Grafik&shy;design
                  </p>
                </div>
                <div class="col contact-type" @click="openContactForm('hello')">
                  <lord-icon
                    trigger="hover"
                    target="div"
                    src="/assets/contact/handshake-deal.json"
                    class="contact-icon bg-light-blue border-top-sb shadow-sb"
                  ></lord-icon>
                  <p class="mt-4 fs-make-contact font-weight-medium text-dark-blue hover-pointer">
                    Einfach "Hallo" sagen
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueMarkdown from "vue-markdown";
import Button from "./element/Button.vue";
import StrawbinarySelect from "./element/StrawbinarySelect.vue";
import { required, email } from "vuelidate/lib/validators";
import axios from "axios";
import { getStrapiUrl } from "../helper";

export default {
  name: "Kontakformular",
  data() {
    return {
      url: getStrapiUrl(),
      sendButton: {
        style: "dark_primary",
        label: "Absenden",
        icon: {
          data: {
            attributes: {
              ext: ".json",
              url: "/assets/contact/arrow-right.json"
            }
          }
        },
        page: {
          data: null
        }
      },
      homeButton: {
        style: "primary",
        label: "Zurück zur Startseite",
        icon: {
          data: null
        },
        page: {
          data: null
        },
        url: "/"
      },
      contactType: "",
      contactTypeText: "",
      contactTypeIcon: "",
      mailError: false,
      mailSuccess: false,
      loading: false,
      contactform: {
        name: "",
        email: "",
        firma: "",
        tel: "",
        website: "",
        softwareType: "",
        designType: "",
        deadline: "",
        message: "",
        datenschutzCheck: false
      }
    };
  },
  validations() {
    return {
      contactform: {
        name: { required },
        email: { required, email },
        message: { required },
        datenschutzCheck: { accepted: val => val === true }
      }
    };
  },
  components: {
    Button,
    VueMarkdown,
    StrawbinarySelect
  },
  methods: {
    openContactForm: function(type) {
      document.getElementById("circle-graphic").style.transform = "rotate(360deg)";
      document.getElementById("contact-form").style.top = "0";
      document.getElementById("contact").style.overflowY = "scroll";
      document.getElementById("main").style.zIndex = 900;
      document.getElementsByClassName("bg-dots-left")[0].style.display = "none";
      document.getElementsByClassName("circle-graphic-contact")[0].style.display = "none";

      this.loading = false;
      this.mailError = false;
      this.mailSuccess = false;
      this.contactType = type;
      switch (type) {
        case "website":
          this.contactTypeText = "Starten Sie jetzt Ihr <b>Website</b> Projekt.";
          this.contactTypeIcon = "/assets/contact/computer-display.json";
          break;
        case "design":
          this.contactTypeText = "Starten Sie jetzt Ihr <b>Design</b> Projekt.";
          this.contactTypeIcon = "/assets/contact/vector-design-pen.json";
          break;
        case "software":
          this.contactTypeText = "Starten Sie jetzt Ihr <b>Software</b> Projekt.";
          this.contactTypeIcon = "/assets/contact/multimedia-code-2.json";
          break;
        case "hello":
          this.contactTypeText = "Teilen Sie uns mit, was Sie uns sagen wollen.";
          this.contactTypeIcon = "/assets/contact/handshake-deal.json";
          break;
      }
    },
    closeContactForm: function() {
      document.getElementById("circle-graphic").style.transform = "rotate(0deg)";
      document.getElementById("contact-form").style.top = "95%";
      document.getElementById("main").style.zIndex = 907;
      document.getElementById("contact").style.overflowY = "hidden";
      document.getElementsByClassName("bg-dots-left")[0].style.display = "block";
      document.getElementsByClassName("circle-graphic-contact")[0].style.display = "block";
    },
    sendMail: async function() {
      this.$v.contactform.$touch();
      this.mailError = false;
      this.mailSuccess = false;
      if (
        !this.$v.contactform.name.$error &&
        !this.$v.contactform.email.$error &&
        !this.$v.contactform.message.$error &&
        !this.$v.contactform.datenschutzCheck.$error
      ) {
        this.loading = true;
        await axios
          .post("/api/mail", this.contactform)
          .then(response => {
            this.loading = false;
            if (response.data.status === "success") {
              this.mailSuccess = true;
              this.mailError = false;
            } else if (response.data.status === "failed") {
              this.mailError = true;
              this.mailSuccess = false;
            }
          })
          .catch(() => {
            this.loading = false;
            this.mailError = true;
            this.mailSuccess = false;
          });
      }
    }
  }
};
</script>

<style scoped>
#contact {
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;
  position: relative;
}

.main {
  height: 100%;
  padding: 5rem 20rem 0 20rem;
  margin-bottom: 9vw;
  position: relative;
  z-index: 907;
}

#sendStatus {
  position: inherit;
  top: 20%;
  left: 15vw;
  z-index: 907;
}

#sendStatus .message p {
  line-height: initial;
}

#sendStatus .success {
  background-image: url("/assets/contact/messageSend.svg");
  background-repeat: no-repeat;
  background-position-x: right;
  width: 55vw;
  height: 25vw;
}

#sendStatus .message {
  width: 60rem;
  color: var(--strawbinary-dark-blue);
  display: flex;
}

#sendStatus .error {
  background-image: url("/assets/contact/messageFailed.svg");
  background-repeat: no-repeat;
  background-position: 100% 0;
  width: 70vw;
  height: 70vh;
}

.home-button-wrapper {
  position: absolute;
  bottom: 13vh;
  text-align: center;
  left: 0;
  right: 0;
  z-index: 907;
}

.loading-icon {
  position: absolute;
  left: 0;
  height: 15rem;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}

/* === Backgrounds === */

#circle-graphic {
  position: absolute;
  z-index: 906;
  height: 70rem;
  width: auto;
  bottom: -32.5rem;
  left: -32.5rem;
  transition: 0.5s all ease;
}

.bg-dots-left {
  height: 100%;
  background-image: url("/assets/dots_blue.svg");
  background-repeat: no-repeat;
  background-position: left;
  background-position: 2rem 2rem;
  background-size: 23%;
  transition: all 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55);
}

.bg-dots-right {
  height: 100%;
  background-image: url("/assets/dots_blue.svg");
  background-repeat: no-repeat;
  background-position: 117%;
  background-size: 23%;
}

.spinner-left {
  height: 100%;
}

.headline {
  position: relative;
  z-index: 900;
}

.headline lord-icon {
  width: 10rem;
  height: 10rem;
  margin-left: -2.5rem;
}

.contact-type p {
  min-width: 10rem;
}

.contact-type-outer-wrapper {
  margin-top: 5rem;
}

.contact-type-wrapper {
  justify-content: space-evenly;
}

.contact-icon {
  min-height: 25rem;
  min-width: 25rem;
  padding: 5rem;
}

.contact-icon-contact-form {
  height: 15rem;
  width: 25rem;
  padding: 0 5rem;
}

.contact-type-text {
  padding: 1rem 2rem;
  padding-top: 0;
}

.contact-form-type {
  align-self: flex-start;
}

.contact-type:first-of-type {
  margin-left: 0;
}

.contact-type:last-child {
  margin-right: 0;
}

.contact-type {
  margin: 5rem;
  max-width: max-content;
  text-align: center;
}

.contact-type-contact-form {
  margin: 5rem;
  background-color: white;
  max-width: 30rem;
  margin-top: 0;
}

.contact-icon:hover {
  cursor: pointer;
}

/* === Contact Form === */

#contact-form {
  position: absolute;
  min-height: 100%;
  max-width: 100vw;
  width: 100%;
  top: 95%;
  transition: all 0.5s ease;
  padding: 7rem 0;
  background-color: #e6ebef;
  overflow: hidden;
}

#contact-form::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  background: #e6ebef;
  z-index: 0;
  clip-path: polygon(100% 100%, 100% 40%, 0 100%);
  top: -10vw;
  height: 10vw;
}

.contact-form {
  width: 50%;
}

.contact-form-wrapper {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 6rem;
  padding-bottom: 6rem;
  z-index: 907;
  position: relative;
}

.contact-form-wrapper input,
.contact-form-wrapper textarea {
  width: 100%;
}

.wrap-input100 {
  width: 100%;
  position: relative;
  border-bottom: 0.3rem solid #1f1f1f;
}

.wrap-input100-xs {
  width: 100%;
  position: relative;
  border-bottom: 0.3rem solid #1f1f1f;
}

.wrap-input100-xs-b0 {
  width: 100%;
  position: relative;
}

.form-group {
  padding: 0 2.5rem;
  margin-bottom: 6rem;
}

.form-group:last-child {
  padding-right: 2.5rem;
}

.input100 {
  font-family: "Rubik", sans-serif;
  color: #8f8f8f;
  line-height: 1.2;

  display: block;
  width: 100%;
  height: 4.5rem;
  background: white;
  padding: 1rem 1.5rem;
  border: none;
}

textarea.input100 {
  height: 20rem;
}

input[type="checkbox"].input100 {
  height: auto;
  width: auto;
  display: inline-block;
}

.focus-input100 {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
}

*:focus {
  outline: none;
}

.focus-input100::before {
  content: "";
  display: block;
  position: absolute;
  bottom: -0.3rem;
  left: 0;
  width: 0;
  height: 0.3rem;
  background: linear-gradient(90deg, #0b315e 0%, #246aa4 100%);
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.select-label {
  font-family: "Rubik", sans-serif;
  color: var(--strawbinary-dark-grey);
  line-height: 1.2;
  padding-left: 1.5rem;
}

.focus-input100::after {
  font-family: "Rubik", sans-serif;
  color: #8f8f8f;
  line-height: 1.2;

  content: attr(data-placeholder);
  display: block;
  width: 100%;
  position: absolute;
  top: 1.2rem;
  left: 0rem;
  padding-left: 1.5rem;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.input100:focus + .focus-input100::after {
  top: -3rem;
  color: #1f1f1f;
}

.input100:focus + .focus-input100::before {
  width: 100%;
}

.input100:focus {
  color: #1f1f1f;
}

.has-val {
  color: #1f1f1f;
}

.has-val.input100 + .focus-input100::after {
  top: -2.5rem;
  color: #1f1f1f;
}

.has-val.input100 + .focus-input100::before {
  width: 100%;
}

.validate-input {
  position: relative;
}

.alert-validate {
  border-bottom: 0.3rem solid var(--strawbinary-error);
}

.datenschutz-control.alert-validate {
  border-bottom: unset;
}

#messageHelp {
  display: block;
}

.message-area {
  margin-bottom: 4.5rem;
}

.datenschutz-control-wrapper {
  margin-bottom: 5rem;
}

/* === Back Button === */

.back-button {
  position: absolute;
  z-index: 979;
  top: 5rem;
  left: 6rem;
}

.round {
  position: absolute;
  border: 2px solid var(--strawbinary-dark-grey);
  width: 6rem;
  height: 6rem;
  border-radius: 100%;
}

#arrow-wrapper {
  width: 100%;
  cursor: pointer;
  position: absolute;
}

#arrow-wrapper .arrow {
  left: 30%;
}
.arrow {
  position: absolute;
  bottom: 0;
  margin-left: 0px;
  width: 2rem;
  height: 2rem;
  background-size: contain;
  top: 1.9rem;
}

.next {
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8' standalone='no'%3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg width='100%25' height='100%25' viewBox='0 0 60 50' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xml:space='preserve' xmlns:serif='http://www.serif.com/' style='fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;'%3E%3Cg transform='matrix(1.1405,0,0,1.1405,-18.66,3.02181)'%3E%3Cg transform='matrix(1.97134,0,0,1.97134,-16.0271,-39.8694)'%3E%3Cpath d='M16.939,28.939C16.354,29.525 16.354,30.475 16.939,31.061L26.485,40.607C27.071,41.192 28.021,41.192 28.607,40.607C29.192,40.021 29.192,39.071 28.607,38.485L20.121,30L28.607,21.515C29.192,20.929 29.192,19.979 28.607,19.393C28.021,18.808 27.071,18.808 26.485,19.393L16.939,28.939ZM19,28.5L18,28.5L18,31.5L19,31.5L19,28.5Z' style='fill:rgb(31,31,31);fill-rule:nonzero;'/%3E%3C/g%3E%3Cg transform='matrix(1.97134,0,0,1.97134,-16.0271,-39.8694)'%3E%3Cpath d='M30.939,28.939C30.354,29.525 30.354,30.475 30.939,31.061L40.485,40.607C41.071,41.192 42.021,41.192 42.607,40.607C43.192,40.021 43.192,39.071 42.607,38.485L34.121,30L42.607,21.515C43.192,20.929 43.192,19.979 42.607,19.393C42.021,18.808 41.071,18.808 40.485,19.393L30.939,28.939ZM33,28.5L32,28.5L32,31.5L33,31.5L33,28.5Z' style='fill:rgb(31,31,31);fill-rule:nonzero;'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
}

@keyframes bounceAlpha {
  0% {
    opacity: 1;
    transform: translateX(0px) scale(1);
  }
  25% {
    opacity: 0;
    transform: translateX(-10px) scale(0.9);
  }
  26% {
    opacity: 0;
    transform: translateX(10px) scale(0.9);
  }
  55% {
    opacity: 1;
    transform: translateX(0px) scale(1);
  }
}

.bounceAlpha {
  animation-name: bounceAlpha;
  animation-duration: 1.4s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.arrow.first.bounceAlpha {
  animation-name: bounceAlpha;
  animation-duration: 1.4s;
  animation-delay: 0.2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.round:hover .arrow {
  animation-name: bounceAlpha;
  animation-duration: 1.4s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.round:hover .arrow.second {
  animation-name: bounceAlpha;
  animation-duration: 1.4s;
  animation-delay: 0.2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@media screen and (max-width: 111.25em) {
  #sendStatus .message img {
    position: absolute;
    left: 30rem;
    top: -17rem;
    height: 55rem;
  }
}

@media screen and (max-width: 100em) {
  #circle-graphic {
    height: 60rem;
  }

  #sendStatus .error {
    background-position: 100% 30%;
    background-size: 100% auto;
  }
}

@media screen and (max-width: 90.625em) {
  #sendStatus .message img {
    top: -13rem;
    height: 45rem;
  }
}

@media screen and (max-height: 75em) {
  .contact-icon {
    min-height: 20rem;
    min-width: 20rem;
    padding: 3.5rem;
  }
}

@media screen and (max-width: 75em) {
  #sendStatus .success {
    width: 70rem;
    height: 30rem;
  }

  #sendStatus {
    top: 25%;
  }
}

@media screen and (max-width: 71.875em) {
  .contact-form-wrapper {
    flex-direction: column;
  }

  .contact-form {
    width: 100%;
  }

  .contact-form-type {
    align-self: center;
  }
}

@media screen and (max-width: 70.9375em) {
  .back-button {
    position: absolute;
    z-index: 979;
    top: 8.5rem;
    left: 2rem;
  }
}

@media screen and (max-width: 68.75em) {
  .main {
    padding: 10rem 10vw 0 10vw;
    margin-bottom: 21vw;
  }

  .contact-type-outer-wrapper {
    margin-top: 0rem;
  }

  #circle-graphic {
    height: 48rem;
    left: -25rem;
    bottom: -25rem;
  }

  .headline {
    padding: 0 2rem;
  }

  #contact-form {
    padding-top: 10rem;
  }

  #contact-form::before {
    top: -20vw;
    height: 20vw;
  }
}

@media screen and (max-height: 63.75em) {
  .contact-icon {
    min-height: 18rem;
    min-width: 18rem;
    padding: 2rem;
  }
}

@media screen and (max-width: 62.5em) {
  .headline lord-icon {
    width: 8rem;
    height: 8rem;
  }

  #sendStatus .message img {
    top: 0rem;
    height: 35rem;
    left: 25vw;
  }
}

@media (max-width: 62em) {
  .alert-validate::before {
    visibility: visible;
    opacity: 1;
  }

  #sendStatus .error {
    background-position: 100% 60%;
    background-size: 100% auto;
    width: 100%;
    height: 70vh;
  }
}

@media screen and (max-width: 53.75em) {
  #contact-form {
    padding-top: 15rem;
  }
}

@media screen and (max-width: 50em) {
  #sendStatus .message {
    width: 30rem;
  }

  #sendStatus .success {
    width: 60rem;
    height: 25rem;
  }

  #sendStatus .error {
    width: 200%;
  }

  #sendStatus {
    top: 25%;
  }
}

@media screen and (max-width: 48.4375em) {
  .contact-type {
    margin: 2.5rem 0;
    display: flex;
    align-items: center;
    text-align: left;
    width: 100vw;
    max-width: 100vw;
  }

  .contact-type p {
    margin-left: 3rem;
  }

  .contact-type-wrapper {
    justify-content: flex-start;
  }

  .contact-icon {
    min-height: 20rem;
    min-width: 20rem;
    padding: 2.5rem;
  }

  #contact {
    height: 100%;
  }

  .main {
    margin-bottom: 11rem;
    margin-bottom: 30vw;
  }

  #circle-graphic {
    height: 40rem;
    left: -20rem;
    bottom: -20rem;
  }
}

@media screen and (max-width: 45em) {
  #sendStatus .message {
    flex-direction: column;
  }

  #sendStatus .message img {
    position: relative;
    left: 0;
    right: 0;
    padding-bottom: 7rem;
  }

  #sendStatus {
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 100%;
    padding: 0 5vw;
    position: relative;
  }

  #sendStatus .error {
    width: 100%;
  }
}

@media screen and (max-width: 39.375em) {
  .contact-icon {
    min-height: 15rem;
    min-width: 15rem;
    padding: 2rem;
  }
}

@media screen and (max-width: 37.5em) {
  .headline lord-icon {
    width: 6rem;
    height: 6rem;
  }

  .form-group {
    margin-bottom: 3rem;
  }

  #sendStatus {
    top: 8%;
  }

  #sendStatus .success {
    height: 40vh;
  }

  #sendStatus .error {
    height: 40vh;
  }

  #sendStatus .success {
    background-position: center bottom;
    background-size: 80%;
    width: 100%;
  }

  #sendStatus .error {
    background-position: center bottom;
    background-size: 80%;
  }

  .focus-input100::after {
    font-family: "Rubik", sans-serif;
    color: #8f8f8f;
    line-height: 1.2;

    content: attr(data-placeholder);
    display: block;
    width: 100%;
    position: absolute;
    top: 1.5rem;
    left: 0rem;
    padding-left: 1.5rem;

    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
  }

  .input100:focus + .focus-input100::after {
    top: -2.2rem;
    color: #1f1f1f;
  }
}

@media screen and (max-width: 31.25em) {
  .headline lord-icon {
    width: 5rem;
    height: 5rem;
  }

  .round {
    width: 5rem;
    height: 5rem;
  }

  .arrow {
    width: 1.6rem;
    height: 1.6rem;
    top: 1.6rem;
  }
}

@media screen and (max-width: 26.25em) {
  .contact-icon {
    min-height: 12rem;
    min-width: 12rem;
    padding: 1.5rem;
  }

  .main {
    padding: 10rem 5vw 0 5vw;
  }
}

@media screen and (max-width: 25em) {
  .headline {
    padding: 0;
  }
}

@media screen and (max-width: 22.5em) {
  .headline lord-icon {
    width: 4.5rem;
    height: 4.5rem;
  }

  .contact-icon {
    min-height: 10rem;
    min-width: 10rem;
    padding: 1rem;
  }

  #circle-graphic {
    height: 30rem;
    left: -15rem;
    bottom: -15rem;
  }
}

@media screen and (max-width: 20em) {
  .headline lord-icon {
    width: 4rem;
    height: 4rem;
  }
}
</style>
