var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"ct-container",attrs:{"id":_vm.pos}},[_c('div',{attrs:{"id":"boxes"}},[_c('div',{staticClass:"wrapper"},[_c('vue-markdown',{staticClass:"text-center head sb-headline-blue"},[_vm._v(" ## "+_vm._s(_vm.body.headline)+" ")]),(_vm.body.slider)?_c('div',{attrs:{"id":"boxes-desktop"}},[_c('div',{staticClass:"row boxes"},_vm._l((_vm.body.box),function(body){return _c('div',{key:body.id,staticClass:"col"},[_c('div',{staticClass:"wrapper-boxes",attrs:{"data-aos-anchor-placement":"center-bottom","data-aos":"fade-up","data-aos-duration":"600"}},[_c('div',{staticClass:"bg-boxes shadow-sb border-top-sb"},[_c('div',{staticClass:"inner-boxes"},[(
                      body.image.data &&
                        body.image.data.attributes.ext.toLowerCase().trim() == '.json'
                    )?_c('div',{staticClass:"text-center"},[_c('lord-icon',{attrs:{"trigger":"hover","target":"div","src":_vm.url + body.image.data.attributes.url}})],1):(body.image.data)?_c('div',[_c('img',{staticClass:"media",attrs:{"src":_vm.url + body.image.data.attributes.url,"alt":body.image.data.attributes.alternativeText}})]):_vm._e(),_c('vue-markdown',{staticClass:"markdown sb-headline-3-blue inhalt",class:{ 'box-headline-padding': body.image.data == null },attrs:{"source":body.text}})],1)])])])}),0)]):_vm._e(),(!_vm.body.slider)?_c('div',{attrs:{"id":"boxes-no-slider"}},[_c('div',{staticClass:"row boxes"},_vm._l((_vm.body.box),function(body){return _c('div',{key:body.id,staticClass:"col"},[_c('div',{staticClass:"wrapper-boxes",attrs:{"data-aos-anchor-placement":"center-bottom","data-aos":"fade-up","data-aos-duration":"600"}},[_c('div',{staticClass:"bg-boxes shadow-sb border-top-sb"},[_c('div',{staticClass:"inner-boxes"},[(
                      body.image.data &&
                        body.image.data.attributes.ext.toLowerCase().trim() == '.json'
                    )?_c('div',{staticClass:"text-center"},[_c('lord-icon',{attrs:{"trigger":"hover","target":"div","src":_vm.url + body.image.data.attributes.url}})],1):(body.image.data)?_c('div',[_c('img',{staticClass:"media",attrs:{"src":_vm.url + body.image.data.attributes.url,"alt":body.image.data.attributes.alternativeText}})]):_vm._e(),_c('vue-markdown',{staticClass:"markdown sb-headline-3-blue inhalt",class:{ 'box-headline-padding': body.image.data == null },attrs:{"source":body.text}})],1)])])])}),0)]):_vm._e(),(_vm.body.slider)?_c('div',{attrs:{"id":"boxes-mobile"}},[_c('carousel',{ref:"boxSliderMobile",staticClass:"row",attrs:{"perPageCustom":[
            [0, 1],
            [1340, 2]
          ],"paginationEnabled":false,"scrollPerPage":true,"autoplay":false,"navigateTo":_vm.pageDesktop},on:{"page-change":_vm.PageChangeDragSlide}},_vm._l((_vm.body.box),function(body){return _c('slide',{key:body.id,staticClass:"row"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"wrapper-boxes",attrs:{"data-aos-anchor-placement":"center-bottom","data-aos":"fade-up","data-aos-duration":"600"}},[_c('div',{staticClass:"bg-boxes shadow-sb border-top-sb"},[_c('div',{staticClass:"inner-boxes"},[(
                        body.image.data &&
                          body.image.data.attributes.ext.toLowerCase().trim() == '.json'
                      )?_c('div',{staticClass:"text-center"},[_c('lord-icon',{attrs:{"trigger":"hover","target":"div","src":_vm.url + body.image.data.attributes.url}})],1):(body.image.data)?_c('div',[_c('img',{staticClass:"media",attrs:{"src":_vm.url + body.image.data.attributes.url,"alt":body.image.data.attributes.alternativeText}})]):_vm._e(),_c('vue-markdown',{staticClass:"markdown sb-headline-3-blue inhalt",class:{ 'box-headline-padding': body.image.data == null },attrs:{"source":body.text}})],1)])])])])}),1),_c('div',{staticClass:"sideButtons"},[_c('button',{staticClass:"clear-button arrowImg arrow btnhoverLEFT",attrs:{"id":"arrowLEFT"},on:{"click":function($event){_vm.pageDesktop === 0
                ? (_vm.pageDesktop = _vm.$refs.boxSliderMobile.pageCount - 1)
                : _vm.pageDesktop--}}}),_c('button',{staticClass:"clear-button arrowImg arrow btnhoverRIGHT",attrs:{"id":"arrowRIGHT"},on:{"click":function($event){_vm.pageDesktop === _vm.$refs.boxSliderMobile.pageCount - 1
                ? (_vm.pageDesktop = 0)
                : _vm.pageDesktop++}}})]),_c('div',{staticClass:"text-center pagination-dots"},_vm._l((this.sliderCount),function(i){return _c('button',{key:i.id,staticClass:"fa-circle slider-custom-pagination",class:{ fas: i == _vm.activePage, far: i != _vm.activePage },attrs:{"type":"button","name":"button"},on:{"click":function($event){return _vm.PaginationCarousel(i)}}})}),0)],1):_vm._e()],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }