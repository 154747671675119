<template lang="html">
  <button type="button" class="button">
    <router-link
      :class="{
        'btn-primary-sb': button.style == 'primary',
        'btn-secondary-sb': button.style == 'secondary',
        'btn-link-sb': button.style == 'link',
        'btn-text-sb': button.style == 'text',
        'btn-dark-primary-sb': button.style == 'dark_primary',
        'btn-blue-icon-sb': button.style == 'blue_icon',
        'btn-nav-link': button.style == 'nav_link',
        'active': button.url === '/' && $route.path === '/'
      }"
      v-if="
        button.page.data &&
          button.page.data.attributes !== null &&
          (button.url == '' || button.url == null)
      "
      :to="{ path: '/page/' + button.page.data.attributes.slug }"
    >
      <label>
        {{ button.label }}
      </label>
      <img
        class="icon"
        v-if="
          button.icon &&
            button.icon.data &&
            button.icon.data.attributes.ext.toLowerCase().trim() !== '.json'
        "
        :src="url + button.icon.data.attributes.url"
        :alt="button.icon.data.attributes.alternativeText"
      />
      <lord-icon
        v-if="
          button.icon &&
            button.icon.data &&
            button.icon.data.attributes.ext.toLowerCase().trim() == '.json'
        "
        target="a"
        trigger="hover"
        :src="url + button.icon.data.attributes.url"
      ></lord-icon>
    </router-link>
    <a
      v-if="button.url !== null && button.url !== ''"
      :class="{
        'btn-primary-sb': button.style == 'primary',
        'btn-secondary-sb': button.style == 'secondary',
        'btn-link-sb': button.style == 'link',
        'btn-text-sb': button.style == 'text',
        'btn-dark-primary-sb': button.style == 'dark_primary',
        'btn-blue-icon-sb': button.style == 'blue_icon',
        'btn-nav-link': button.style == 'nav_link',
        'active': button.url === '/' && $route.path === '/'
      }"
      :href="button.url"
    >
      <label>
        {{ button.label }}
      </label>
      <img
        class="icon"
        v-if="
          button.icon &&
            button.icon.data &&
            button.icon.data.attributes.ext.toLowerCase().trim() !== '.json'
        "
        :src="url + button.icon.data.attributes.url"
        :alt="button.icon.data.attributes.alternativeText"
      />
      <lord-icon
        v-if="
          button.icon &&
            button.icon.data &&
            button.icon.data.attributes.ext.toLowerCase().trim() == '.json'
        "
        target="a"
        trigger="hover"
        :src="url + button.icon.data.attributes.url"
      ></lord-icon>
    </a>
  </button>
</template>

<script>
import { getStrapiUrl } from "../../helper";
export default {
  name: "Button",
  props: {
    button: {
      type: Object
    },
    url: {
      type: String,
      default: getStrapiUrl()
    }
  }
};
</script>

<style lang="css" scoped>
.button {
  border: none;
  background: none;
  display: inline-block;
}

lord-icon {
  height: 5rem;
  width: 5rem;
  margin-bottom: 0.6rem;
  margin-left: 0.6rem;
  margin-right: 0;
}

.icon {
  height: 4rem;
  width: 4rem;
  margin-bottom: 0.6rem;
  margin-left: 1.6rem;
  margin-right: -1rem;
}

@media screen and (max-width: 93.75em) {
  lord-icon {
    height: 4rem;
    width: 4rem;
    margin-right: 0;
    margin-bottom: 0;
  }
}
</style>
